<template>
    <span>
        <div class="drawHisLI col4 link" @click="getEntries">
            <span v-if="!view">Entry Details</span>
            <span v-if="view">Hide Details</span>
        </div>
        <ul v-if="view === true" class="drawHisLI col5">
            <li class="showingDetails drawHistDetail listRow cf">
                <div class="drawHisLI listHead">
                    Date Entered
                </div>
                <div class="drawHisLI listHead entryCount">
                    Entries
                </div>
            </li>
            <li v-for="entries in data" :key="entries.itenID" class="showingDetails listRow cf">
                    {{ entries.entryDate }}
                <span class="entryCount" v-if="secondChance === true">
                    {{ entries.entrycount }}
                </span>
                <span class="entryCount" v-else>
                    {{ entries.entryCount }}
                </span>
            </li>
        </ul>
    </span>
</template>

<script>
    // import $ from 'jquery';
    // import Foundation from "@/foundation-sites/js/foundation.js";
    import moment from "moment";

    export default {
        props: [
            'id',
            'tokenKey',
            'url',
            'secondChance'
        ],
        components:{
        },
        data: function () {
            return {
                data: '',
                view: false
            }
        },
        mounted: function () {
        },
        methods: {
            getEntries() {
                this.view = !this.view;

                let url = process.env.VUE_APP_APIURL + this.url;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.tokenKey
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    let d = json.data;

                    for (let i = 0; i < d.length; i++) {
                        if(this.secondChance !== true){
                            let date = moment(d[i].entryDate).format("MM/DD/YYYY");
                            d[i].entryDate = date;
                        }else{
                            let date = moment(d[i].participationDate).format("MM/DD/YYYY");
                            d[i].entryDate = date;
                        }
                    }

                    this.data = d;
                    // console.log(this.data)
                })
            }
        },
        computed: {
        },
        watch: {
        }
    };
</script>

<style lang="scss" scoped>
    h2 {
        float: left;
    }
    .col5 {
        display: block !important;
    }
    .drawHisViewAll {
        display: inline;
        float: right;
        margin-top: -30px;
        margin-bottom: 10px;
        font-weight: 300;

        @media screen and (min-width: 641px) {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
</style>