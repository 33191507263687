<template>
    <section class="cf module_drawingList">
        <span v-if="debug === true">
            <hr>
            <p>
                <span>ID: </span>{{ userId }} ||
                <span>Return Responses: </span>{{ resultsReturned }}
            </p>
        </span>
        <h2>{{ title }}</h2>
        <button v-if="view === false && history" class="drawHisViewAll link" @click="$emit('history', 3)">View History</button>
        <button v-if="view === true && history" class="drawHisViewAll link" @click="$emit('history', 3)">View Recent</button>
        <div class="cf drawingList drawingListResponsive ActiveDrawingEntryList">
            <table class="ActiveDrawingEntryList cf">
                <tbody>
                    <tr>
                        <th class="listHead col1">Drawing Name</th>
                        <th class="listHead col2">Closing Date</th>
                        <th class="listHead col3">Entries</th>
                        <th class="listHead col4">Winners</th>
                    </tr>
                </tbody>
            </table>

            <!-- Recent View -->
            <ul v-if="view === false">
                <li v-for="entry in data.slice(0, resultsReturned)" :key="entry.drawingName" class="stRow drawEntryHistoryMore cf">
                    <div class="drawHisLI col1">{{ entry.drawingName }}</div>
                    <div class="drawHisLI col2"><span class="drawingLabel">Closing Date: </span>{{ entry.drawingClosingDate }}</div>
                    <div class="drawHisLI col3"><span class="drawingLabel">Entries: </span>{{ entry.entryCount }}</div>
                    <WinnersList v-if="entry.winners" :id="entry.secondChanceID" :tokenKey="tokenKey" :url="false" :winners="entry.winners" :drawingCodeName="entry.drawingCodeName"></WinnersList>
                </li>
            </ul>

            <!-- History View -->
            <ul v-if="view === true">
                <li v-for="entry in data" :key="entry.drawingName" class="stRow drawEntryHistoryMore cf">
                    <div class="drawHisLI col1">{{ entry.drawingName }}</div>
                    <div class="drawHisLI col2"><span class="drawingLabel">Closing Date: </span>{{ entry.drawingClosingDate }}</div>
                    <div class="drawHisLI col3"><span class="drawingLabel">Entries: </span>{{ entry.entryCount }}</div>
                    <WinnersList v-if="entry.winners" :id="userId" :tokenKey="tokenKey" :url="false" :winners="entry.winners" :drawingCodeName="entry.drawingCodeName"></WinnersList>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    // import $ from 'jquery';
    // import Foundation from "@/foundation-sites/js/foundation.js";
    import WinnersList from './DrawListWinners.vue';

    export default {
        props: [
            'debug',
            'tokenKey',
            'title',
            'userId',
            'resultsReturned',
            'data',
            'view'
        ],
        components:{
            WinnersList
        },
        data: function () {
            return {
                history: false
            }
        },
        mounted: function () {
            this.data.length > this.resultsReturned ? this.history = true : false;
            console.log("WHAT IS THE DATA HERE");
            console.log(this.data);
            if (this.debug === true) {
                // console.log("= Second Chance =");
                // console.log(this.data);
            }
        },
        methods: {
        },
        computed: {
        },
        watch: {
        }
    };
</script>

<style lang="scss" scoped>
    h2 {
        float: left;
    }
    .drawHisViewAll {
        display: inline;
        float: right;
        margin-top: -30px;
        margin-bottom: 10px;
        font-weight: 300;
        letter-spacing: 0;
            text-transform: none;

        @media screen and (min-width: 641px) {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
</style>