<template>
    <section class="cf module_drawingList">
        <span v-if="debug === true">
            <hr>
            <p>
                <span>ID: </span>{{ userId }} ||
                <span>Return Responses: </span>{{ resultsReturned }}
            </p>
        </span>
        <h2>{{ title }}</h2>
        <button v-if="view === false && history" class="drawHisViewAll link" @click="$emit('history', 5)">View History</button>
        <button v-if="view === true && history" class="drawHisViewAll link" @click="$emit('history', 5)">View Recent</button>
        <div class="cf drawingList cashExplosionEntriesList">
            <table class="CustomDrawingEntryList cf">
                <tbody>
                    <tr>
                        <th class="listHead">Entry Date</th>
                        <th class="listHead">Ticket Number</th>
                        <th class="listHead">Watch On</th>
                    </tr>
                </tbody>
            </table>

            <!-- Recent View -->
            <ul v-if="view === false" class="CustomDrawingEntryList entryList cf">
                <li v-for="entry in data.slice(0, resultsReturned)" :key="entry.drawingName" class="stRow drawEntryHistoryMore cf">
                    <div class="ceEntryDate col1">
                        {{ new Date(entry.submitDate) | moment('L') }}
                    </div>
                    <div class="ceEntryNumber col2">
                        {{ entry.ticketSerialNumber }}
                    </div>
                    <div class="ceEntryAirDate col3">
                        {{ new Date(entry.showAirDate) | moment('L') }}
                    </div>
                </li>
            </ul>

            <!-- History View -->
            <ul v-if="view === true" class="CustomDrawingEntryList entryList cf">
                <li v-for="entry in data.slice(0, resultsReturned)" :key="entry.drawingName" class="stRow drawEntryHistoryMore cf">

                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    // import $ from 'jquery';
    // import Foundation from "@/foundation-sites/js/foundation.js";
    import moment from 'moment';

    export default {
        props: [
            'debug',
            'tokenKey',
            'title',
            'userId',
            'resultsReturned',
            'data',
            'view'
        ],
        components:{
        },
        data: function () {
            return {
                history: false
            }
        },
        mounted: function () {
            // this.setProps();
            this.data.length > this.resultsReturned ? this.history = true : false;
            if (this.debug === true) {
                // console.log("= Cash Explosion =");
                // console.log(this.data);
            }
        },
        methods: {
        },
        computed: {
        },
        watch: {
        }
    };
</script>

<style lang="scss" scoped>
    h2 {
        float: left;
    }
    .drawHisViewAll {
        display: inline;
        float: right;
        margin-top: -30px;
        margin-bottom: 10px;
        font-weight: 300;
        letter-spacing: 0;
            text-transform: none;

        @media screen and (min-width: 641px) {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
</style>