<template>
    <div class="full featuredWinner-container">
        <div class="slick-slide">
            <div class="win-item">
                <div class="win-value" style="text-align:center;">
                    {{ res.winAmount }}
                </div>
                <div class="win-info" style="text-align:center;">
                    <span class="win-date">{{ customFormatter( res.date ) }}:</span>
                    {{ res.title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import moment from 'moment';


    export default {
        props: [
            'res'
        ],
        components: {
            VueSlickCarousel
        },
        data: function () {
            return {
                winningData: this.res
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            customFormatter(date) {
                return moment(date).format('MMMM Do');
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();

                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetAllByCategory/FeaturedWinner", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var res = json.data;

                    function sortByDate(a, b) {
                        return new Date(b.date).getTime() - new Date(a.date).getTime();
                    }
                   
                    res.sort(sortByDate);

                    this.winners = res;

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>


</style>