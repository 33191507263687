<template>
    <div class="interactiveGameContent">
        <div class="interactiveGame page_content cf">

        </div>
        <HollowDotsSpinner
                :animation-duration="1000"
                :dot-size="15"
                :dots-num="3"
                color="#0FD8C9"
                v-if="isLoading"
            />

        <div class="cf module interactiveGameContent" id="interactiveGameCanvas" style="max-width:500px;width:100%;text-align:center;margin:0 auto;">
            <!-- <img src="/ohiolottery/media/ContentImages/MyLottoRewards/InteractiveGames/OH_logo.png" id="loadIMG" style="margin:40% auto;"> -->
            <div id="loadIMG" style="margin:40% auto;" />
            
        </div>
        <!-- <input type="hidden" id="userIdentifier" value="{% String.GetSafeUserId() #%}"/> -->
        <!-- reference the global IG JS -->
        
    </div>
</template>

<script>
import { HollowDotsSpinner } from 'epic-spinners'
export default {
        components: {
            HollowDotsSpinner
        },
        data: function() {
            return {
                id: '',
                interactive_basicSettings: {
                    basic: {
                        canvasElement: { 
                            height: 500,
                            width: 500,
                            transparency: true,
                            resolution: 1
                        },
                        settings: {
                            closed: [0,0],
                            open: [20,20]
                        },
                        wagerTiers: []
                    }
                },
                interactive_customSettings: {},
                token: '',
                isLoading: true,
                isKYCVerified: false
            }
        },
        mounted: function() {
            this.getToken()
            // console.log('Game for Prizes Initiated')
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.urlSearchParams('id')
                })
                .then(() => {
                    this.getKYCVerified();
                });
            },
            get_game_settings (id) {
                var token = this.token;

                // console.log(token)

                var url = process.env.VUE_APP_APIURL+'/1.0/Games/InteractiveGames/GetGameSettings?gameId=' + id;
                // console.log(url)

                return fetch (url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(response => response.json())
                .then(json => {
                    let data = json.data;

                    for (var i = 0; i < data.books.length; i++) {
                        if (data.books[i].isDisabled !== true) {
                            this.interactive_basicSettings.basic.wagerTiers.push(data.books[i].wager);
                        }
                    }

                    var game_type = data.gameType,
                        settings = data.settings;

                    switch (game_type) {
                        case 0:
                            game_type = '/InteractiveGames/scripts/interactiveGames/plinko.js';
                            // game_type = '/InteractiveGames/scripts/interactiveGames/mazr.js';
                            break;
                        case 1:
                            game_type = '/InteractiveGames/scripts/interactiveGames/spin.js';
                            break;
                        case 2:
                            game_type = '/InteractiveGames/scripts/interactiveGames/matching.js';
                            break;
                        case 3:
                            game_type = '/InteractiveGames/scripts/interactiveGames/eightbit.js';
                            break;
                    }
                    
                    this.interactive_customSettings = settings;

                    let basic = 'var interactive_basicSettings= ' + JSON.stringify(this.interactive_basicSettings) + ';\n';
                    let custom = 'var interactive_customSettings= ' + JSON.stringify(this.interactive_customSettings);

                    let scripts = document.getElementById("scripts");

                    scripts.innerHTML = basic + custom;

                    this.isLoading = false;

                    setTimeout(() => {
                        document.getElementById("game-type").src = game_type;
                    }, 1000);
                });
            },
            //Gets values from Querystring
            getQuerystringValue(name) {
                name = name.replace(/[[]/,"\\[").replace(/[\]]/,"\\]");
                var regexS = "[\\?&]"+name+"=([^&#]*)";
                var regex = new RegExp( regexS );
                var results = regex.exec( window.location.href );
                if( results == null ) {
                    return "";
                } else {
                    return decodeURIComponent(results[1].replace(/\+/g, " "));
                }
            },
            urlSearchParams (name) {
                // console.log(this.token)
                var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href),
                    decode = false,
                    webView;

                if (results == null){
                    webView = document.getElementById('iwGameID').value;

                    if (webView.length) {
                        // console.log(webView);
                    } else {
                        console.log("=== ERROR: No Game ID Found ===")
                    }
                }
                else {
                    decode = true;
                }

                let id;
                decode ? id = decodeURI(results[1]) || 0 : id = webView;
                // console.log(id)
                setTimeout(() => {
                    this.get_game_settings(id);
                }, 1000);

                let token = this.$cookies.get('apiToken');

                let mobile = document.getElementById("iwGameID");

                let tokenInput = document.getElementById('userToken'),
                    refreshInput = document.getElementById('refreshToken'),
                    userInput = document.getElementById('userId'),
                    nameInput = document.getElementById("userName"),
                    pointsInput = document.getElementById("userPoints"),
                    gameInput = document.getElementById("gameId");

                if (mobile === null) {
                    // If not on Mobile use these values.
                    let userToken = token.token,
                        userId = token.user.userId,
                        userName = token.user.firstName,
                        userPoints = token.user.pointBalance.balance;
                    
                    //Check User point value
                    if (userPoints > 999) {
                        userPoints = userPoints.toLocaleString();
                    }

                    tokenInput.value = userToken;
                    userInput.value = userId;
                    nameInput.value = userName;
                    pointsInput.value = userPoints;
                    gameInput.value = id;
                } else {
                    // If on Mobile, use these values.
                    let authToken = document.getElementById('iwAuthToken').value,
                        authUserId = document.getElementById('iwAuthUserId').value,
                        authGameId = document.getElementById("iwGameID").value,
                        authUserName = document.getElementById("iwAuthUserName").value,
                        authUserPoints = document.getElementById("iwAuthUserPoints").value;
                    
                    if (authUserPoints > 999) {
                        authUserPoints = authUserPoints.toLocaleString();
                    }

                    tokenInput.value = authToken;
                    userInput.value = authUserId;
                    nameInput.value = authUserName;
                    pointsInput.value = authUserPoints;
                    gameInput.value = authGameId;
                }
            },
            getKYCVerified() {
                let token = this.$cookies.get('apiToken');
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + token.user.userId;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + token.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    // console.log(json.data);
                    this.isKYCVerified = json.data.userIsVerified;
                    // this.verifyLink = json.data.kycVerificationLink;
                    // this.kyc = true;
                    // console.log(`KYC : ${this.isKYCVerified}`);
                    // console.log(`Link URL : ${this.verifyLink}`);
                })
                .catch((error) => {
                    return (this.errorMessage = error)
                })
            }
        }
    }
</script>