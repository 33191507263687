<template>
    <div class="eapp-chain-sp eAppChainAssign ">
        <h3>Chain Assignments</h3>

        <SignInRegister v-if="!isUserLogged && !isLoading" content-key="authButtonsEapp"></SignInRegister>

        <HollowDotsSpinner :animation-duration="1000"
                           :dot-size="15"
                           :dots-num="3"
                           color="#0FD8C9"
                           v-if="isLoading" />

        <div class="alert-icon" v-if="success">
            <i class="icon-check-circle"></i><span>The operation was completed successfully.</span>
        </div>
        <div v-if="errorMessageShow" class="ErrorLabel alert-error">There was an error processing your request.</div>

        <div v-if="!isLoading && isUserLogged && isChainUser">
            <!-- <div id="eapp-chain-search-form">
            <input type="search" id="eapp-chain-sp-search" v-model="searchQuery" class="chainSearchInput">
            <button class="button">Search</button>
        </div> -->
            <ul class="eapp-chain-sp-header">
                <li>
                    <div class=" eapp-chain-sp-left">
                        <h5 class="eapp-chain-sp-header-b">Application Business Name</h5>
                    </div>
                    <div class="eapp-chain-sp-header-sr">
                        <h5>Sales Representative</h5>
                    </div>
                </li>
            </ul>
            <ul class="eapp-chain-sp-list">
                <li v-for="chain in chainList" :key="chain.chainAppGuid" class="eapp-chain-sp-buisness">
                    <div class="eapp-chain-edit" @click="handleEdit(chain)">
                        <!-- wire thfis to toggle the Select/Save visibility -->
                    </div>
                    <div class="eapp-chain-name eapp-chain-sp-left">
                        <p>{{chain.chainName}}</p>
                    </div>
                    <div class="eaap-chain-select">
                        <!-- <p v-if="chain.chainSalesRepName !== null">{{chain.chainSalesRepName}}</p> -->
                        <!-- convert this to a reusable module -->
                        <!-- preselect the sales rep if one has already been designated prior -->
                        <select name="sales-rep" :id="chain.chainAppGuid" v-model="chain.chainSalesRepUserId"  :disabled="!chain.editing">
                            <option value="null">Select a Representative</option>
                            <option v-for="rep in reps" :key="rep.userId" :value="rep.userId">{{ rep.fullName }}</option>
                        </select>
                    </div>
                    <a class="button" v-if="chain.editing" @click="setSalesRep(chain)">SAVE</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { HollowDotsSpinner } from 'epic-spinners';
    import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';
    export default {
        components: {
            HollowDotsSpinner,
            SignInRegister
        },
        data() {
            return {
                isUserLogged: false,
                isLoading: false,
                API_URL: this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/dashboard/GetApplicationListForRetailer`,
                userId: 0,
                token: '',
                isChainUser: false,
                // chainRoles: ['eApp-ChainManager','eApp-ChainSalesRep','eApp-ChainApplicant','eAppApplicant'],
                chainRoles: ['eApp-ChainManager'],
                retailers: [],
				reps: [],
                chainList: [],
                testing: false,
                searchQuery: '',
                sortBy: 'businessname',
                sortOrder: 'asc',
                success: false,
                errorMessageShow: false,
                apiToken: '',
                repUserId: 0
            }
        },
        mounted: function () {
            this.isLoading = true;
            this.isUserLogged = this.isUserAuthed(); 
            this.chainRoles.forEach(element => {
                // must have at least one chain role
                if (this.isUserInRole(element)) {
                    this.isChainUser = true;
                }
            });

            if (this.isUserLogged && this.isChainUser) {
                this.init();
            } else {
                this.isLoading = false;
                // we could show something, but it's not necessary
            }
        },
        methods: {
            init() {
                this.getToken();
            }, getToken() {
                let uId = this.getLoggedInUserId();
                if (uId) {
                    // console.log('uid: ' + uId);
                    this.userId = uId;
                    this.getAPItoken()
                        .then((token) => {
                            this.token = token;
                            this.fetchData(token, this.searchQuery, this.sortBy, this.sortOrder);
                        });
                }
            },
            async fetchData(token, _businessName, _sortBy, _sortOrder) {
                this.isLoading = true;
                this.apiToken = token;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        businessName: _businessName,
                        sortBy: _sortBy,
                        sortOrder: _sortOrder,
                    })
                };
                let response = await (await fetch(this.GET_APP_RETAILER_LIST_API_URL, appsRequestOptions)).json();
                
                if (response.statusCode == 200) {
                    let masterList = [ ...response.data.masterApplicationList ];

                    for (let i = 0; i < masterList.length; i++) {
                        let masterItem = masterList[i];

                        masterItem.editing = false;
                    }

                    this.chainList = masterList;
                    this.reps = response.data.chainSalesReps;
                }
                else {
                    this.errorMessageShow = true;
                }
                this.isLoading = false;
            },
            handleEdit(data) {
                let chain = data,
                    chainList = [ ...this.chainList ];
                // console.log(chain)

                for (let i = 0; i < chainList.length; i++) {
                    let chainListChain = chainList[i];

                    if (chain.chainAppGuid === chainListChain.chainAppGuid) {
                        // console.log(chain)
                        if (chain.editing === false) {
                            chainListChain.editing = true;
                        } else {
                            chainListChain.editing = false;
                        }

                    }
                }

                this.chainList = chainList;
            },
            async setSalesRep(chain) {
                // update the chainList item from selected Rep option
                // this.repUserId is a v-model on ALL selects and may change all simultaniously overriding any previously made changes.
                // 
                // this.chainList.forEach((element) => {
                //     if (element.chainAppGuid == chain.chainAppGuid) {
                //         element.chainSalesRepUserId = this.repUserId;
                //     }
                // });
                // console.log(chain);
                // save the new value to an endpoint
                
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.apiToken,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        salesRepId: chain.chainSalesRepUserId,
                        applicationGuid: chain.chainAppGuid
                    })
                };
                let response = await(await fetch(this.SET_MASTER_APP_SALES_REP_API_URL, appsRequestOptions)).json();

                if (response.statusCode == 200) {
                    this.success = true;
                    chain.editing = false;
                }
                else {
                    this.errorMessageShow = true;
                }
                this.isLoading = false;
            }
        },
       
        computed: {
            GET_APP_RETAILER_LIST_API_URL() {
                let url = '';
                url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/ApplicationAdministration/MasterApplicationList/` + this.userId;
                return url;
            },
            SET_MASTER_APP_SALES_REP_API_URL() {
                let url = '';
                url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/ApplicationAdministration/AssignChainSalesRepToMasterChain/' + this.userId;
                return url;
            }
        }
    }
</script>