<template>
  <div class="module">
    <SignInRegister></SignInRegister>
    <TicketChecker v-if="showTicketEntryForm" @getTicketChecker="getTicketChecker"></TicketChecker>
    <div v-if="this.showForm">
      <template>
        <div class="dcf_identityCheck olcForm dcf_forms">
          <div>
            <p class="reqNote"><span class="req">*</span> Required field</p>
            <h2>Congratulations, you won <span class="prizeVal">${{ form.ClaimForm.PrizeAmount }}</span> !</h2>
            <p>You may claim your winnings by filling out the form below.</p>

            <p>Information submitted in connection with an Ohio Lottery claim and/or
              prize payment may be a public record under section 149.43 of the Ohio
              Revised Code and subject to release. I understand that my name, address,
              telephone and/or likeness may be utilized for administrative, marketing and/ or promotional purposes.</p>
            <p>To remain anonymous and ensure <strong>NO</strong> media or publicity, my prize must
              be claimed in a <strong>Blind Trust</strong>. To begin the process of a Blind Trust, I or my
              legal counsel should contact the Legal Department at the Ohio Lottery in
              Cleveland at 216-774-5652 <strong>before</strong> the claim form is completed.</p>
            <h3 v-if="statusCode != 200">Contact Information</h3>
            <p v-if="statusCode != 200">Please provide your legal contact information on record with the government.</p>
            <p class="InfoLabel" :class="[hasError ? 'alert-error' : 'alert-success']" v-if="statusCode === 200"><strong>{{ messageApi }}</strong></p>
          </div>

          <form data-abide novalidate data-validate-on-blur="true" id="bigForm" v-if="statusCode != 200">

            <fieldset class="grid-x grid-margin-x section-contact-info grid-margin-y"
              :class="{ 'previewOnly': step.ContactInfo.activeState, 'disabled': !step.ContactInfo.activeState }">
              <div class=" columns small-12 medium-5 cell">
                <label>First Name <span class="req">*</span></label>
                <input type="text" maxlength="150" v-model="form.ClaimForm.FirstName" required pattern="^[a-zA-Z0-9 ]*$"
                  :disabled="(!step.ContactInfo.activeState && !step.ContactInfo.editState) || (!step.ContactInfo.activeState && step.ContactInfo.editState)" />
                <div class="form-error error errortextOnly">Please provide text only.</div>
              </div>
              <div class=" hasMiddleInit columns small-12 medium-1 cell">
                <label>MI</label>
                <input type="text" maxlength="1" v-model="form.ClaimForm.MI" pattern="alpha"
                  :disabled="(!step.ContactInfo.activeState && !step.ContactInfo.editState) || (!step.ContactInfo.activeState && step.ContactInfo.editState)" />
                <div class="form-error error errortextOnly">Please provide text only.</div>
              </div>
              <div class="columns small-12 medium-6 cell">
                <label>Last Name <span class="req">*</span></label>
                <input type="text" maxlength="150" v-model="form.ClaimForm.LastName" required pattern="^[a-zA-Z0-9 ]*$"
                  :disabled="(!step.ContactInfo.activeState && !step.ContactInfo.editState) || (!step.ContactInfo.activeState && step.ContactInfo.editState)" />
                <div class="form-error error errortextOnly">Please provide text only.</div>
              </div>
              <div class="columns small-12 medium-6 cell dateWrapp">
                <label>Date of Birth <span class="req">*</span></label>
                <datepicker :disabledDates="state.disabledDates" placeholder="Select Date" name="inputArchiveDate"
                  id="txtDateOfContact" :format="customFormatter" v-model="form.ClaimForm.DoB" required
                  :disabled="(!step.ContactInfo.activeState && !step.ContactInfo.editState) || (!step.ContactInfo.activeState && step.ContactInfo.editState)">
                </datepicker>
                <div class="form-error error errortextOnly">Please provide your date of birth.</div>
              </div>
              <div class="columns small-12 medium-6 cell">
                <label id="lbltxtSSN" for="txtSSN">Social Security Number <span class="req">*</span></label>
                <input type="text" maxlength="9" v-model="form.ClaimForm.SSN" required pattern="number"
                  :disabled="(!step.ContactInfo.activeState && !step.ContactInfo.editState) || (!step.ContactInfo.activeState && step.ContactInfo.editState)" />
                <span class="form-error error ssnFormatError regError">Please provide a valid social security
                  number, without spaces.</span>
              </div>
              <div class="columns small-12 medium-6 cell">
                <label>Email Address <span class="req">*</span></label>
                <input type="text" maxlength="200" v-model="form.ClaimForm.Email" required pattern="email"
                  :disabled="(!step.ContactInfo.activeState && !step.ContactInfo.editState) || (!step.ContactInfo.activeState && step.ContactInfo.editState)" />
                <span class="form-error error emailFormatError regError" data-form-error-on="pattern">Invalid email
                  address.</span>
                <span class="form-error error emailFormatError regError">Please provide a email address.</span>
              </div>
              <div class="phoneWrap columns small-12 medium-6 cell">
                <label>Phone Number <span class="req">*</span></label>
                <input type="text" maxlength="200" v-model="form.ClaimForm.PhoneNumber" required id="contactInfoPhone"
                  pattern="phone_number" @change="phoneFormat($event)"
                  :disabled="(!step.ContactInfo.activeState && !step.ContactInfo.editState) || (!step.ContactInfo.activeState && step.ContactInfo.editState)" />
                <span class="form-error error regError">Please provide a phone number: Ex. ### ### ####</span>
              </div>

              <span class="error regError columns cell" role="alert" v-if="contacInfoErr && !isLoading">Please verify
                your Contact Information and try again.</span>

              <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9"
                v-if="step.ContactInfo.activeState && isLoading" />

              <button type="button" class="button submit" id="section-contact-info" @click="getSection($event)"
                v-if="(step.ContactInfo.activeState && !step.ContactInfo.editState && !isLoading) || (!step.ContactInfo.activeState && !step.ContactInfo.editState && !isLoading)"
                :disabled="!step.ContactInfo.activeState">Confirm Contact Information</button>

              <button class="button" id="section-contact-info" @click="editBtn($event)"
                v-if="(!step.ContactInfo.activeState && step.ContactInfo.editState && !isLoading)">Edit Contact
                Information</button>

            </fieldset>

            <!-- MAILING ADDREES -->
            <fieldset class="grid-x grid-margin-x grid-margin-y section-mailing-address" ref="section-mailing-address"
              :class="{ 'previewOnly': step.MailingAddress.activeState, 'disabled': !step.MailingAddress.activeState }">
              <div class="halfForm columns small-12 medium-6 cell">
                <label>Mailing address <span class="req">*</span></label>
                <input type="text" maxlength="150" v-model="form.ClaimForm.Address1" required pattern="^[a-zA-Z0-9 ]*$"
                  :disabled="(!step.MailingAddress.activeState && !step.MailingAddress.editState) || (!step.MailingAddress.activeState && step.MailingAddress.editState)" />
                <div class="form-error error errortextOnly">Please provide text and numbers only.</div>
              </div>

              <div class="halfForm halfFormRight columns small-12 medium-6 cell">
                <label>Address (continued)</label>
                <input type="text" maxlength="150" v-model="form.ClaimForm.Address2" pattern="^[a-zA-Z0-9 ]*$"
                  :disabled="(!step.MailingAddress.activeState && !step.MailingAddress.editState) || (!step.MailingAddress.activeState && step.MailingAddress.editState)" />
                <div class="form-error error errortextOnly">Please provide text and numbers only.</div>
              </div>

              <div class="halfForm columns small-12 medium-6 cell">
                <label>City <span class="req">*</span></label>
                <input type="text" maxlength="150" v-model="form.ClaimForm.City" required pattern="^[A-Za-z\s]*$"
                  :disabled="(!step.MailingAddress.activeState && !step.MailingAddress.editState) || (!step.MailingAddress.activeState && step.MailingAddress.editState)" />
                <div class="form-error error errortextOnly">Please provide text only.</div>
              </div>

              <div class="qtrForm stateField columns small-12 medium-3 cell">
                <label>State/Province <span class="req">*</span></label>
                <select v-model="form.ClaimForm.State" required
                  :disabled="(!step.MailingAddress.activeState && !step.MailingAddress.editState) || (!step.MailingAddress.activeState && step.MailingAddress.editState)">
                  <option :value="null" disabled selected></option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
                <div class="form-error error errortextOnly">Please select one option.</div>
              </div>

              <div class="qtrForm qtrFormRight columns small-12 medium-3 cell">
                <label>Zip/Postal Code <span class="req">*</span></label>
                <input type="text" maxlength="5" v-model="form.ClaimForm.Zip" required pattern="number"
                  :disabled="(!step.MailingAddress.activeState && !step.MailingAddress.editState) || (!step.MailingAddress.activeState && step.MailingAddress.editState)" />
                <span class="form-error error zipError">Please provide numbers only.</span>
              </div>

              <div class="columns small-12 medium-12 cell">
                <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9"
                  v-if="step.MailingAddress.activeState && isLoading" />

                <button class="button submit" id="section-mailing-address" @click="getSection($event)"
                  v-if="step.MailingAddress.activeState && !step.MailingAddress.editState && !isLoading"
                  :disabled="!step.MailingAddress.activeState">Confirm Address</button>

                <button class="button" id="section-mailing-address" @click="editBtn($event)"
                  v-if="step.MailingAddress.editState">Edit Mailing Address</button>
              </div>

              <USPS :mailingAddress="this.form.ClaimForm.Address1" :addressContinued="this.form.ClaimForm.Address2"
                :city="this.form.ClaimForm.City" :state="this.form.ClaimForm.State" :zipcode="this.form.ClaimForm.Zip"
                country="USA" ref="uspsValidate">
              </USPS>
            </fieldset>

            <!-- RETAILER INFO -->
            <fieldset class="grid-x grid-margin-x grid-margin-y section-retailer-info" ref="section-retailer-info"
              :class="{ 'previewOnly': step.RetailerInfo.activeState, 'disabled': !step.RetailerInfo.activeState }">

              <div class="columns small-12 medium-12 cell fullForm radioGroup radiosInline">
                <h3>Lottery Retailer Information</h3>
              </div>

              <div class="columns small-12 medium-12 cell fullForm radioGroup radiosInline">
                <label class="radioLabel">Are you a Lottery Retailer? <span class="req">*</span></label>

                <input type="radio" name="IsRetailer" id="radRetYes" v-model="form.ClaimForm.IsRetailer" :value="true"
                  :disabled="(!step.RetailerInfo.activeState && !step.RetailerInfo.editState) || (!step.RetailerInfo.activeState && step.RetailerInfo.editState)" />
                <label for="radRetYes">Yes</label>

                <input type="radio" name="IsRetailer" id="radRetNo" v-model="form.ClaimForm.IsRetailer" :value="false"
                  required
                  :disabled="(!step.RetailerInfo.activeState && !step.RetailerInfo.editState) || (!step.RetailerInfo.activeState && step.RetailerInfo.editState)" />
                <label for="radRetNo">No</label>

                <div class="form-error error errortextOnly">Please select one option.</div>
              </div>

              <div class="columns small-12 medium-12 cell fullForm radioGroup radiosInline">
                <label class="radioLabel">Are you employed by a Lottery Retailer? <span class="req">*</span></label>

                <input type="radio" name="IsRetailerEmployed" id="radRetEmplYes"
                  v-model="form.ClaimForm.IsRetailerEmployed" :value="true"
                  :disabled="(!step.RetailerInfo.activeState && !step.RetailerInfo.editState) || (!step.RetailerInfo.activeState && step.RetailerInfo.editState)" />
                <label for="radRetEmplYes">Yes</label>

                <input type="radio" name="IsRetailerEmployed" id="radRetEmplNo" required
                  v-model="form.ClaimForm.IsRetailerEmployed" :value="false"
                  :disabled="(!step.RetailerInfo.activeState && !step.RetailerInfo.editState) || (!step.RetailerInfo.activeState && step.RetailerInfo.editState)" />
                <label for="radRetEmplNo">No</label>

                <div class="form-error error errortextOnly">Please select one option.</div>
              </div>

              <div class="columns small-12 medium-12 cell fullForm radioGroup radiosInline">
                <label class="radioLabel">Are you related to a Lottery Retailer? <span class="req">*</span></label>

                <input type="radio" name="IsRetailerRelated" id="radRetFamYes"
                  v-model="form.ClaimForm.IsRetailerRelated" :value="true"
                  :disabled="(!step.RetailerInfo.activeState && !step.RetailerInfo.editState) || (!step.RetailerInfo.activeState && step.RetailerInfo.editState)" />
                <label for="radRetFamYes">Yes</label>

                <input type="radio" name="IsRetailerRelated" id="radRetFamNo" required
                  v-model="form.ClaimForm.IsRetailerRelated" :value="false"
                  :disabled="(!step.RetailerInfo.activeState && !step.RetailerInfo.editState) || (!step.RetailerInfo.activeState && step.RetailerInfo.editState)" />
                <label for="radRetFamNo">No</label>

                <div class="form-error error errortextOnly">Please select one option.</div>
              </div>

              <div class="columns small-12 medium-12 cell fullForm radioGroup radiosInline"
                v-show="form.ClaimForm.IsRetailerRelated">
                <p>If YES to any of the above, please provide the following:</p>
                <div class="halfForm">
                  <label>Retailer Name <span class="req">*</span></label>
                  <input type="text" v-model="form.ClaimForm.RelatedRetailerName" pattern="^[a-zA-Z0-9 ]*$"
                    :disabled="(!step.RetailerInfo.activeState && !step.RetailerInfo.editState) || (!step.RetailerInfo.activeState && step.RetailerInfo.editState)"
                    :required="form.ClaimForm.IsRetailerRelated" />
                  <div class="form-error error errortextOnly">Please provide a retail name.</div>
                </div>
                <div class="halfForm">
                  <label id="lbltxtRelatedRetailerNumber" for="txtRelatedRetailerNumber">Retailer Number <span
                      class="req">*</span></label>
                  <input type="text" id="txtRelatedRetailerNumber" class="RelatedRetailerNumber"
                    v-model="form.ClaimForm.RelatedRetailerNumber" maxlength="6" pattern="number"
                    :disabled="(!step.RetailerInfo.activeState && !step.RetailerInfo.editState) || (!step.RetailerInfo.activeState && step.RetailerInfo.editState)"
                    :required="form.ClaimForm.IsRetailerRelated" />
                  <div class="form-error error errortextOnly">Please provide a valid retailer number.</div>
                </div>
              </div>

              <div class="columns small-12 medium-12 cell">
                <button class="button submit" id="section-retailer-info" @click="getSection($event)"
                  v-if="(step.RetailerInfo.activeState && !step.RetailerInfo.editState) || (!step.RetailerInfo.activeState && !step.RetailerInfo.editState)"
                  :disabled="!step.RetailerInfo.activeState">Save & Next</button>

                <button class="button" id="section-retailer-info" @click="editBtn($event)"
                  v-if="step.RetailerInfo.editState">Edit Retailer Information</button>
              </div>
            </fieldset>

            <!-- MEDIA INFO -->
            <fieldset class="grid-x grid-margin-x grid-margin-y section-media-info"
              :class="{ 'previewOnly': step.MediaInfo.activeState, 'disabled': !step.MediaInfo.activeState }">

              <div class="columns small-12 medium-12 cell">
                <h3>Media Acknowledgement</h3>
                <p>I understand that, as a matter of public record, the Ohio Lottery will release
                  my name, hometown, state and the prize amount I won playing Ohio Lottery
                  games to the media, to the Ohio Lottery Web site and, upon request, to other
                  inquiries.</p>
                <p>Information submitted in connection with an Ohio Lottery claim and/or
                  prize payment may be a public record under section 149.43 of the Ohio
                  Revised Code and subject to release. I understand that my name, address,
                  telephone and/or likeness may be utilized for administrative, marketing and/
                  or promotional purposes.</p>
                <p>To remain anonymous and ensure <strong>NO</strong> media or publicity, my prize must
                  be claimed in a <strong>Blind Trust</strong>. To begin the process of a Blind Trust, I or my
                  legal counsel should contact the Legal Department at the Ohio Lottery in
                  Cleveland at 216-774-5652 <strong>before</strong> the claim form is completed.</p>
              </div>
              <div class="columns small-12 medium-12 cell">
                <div class="fullForm radioGroup radiosInline">
                  <strong>Photo Use Permission</strong><br />
                  <label>I give permission to the Ohio Lottery Commission to use photographs taken of me for use in
                    print, television, film, internet or any other media. <span class="req">*</span></label>

                  <input type="radio" name="photoUse" id="radPhotoYes" v-model="form.ClaimForm.AllowPhotoUse"
                    :value="true"
                    :disabled="(!step.MediaInfo.activeState && !step.MediaInfo.editState) || (!step.MediaInfo.activeState && step.MediaInfo.editState)" />
                  <label for="radPhotoYes">Yes</label>

                  <input type="radio" name="photoUse" id="radPhotoNo" required v-model="form.ClaimForm.AllowPhotoUse"
                    :value="false"
                    :disabled="(!step.MediaInfo.activeState && !step.MediaInfo.editState) || (!step.MediaInfo.activeState && step.MediaInfo.editState)" />
                  <label for="radPhotoNo">No</label>

                  <div class="form-error error errortextOnly">Please select one option.</div>
                </div>
                <div class="fullForm radioGroup radiosInline">
                  <strong>Advertising and Permission</strong><br />
                  <label>I am interested in being contacted by the Ohio Lottery or one of the Ohio Lottery's vendor
                    representatives for future agency advertising and promotional needs. <span
                      class="req">*</span></label>

                  <input type="radio" name="adUse" id="radAdYes" v-model="form.ClaimForm.AllowAdvertising" :value="true"
                    :disabled="(!step.MediaInfo.activeState && !step.MediaInfo.editState) || (!step.MediaInfo.activeState && step.MediaInfo.editState)" />
                  <label for="radAdYes">Yes</label>

                  <input type="radio" name="adUse" id="radAdNo" required v-model="form.ClaimForm.AllowAdvertising"
                    :value="false"
                    :disabled="(!step.MediaInfo.activeState && !step.MediaInfo.editState) || (!step.MediaInfo.activeState && step.MediaInfo.editState)" />
                  <label for="radAdNo">No</label>

                  <div class="form-error error errortextOnly">Please select one option.</div>
                </div>
              </div>

              <div class="columns small-12 medium-12 cell">
                <button class="button submit" id="section-media-info" @click="getSection($event)"
                  v-if="(step.MediaInfo.activeState && !step.MediaInfo.editState) || (!step.MediaInfo.activeState && !step.MediaInfo.editState)"
                  :disabled="!step.MediaInfo.activeState">Save & Next</button>

                <button class="button" id="section-media-info" @click="editBtn($event)"
                  v-if="step.MediaInfo.editState">Edit Media Acknowledgement</button>
              </div>
            </fieldset>

            <!-- REQUIRED DOCS -->
            <fieldset class="grid-x grid-margin-x grid-margin-y section-required-docs"
              :class="{ 'previewOnly': step.RequiredDocs.activeState, 'disabled': !step.RequiredDocs.activeState }">

              <h3>Required Documents</h3>
              <p class="cell large-12 halfForm">Please upload a .pdf, .jpg, .jpeg, or .png file for each of the items
                below. Images must be between 600px and 6000px wide. Files should be no larger than 5mb. Most images
                taken with a phone should be meet this criteria.</p>

              <div class="cell small-12 large-6  file-input">
                <label>Front of Ticket <span class="req">*</span></label>
                <input type="file" required accept="image/png, image/gif, image/jpeg, application/pdf" id="0"
                  @input="form.TicketImageFront = $event.target.files[0]" @change="validFile($event)"
                  :disabled="(!step.RequiredDocs.activeState && !step.RequiredDocs.editState) || (!step.RequiredDocs.activeState && step.RequiredDocs.editState)" />
                <div class="form-error error fileType">Please upload a .pdf, .jpg, .jpeg, or .png file.</div>
                <div class="error filesize">File is too large. Please upload a file less than 5mb.</div>
              </div>

              <div class="cell small-12 large-6  file-input">
                <label>Back of Ticket <span class="req">*</span></label>
                <input type="file" required accept="image/png, image/jpeg, application/pdf"
                  pattern="^.+?\.(pdf|PDF|jpg|JPG|jpeg|JPEG|png|PNG)$" id="1"
                  @input="form.TicketImageBack = $event.target.files[0]" @change="validFile($event)"
                  :disabled="(!step.RequiredDocs.activeState && !step.RequiredDocs.editState) || (!step.RequiredDocs.activeState && step.RequiredDocs.editState)" />
                <div class="form-error error fileType">Please upload a .pdf, .jpg, .jpeg, or .png file.</div>
                <div class="error filesize">File is too large. Please upload a file less than 5mb.</div>
              </div>

              <!-- fileclaim_front & fileclaim_back -->
              <div class="cell small-12 large-6 file-input" v-if="(form.ClaimForm.PrizeAmount > 5000)">
                <label>File Claim Receipt <span class="req">*</span></label>
                <input type="file" accept="image/png, image/jpeg, application/pdf"
                  pattern="^.+?\.(pdf|PDF|jpg|JPG|jpeg|JPEG|png|PNG)$" id="3"
                  @input="form.ClaimReceipt = $event.target.files[0]" @change="validFile($event)"
                  :disabled="(form.ClaimForm.PrizeAmount >= 600 && form.ClaimForm.PrizeAmount <= 5000 && form.ClaimForm.PtoBFlag)
      || (!step.RequiredDocs.activeState && !step.RequiredDocs.editState) || (!step.RequiredDocs.activeState && step.RequiredDocs.editState)" :required="(form.ClaimForm.PrizeAmount > 5000)" />

                <div class="form-error error fileType">Please upload a .pdf, .jpg, .jpeg, or .png file.</div>
                <div class="error filesize">File is too large. Please upload a file less than 5mb.</div>
              </div>

              <div class="cell small-12 large-6  file-input" v-if="(form.ClaimForm.PrizeAmount > 5000)">
                <label>File Claim Receipt Back <span class="req">*</span></label>
                <input type="file" accept="image/png, image/jpeg, application/pdf"
                  pattern="^.+?\.(pdf|PDF|jpg|JPG|jpeg|JPEG|png|PNG)$" id="4"
                  @input="form.ClaimReceiptBack = $event.target.files[0]" @change="validFile($event)"
                  :disabled="(form.ClaimForm.PrizeAmount >= 600 && form.ClaimForm.PrizeAmount <= 5000 && form.ClaimForm.PtoBFlag)
      || (!step.RequiredDocs.activeState && !step.RequiredDocs.editState) || (!step.RequiredDocs.activeState && step.RequiredDocs.editState)" :required="(form.ClaimForm.PrizeAmount > 5000)" />

                <div class="form-error error fileType">Please upload a .pdf, .jpg, .jpeg, or .png file.</div>
                <div class="error filesize">File is too large. Please upload a file less than 5mb.</div>
              </div>
              <!-- fileclaim_front & fileclaim_back -->

              <!-- ptob_front & ptob_back -->
              <div class="cell small-12 large-6 file-input"
                v-if="(form.ClaimForm.PrizeAmount >= 600 && form.ClaimForm.PrizeAmount <= 5000 && form.ClaimForm.PtoBFlag)">

                <label>Pay-to-Bearer Front of Ticket <span class="req">*</span></label>
                <input type="file" accept="image/png, image/jpeg, application/pdf"
                  pattern="^.+?\.(pdf|PDF|jpg|JPG|jpeg|JPEG|png|PNG)$" id="3"
                  @input="form.PtoBFront = $event.target.files[0]" @change="validFile($event)"
                  :disabled="(!step.RequiredDocs.activeState && !step.RequiredDocs.editState || !step.RequiredDocs.activeState && step.RequiredDocs.editState)"
                  :required="form.ClaimForm.PtoBFlag" />

                <div class="form-error error fileType">Valid Pay-to-Bearer Front Ticket image is not valid. Provided
                  file format is not supported.</div>
                <div class="error filesize">File is too large. Please upload a file less than 5mb.</div>
              </div>

              <div class="cell small-12 large-6 file-input"
                v-if="(form.ClaimForm.PrizeAmount >= 600 && form.ClaimForm.PrizeAmount <= 5000 && form.ClaimForm.PtoBFlag)">
                <label>Pay-to-Bearer Back of Ticket <span class="req">*</span></label>
                <input type="file" accept="image/png, image/jpeg, application/pdf"
                  pattern="^.+?\.(pdf|PDF|jpg|JPG|jpeg|JPEG|png|PNG)$" id="4"
                  @input="form.PtoBBack = $event.target.files[0]" @change="validFile($event)"
                  :disabled="(!step.RequiredDocs.activeState && !step.RequiredDocs.editState) || (!step.RequiredDocs.activeState && step.RequiredDocs.editState)"
                  :required="form.ClaimForm.PtoBFlag" />

                <div class="form-error error fileType">Valid Pay-to-Bearer Back Ticket image is not valid. Provided file
                  format is not supported.</div>
                <div class="error filesize">File is too large. Please upload a file less than 5mb.</div>
              </div>
              <!-- ptob_front & ptob_back -->

              <div class="cell small-12 large-6 file-input">
                <label>Valid Photo ID <span class="req">*</span></label>
                <input type="file" required accept="image/png, image/gif, image/jpeg, application/pdf" id="2"
                  @input="form.DriverLicense = $event.target.files[0]" @change="validFile($event)"
                  :disabled="(!step.RequiredDocs.activeState && !step.RequiredDocs.editState) || (!step.RequiredDocs.activeState && step.RequiredDocs.editState)" />

                <div class="form-error error fileType">Valid Photo ID image is not valid. Provided file format is not
                  supported.</div>
                <div class="error filesize">File is too large. Please upload a file less than 5mb.</div>
              </div>

              <div class="columns small-12 medium-12 cell">

                <button class="button submit" id="section-required-docs" @click="getSection($event)"
                  v-if="(step.RequiredDocs.activeState && !step.RequiredDocs.editState) || (!step.RequiredDocs.activeState && !step.RequiredDocs.editState)"
                  :disabled="!step.RequiredDocs.activeState">Save & Next</button>

                <button class="button" id="section-required-docs" @click="editBtn($event)" v-if="step.RequiredDocs.editState">Edit Required Docs</button>
              </div>
            </fieldset>

            <!-- SIGNATURE -->
            <fieldset class="grid-x grid-margin-x grid-margin-y section-signature"
              :class="{ 'previewOnly': step.Signature.activeState, 'disabled': !step.Signature.activeState }">
              <div class="cell small-12 large-6">
                <label>Digital Signature - please type your full name below:</label>
                <input type="text" v-model="form.ClaimForm.Signature" required pattern="^[A-Za-z\s]*$"
                  :disabled="(!step.Signature.activeState && !step.Signature.editState) || (!step.Signature.activeState && step.Signature.editState) && submitAllForm" />
                <div class="form-error error sigError">Please provide your digital signature.</div>
              </div>

              <div class="columns small-12 medium-12 cell">
                <button class="button submit" id="section-signature" @click="getSection($event)"
                  v-if="step.Signature.activeState  && !submitAllForm" :disabled="!step.Signature.activeState">Submit Claim</button>

                  <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="submitAllForm" />
              </div>
            </fieldset>
          </form>

        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TicketChecker from "../TicketChecker/TicketChecker.vue";
import SignInRegister from "../../ViewComponents/SignInRegister/SignInRegister.vue";
import USPS from "../Shared/USPS-AddressCheck.vue";
import Datepicker from "vue-moment-datepicker";
import moment from 'moment';

import $ from 'jquery';
import { HollowDotsSpinner } from 'epic-spinners'

export default {
  components: {
    SignInRegister,
    TicketChecker,
    HollowDotsSpinner,
    USPS,
    Datepicker
  },
  data: function () {
    return {
      showTicketEntryForm: false,
      TicketChecker: false,
      errorMessage: false,
      showForm: false,
      formSection: '',
      lastSection: '',
      showRetailerDetails: false,
      isLoading: false,
      arrValidFiles: [],
      statusCode: null,
      messageApi: null,
      state: {
        disabledDates: {
          from: ''
        }
      },
      step: {
        ContactInfo: {
          activeState: true,
          editState: false
        },
        MailingAddress: {
          activeState: false,
          editState: false
        },
        RetailerInfo: {
          activeState: false,
          editState: false
        },
        MediaInfo: {
          activeState: false,
          editState: false
        },
        RequiredDocs: {
          activeState: false,
          editState: false
        },
        Signature: {
          activeState: false,
          editState: false
        },
      },
      form: {
        DriverLicense: null,
        ClaimReceipt: null,
        ClaimReceiptBack: null,
        TicketImageFront: null,
        TicketImageBack: null,
        PtoBFront: null,
        PtoBBack: null,
        ClaimForm: {
          UserID: this.$cookies.get('apiToken').user.userId,
          //
          TicketNumber: '',
          PrizeAmount: '',
          GameName: '',
          DrawDate: '',
          PtoBFlag: null,
          //
          DoB: this.$cookies.get('apiToken').user.birthdate,
          PhoneNumber: this.$cookies.get('apiToken').user.phoneNumber,
          FirstName: this.$cookies.get('apiToken').user.firstName,
          MI: '',
          LastName: this.$cookies.get('apiToken').user.lastName,
          SSN: '',
          Email: this.$cookies.get('apiToken').user.email,
          //
          Address1: '',
          Address2: '',
          City: '',
          State: 'OH',
          Zip: '',
          //
          IsRetailer: null,
          IsRetailerEmployed: null,
          IsRetailerRelated: null,
          RelatedRetailerName: '',
          RelatedRetailerNumber: '',

          RetailerNumber: '',
          //
          AllowPhotoUse: null,
          AllowAdvertising: null,
          //
          Signature: '',
          //
          TicketImages: [],
          ClaimReceipt: '',
          ClaimReceiptBack: '',
          PtoBFront: '',
          PtoBBack: '',
          DriverLicense: '',
          User: {
            FirstName: this.$cookies.get('apiToken').user.firstName,
            LastName: this.$cookies.get('apiToken').user.lastName,
            Email: this.$cookies.get('apiToken').user.email,
            UserId: this.$cookies.get('apiToken').user.userId,
            Username: this.$cookies.get('apiToken').user.username,
            Nickname: this.$cookies.get('apiToken').user.nickname,
            MainCurrency: this.$cookies.get('apiToken').user.mainCurrency,
            ListOfRoles: this.$cookies.get('apiToken').user.listOfRoles,
            Address: this.$cookies.get('apiToken').user.address,
            PointBalance: this.$cookies.get('apiToken').user.PointBalance,
            LastLogin: this.$cookies.get('apiToken').user.LastLogin,
            LoginInARowCount: this.$cookies.get('apiToken').user.loginInARowCount
          },
        }
      },
      contacInfoErr: false,
      submitAllForm: false,
      hasError: false
    };
  },
  mounted: function () {
    this.showTicketEntryForm = this.isUserAuthed();
    let _this = this;
    _this.getCurrentDate();

    Foundation.Abide.defaults.patterns['phone_number'] = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    $('button.submit').on("click", "tr", function () {
      // console.log($(this).text());
    });
  },
  methods: {
    forceAbide() {
      let _this = this,
        validateNow = true;

      $('#bigForm').foundation();

      if (validateNow === true) {
        $('#bigForm').on("formvalid.zf.abide", function () {
          if (_this.formSection === 'section-contact-info' && validateNow === true) {
            _this.getAPItoken()
              .then((token) => {
                _this.submitContactInfo(token);
              });

            validateNow = false;
          }

          if (_this.formSection === 'section-mailing-address' && validateNow === true) {
            _this.isLoading = true;
            _this.$refs.uspsValidate.validate();
            validateNow = false;
          }

          if (_this.formSection === 'section-retailer-info' && validateNow === true) {
            _this.step.RetailerInfo.activeState = false;
            _this.step.RetailerInfo.editState = true;

            _this.step.MediaInfo.activeState = true;
            validateNow = false;
          }

          if (_this.formSection === 'section-media-info' && validateNow === true) {
            _this.step.MediaInfo.activeState = false;
            _this.step.MediaInfo.editState = true;

            _this.step.RequiredDocs.activeState = true;
            validateNow = false;
          }

          if (_this.formSection === 'section-required-docs' && validateNow === true) {
            let checker = arr => arr.every(Boolean);
            if (checker(_this.arrValidFiles)) {
              _this.step.RequiredDocs.activeState = false;
              _this.step.RequiredDocs.editState = true;

              _this.step.Signature.activeState = true;
            }
            validateNow = false;
          }

          if (_this.formSection === 'section-signature' && validateNow === true) {
            _this.getAPItoken()
              .then((token) => {
                _this.submitBigForm(token);
              });
            validateNow = false;
          }

          setTimeout(() => {
            this.formSection = ''
          }, 100);
          // _this.isLoading = true;
        }).on("submit", function (ev) {
          ev.preventDefault();
        }).on("click", ".submit", function () {
          $("#bigForm").submit();
        });
      }
    },
    customFormatter(date) {
      return moment(date).format('MM-DD-yyyy');
    },
    scrollToAnchor(refName) {
      const el = this.$refs[refName]
      el.scrollIntoView({ behavior: 'smooth' })
    },
    editBtn(e) {
      let section = e.target.id;
      if (section === 'section-contact-info') {
        this.step.ContactInfo.activeState = true;
        this.step.ContactInfo.editState = false;

        this.step.MailingAddress.activeState = false;
        this.step.RetailerInfo.activeState = false;
        this.step.RequiredDocs.activeState = false;
        this.step.Signature.activeState = false;
      }
      if (section === 'section-mailing-address') {
        this.step.MailingAddress.activeState = true;
        this.step.MailingAddress.editState = false;

        this.step.RetailerInfo.activeState = false;
        this.step.RequiredDocs.activeState = false;
        this.step.Signature.activeState = false;
      }
      if (section === 'section-retailer-info') {
        this.step.RetailerInfo.activeState = true;
        this.step.RetailerInfo.editState = false;

        this.step.MediaInfo.activeState = false;
        this.step.RequiredDocs.activeState = false;
        this.step.Signature.activeState = false;
      }
      if (section === 'section-media-info') {
        this.step.MediaInfo.activeState = true;
        this.step.MediaInfo.editState = false;

        this.step.RequiredDocs.activeState = false;
        this.step.Signature.activeState = false;
      }
      if (section === 'section-required-docs') {
        this.step.RequiredDocs.activeState = true;
        this.step.RequiredDocs.editState = false;

        this.step.Signature.editState = false;
      }

    },
    getToken() {
      // then hit API with token
    },
    getCurrentDate() {
      const dateObj = new Date();
      let year = dateObj.getUTCFullYear();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let day = dateObj.getUTCDate();
      this.state.disabledDates.from = new Date(year, month - 1, day + 1);
    },
    getSection(e) {
      e.preventDefault();
      this.formSection = ''
      let section = e.target.id;
      this.formSection = section;
      this.lastSection = this.formSection;
      this.forceAbide();
    },
    phoneFormat(e) {
      let val = e.target.value;
      let id = e.target.id;

      let regx = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
      if (val !== '') {
        let formatVal = regx.test(val);
        if (formatVal !== false) {
          let formatPhone = val.replace(/\s/g, '-')
          document.querySelector('#' + id).value = formatPhone;
        }

      }
    },
    // *************** GET TICKECT CHECKER
    getTicketChecker(data) {

      if (data.showClaimForm) {
        this.form.ClaimForm.TicketNumber = data.TicketNumber;
        this.form.ClaimForm.PrizeAmount = data.ticketChecker.ticketWinAmount;
        this.form.ClaimForm.GameName = data.ticketChecker.gameName;

        //FIX ticketTransactionTimeStamp
        if (data.ticketChecker.ticketTransactionTimeStamp === 0) {
          const currentDate = new Date();
          const isoFormattedDate = currentDate.toISOString();
          this.form.ClaimForm.DrawDate = isoFormattedDate;
        } else {
          let ticketDate = new Date(data.ticketChecker.ticketTransactionTimeStamp);
          ticketDate = ticketDate.toISOString();
          this.form.ClaimForm.DrawDate = ticketDate;
        }
        // console.log(this.form.ClaimForm.DrawDate);

        this.form.ClaimForm.PtoBFlag = data.ticketChecker.ticketPTB_flag;

        this.TicketChecker = false;
        this.showForm = true;
        this.step.ContactInfoformShow = true;

      } else {
        this.TicketChecker = true;
        this.showForm = false;
        this.step.ContactInfoformShow = false;
      }

    },

    // *************** SUBMITES
    submitContactInfo(token) {
      var apiToken = token;
      var urlProtocol = this.getProtocol();

      
      var request = {
        firstName: this.form.ClaimForm.FirstName,
        lastName: this.form.ClaimForm.LastName,
        ssn: this.form.ClaimForm.SSN,
        address: "",
        city: "",
        state: "",
        zip: "",
      }

      this.isLoading = true;

      return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/PAM/ValidateUser/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + apiToken,
        },
        body: JSON.stringify(request)
      })
        .then(res => res.json())
        .then(res => {

          // console.log(res);
          if (res.data) {
            // Use !res.data in the if condition to bypass for testing
            this.step.ContactInfo.activeState = false;
            this.step.ContactInfo.editState = true;

            this.step.MailingAddress.activeState = true;
            this.step.MailingAddress.editState = false;

            this.contacInfoErr = false;
          } else {
            // Show these lines on PROD
            this.step.ContactInfo.activeState = true;
            this.step.ContactInfo.editState = false;

            // Hide these lines on PROD
            // this.step.ContactInfo.activeState = false;
            // this.step.ContactInfo.editState = true;

            this.step.MailingAddress.activeState = false;
            this.step.MailingAddress.editState = false;

            this.contacInfoErr = true;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    fetchData(token) {
      var apiToken = token;
      var urlProtocol = this.getProtocol();

      let fd = new FormData();

      let data = JSON.stringify(this.form);
      fd.append('data', data)

      if (this.form.FileOne != '') {
        // fd.append('name', this.form.FileOne.name)
        fd.append('FileOne', this.form.FileOne)
      }

      if (this.form.FileTwo != '') {
        // fd.append('name', this.form.FileTwo.name)
        fd.append('FileTwo', this.form.FileTwo)
      }

      if (this.form.FileThree != '') {
        // fd.append('name', this.form.FileThree.name)
        fd.append('FileThree', this.form.FileThree)
      }

      this.submitAllForm = true;
      return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Form/SubmitClaimEntry", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + apiToken,
        },
        body: fd
      })
        .then(res => res.json())
        .then(res => {
          console.log(res);
          this.submitAllForm = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    submitBigForm(token) {
      var apiToken = token;
      var urlProtocol = this.getProtocol();

      let fd = new FormData();

      fd.append("userId", this.$cookies.get('apiToken').user.userId);

      if (this.form.DriverLicense != '') {
        // fd.append('name', this.form.FileOne.name)
        fd.append('DriverLicense', this.form.DriverLicense)
      }

      if (this.form.ClaimReceipt != '') {
        // fd.append('name', this.form.FileTwo.name)
        fd.append('ClaimReceipt', this.form.ClaimReceipt)
      }

      if (this.form.ClaimReceiptBack != '') {
        // fd.append('name', this.form.FileThree.name)
        fd.append('ClaimReceiptBack', this.form.ClaimReceiptBack)
      }

      if (this.form.TicketImageFront != '') {
        // fd.append('name', this.form.FileTwo.name)
        fd.append('TicketImageFront', this.form.TicketImageFront)
      }

      if (this.form.TicketImageBack != '') {
        // fd.append('name', this.form.FileThree.name)
        fd.append('TicketImageBack', this.form.TicketImageBack)
      }

      if (this.form.PtoBFront != '') {
        // fd.append('name', this.form.FileTwo.name)
        fd.append('PtoBFront', this.form.PtoBFront)
      }

      if (this.form.PtoBBack != '') {
        // fd.append('name', this.form.FileThree.name)
        fd.append('PtoBBack', this.form.PtoBBack)
      }

      let data = JSON.stringify(this.form.ClaimForm);
      fd.append('ClaimForm', data)

      this.submitAllForm = true;
      return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Form/SubmitClaimEntry", {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + apiToken,
        },
        body: fd
      })
        .then(res => res.json())
        .then(res => {
          // console.log(res.statusCode);
          if (res.statusCode === 200 && res.error == '' || res.error == null) {
            // success
            this.statusCode = res.statusCode;
            this.messageApi = res.data;

          } else if (res.statusCode === 200 && res.error !== '' || res.error !== null) {
            // failed successfully
            this.statusCode = res.statusCode;
            this.hasError = true;
            if (res.data === null) {
              this.messageApi = 'There was an error processing the form. Please try again.';
              // console.log(res.error);
            } else {
              this.messageApi = res.data;
            }

          } else {
            // uknown errors
            this.statusCode = res.statusCode;
            this.messageApi = res.data;
            this.hasError = true;
            // console.log(res);

          }
          this.submitAllForm = false;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    // *************** MAILING ADDREES
    uspsVerified(msg) {
      this.isLoading = true;
      if (!msg.address || msg.address === '') {
        this.uspsClose();
        this.isLoading = false;
      } else {
        this.uspsClose();
        this.isLoading = false;

        if (msg.isProvidedAddressAvailable && msg.isSuggestedAddressAvailable) {
          this.form.ClaimForm.Address1 = msg.mailingAddress;
          // this.form.ClaimForm.Address2 = msg.mailingAddress;
          this.form.ClaimForm.City = msg.city;
          this.form.ClaimForm.State = msg.state;
          this.form.ClaimForm.Zip = msg.zip;

          this.step.MailingAddress.activeState = false;
          this.step.MailingAddress.editState = true;

          this.step.RetailerInfo.activeState = true;

        } else if (!msg.isProvidedAddressAvailable && !msg.isSuggestedAddressAvailable) {

          this.step.MailingAddress.activeState = true;
          this.step.MailingAddress.editState = true;
          this.step.RetailerInfo.activeState = false;
        }
      }


    },
    uspsOpen() {
      this.isLoading = false;
      $('#USPSmodal').foundation('open');
    },
    uspsClose() {
      this.isLoading = false;
      $('#USPSmodal').foundation('close');
      this.scrollToAnchor('section-mailing-address');
    },

    // *************** REQUIRED DOCS
    validFile(e) {
      if (e.target.files[0] != undefined) {
        let fileSize = e.target.files[0].size;
        let size = Math.round((fileSize / 1024));
        if (size >= 4996) {
          this.arrValidFiles[e.target.id] = false;
          e.target.closest('.file-input').querySelector('.filesize').style.display = "block";
          e.target.closest('.file-input').querySelector('input').classList.add('is-invalid-size');
        } else {
          this.arrValidFiles[e.target.id] = true;
          e.target.closest('.file-input').querySelector('.filesize').style.display = "none";
          e.target.closest('.file-input').querySelector('input').classList.remove('is-invalid-size');
        }
      }
    },

  },
  watch: {
    'form.ClaimForm.IsRetailer': function (newVal) {
      this.form.ClaimForm.IsRetailer = Boolean(newVal)
    },
    'form.ClaimForm.IsRetailerEmployed': function (newVal) {
      this.form.ClaimForm.IsRetailerEmployed = Boolean(newVal)
    },
    'form.ClaimForm.IsRetailerRelated': function (newVal) {
      this.form.ClaimForm.IsRetailerRelated = Boolean(newVal)
    },
    'form.ClaimForm.AllowPhotoUse': function (newVal) {
      this.form.ClaimForm.AllowPhotoUse = Boolean(newVal)
    },
    'form.ClaimForm.AllowAdvertising': function (newVal) {
      this.form.ClaimForm.AllowAdvertising = Boolean(newVal)
    },
  }
};
</script>
<style lang="scss" scoped>
.ticket-checker-form:empty{
  display: none;
}
.dcf_forms {
  background-color: #f1efee;
  padding: 20px;

  fieldset {
    margin-bottom: 30px;
  }

  select {
    // fix Line Selected
    line-height: 1.25;
  }

  input,
  select {
    background-color: white;

    &:disabled {
      background-color: #e6e6e6 !important;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        background-color: #e6e6e6 !important;
      }
    }
  }

  &.previewOnly,
  &.disabled {
    opacity: 1;

    label,
    input,
    textarea,
    select {
      pointer-events: none;
      opacity: .5;
    }
  }

  .file-input {
    input {
      height: 3.4609375rem;
      line-height: 32px;
      border: 0;

      &::file-selector-button {
        padding: 0 5px !important;
        border-radius: 0.8rem !important;
        border: 1px solid #969696;
      }

      &.is-invalid-input,
      &.is-invalid-size {
        &::file-selector-button {
          border-color: #cc4b37;
          background-color: #faedeb;
        }
      }
    }

    .fileSize {
      display: none;
    }
  }

  .filesize {
    display: none;
  }

}

.txtDateOfContact[readonly] {
  background-color: white;
}

.form-error {
  margin-top: .5rem;
  margin-bottom: 0;
}
</style>