<template>
    <div class="winner-stories-grid" v-if="!isLoading">

        <div class="winnerStories-featured">
            <h2 class="storyListHeader">FEATURED STORIES</h2>
            <div class="featuredStories">
                <div class="txtCenter" v-if="featuredStories.length < 1"><strong>No featured stories to show.</strong></div>
                <div class="featuredStoriesWrap" v-else>
                    <div v-for="featured in featuredStories" class="featuredStoryBlock">
                        <div @click="openFeaturedModalItem($event)">
                        <img :src="featured.image1URL" alt="Featured Winner Story">
                        <div class="story-modal-content">
                            <div class="grid-x grid-margin-x modal-content">
                                <div class="cell medium-6">
                                    <iframe :src="featured.subTitle" frameborder="0" gesture="media" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="" ></iframe>
                                </div>
                                <div class="cell medium-6">
                                    <h5 class="story-title">{{ featured.title }}</h5>
                                    <p>{{ featured.content }}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="storyListHeader" id="UserStoryListHeader">
            <div>
                <h2>REAL PEOPLE, REAL WINS</h2>
                <span class="storyTotal" style="font-size:1.6rem;position:unset;">{{ items.length }} TOTAL STORIES</span>
            </div>
        </div>
            
        <masonry-wall :items="itemsLoaded" :ssr-columns="1" :column-width="300" :gap="30">
                <template #default="{ item, index }">
                    <div :class="[item.winnerStoriesGuid ? 'storyGridItemInner' : '',`storyGridItemInner-` + index]"  v-on:click="openModalItem($event)" :data-guid="item.winnerStoriesGuid">
                       
                        <img class="storyGridImg notClickSupport" :src="item.photoPathUrl" :alt="item.storyTitle" v-if="!item.videoUrl" />
                        <img class="storyGridImg notClickSupport" :src="setYTThumb(item.videoUrl)" :alt="item.StoryTitle" v-if="item.videoUrl" />
                        <!-- <iframe title="YouTube video player" class="storyGridVid" :src="item.videoUrl" frameborder="0" gesture="media" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="" width="290" height="164" v-if="item.videoUrl"></iframe> -->

                        <!-- Replace the above iframe with youtube thumbnail 'https://img.youtube.com/vi'+videoID+'/0.jpg'-->


                        <div class="storyGridShareLink">
                            Share using this link: 
                            <a :href="`/mylotto-rewards/winners/story-detail?guid=${item.winnerStoriesGuid}`">
                                {{ currentUrl }}/mylotto-rewards/winners/story-detail?guid={{item.winnerStoriesGuid}}
                            </a>
                        </div>
                        
                        <h1 class="storyGridTitle notClickSupport">{{ item.storyTitle}}</h1>
                        <h5 class="storyGridTitle notClickSupport">{{ item.storyTitle}}</h5>
                        <div class="storyGridUser notClickSupport" v-if="item.firstName">by <span class="userName">{{item.firstName}} {{ item.lastName }}</span></div>
                        <div class="storyGridText notClickSupport" v-if="item.storyText">{{ item.storyText }}</div>
                        <div class="storyGridLocation notClickSupport" v-if="item.city">
                            <span class="icon-map-marker icon"></span> <span class="location">{{ item.city }}, {{item.state}}</span>
                        </div>


                        <div class="story-modal-content">
                            <div class="grid-x grid-margin-x modal-content">
                                <div class="cell medium-6">
                                    <img :src="item.photoPathUrl" :alt="item.storyTitle" v-if="!item.videoUrl" />
                                    <iframe :src="item.videoUrl" frameborder="0" gesture="media" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="" v-if="item.videoUrl"></iframe>

                                    <div class="sharing-link">
                                        Share using this link: 
                                        <a :href="`/mylotto-rewards/winners/story-detail?guid=${item.winnerStoriesGuid}`">
                                            {{ currentUrl }}/mylotto-rewards/winners/story-detail?guid={{item.winnerStoriesGuid}}
                                        </a>
                                    </div>

                                </div>
                                <div class="cell medium-6">
                                    <h5 class="story-title">{{ item.storyTitle}}</h5>
                                    <div class="story-username">by <span class="userName">{{item.firstName}} {{ item.lastName }}</span></div>
                                    <div class="story-text">{{ item.storyText }}</div>
                                    <!-- <div>
                                        <span class="icon-map-marker icon"></span> <span class="location">{{ item.city }}, {{item.state}}</span>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
            </template>
        </masonry-wall>

        <div class="load-more-btn text-center" v-if="!hideLoadMore">
            <a @click="loadMore" class="button">{{itemsLeftText}}</a>
        </div>
        
        <template>
            <modal name="story-modal" classes="story-modal" :maxWidth="200" :height="'auto'">
                <button class="close-button" type="button" @click="closeModal()">
                    <span aria-hidden="true">×</span>
                </button>
                <span v-html="modalInnerContent"></span>
            </modal>
        </template>
    </div>
</template>

<script>


    export default {
        components: {
            
        },
        data: function () {
            return {
                isLoading: true,
                items: [],
                modalInnerContent: '',
                maxPerPage: 20,
                hideLoadMore: false,
                itemsLeftText: "Load 20 More",
                currentUrl: window.location.hostname,
                featuredStories: [],
                infographics: [
                    {
                        photoPathUrl: '/getattachment/9e64d08b-b66c-46ae-a500-1800ecfbd08a/stat-04-last-year-over-3-billion-2x.jpg'
                    },
                    {
                        photoPathUrl: '/getattachment/9dfe62eb-9114-4588-8be6-25eaf6a017c3/stat-03-Jan2025-2x.jpg'
                    },
                    {
                        photoPathUrl: '/getattachment/f19023d1-8741-4735-a214-8a0b0e5b854b/stat-02-since1974-2x.jpg'
                    },
                    {
                        photoPathUrl: '/getattachment/26565865-ccfe-4c6f-b699-77151858679c/stat-01-8million-a-day-2x.jpg'
                    },
                    {
                        photoPathUrl: '/getattachment/2a20e3d1-ee8a-456f-a4a1-971be9ac9fcb/stat-05-2024-biggest-winner-2x.jpg'
                    }
                ]
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(apiToken) {
                return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/WinnerStories/GetAllApprovedWinnerStories', {
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    const results = json.data;
                    
                    this.items = results.reverse();
                    // console.log("This.items");
                    // console.log(this.items);
                    this.getFeaturedStories(apiToken);
                }).then(() => {
                    this.interjectInfographics();
                })
            },
            loadMore() {

                

                if( this.itemsLeftText == "Load 20 More"){
                    this.itemsLeftText = "20 MORE INSPIRATIONS"
                }else if ( this.itemsLeftText == "20 MORE INSPIRATIONS" ){
                    this.itemsLeftText = "Show Me 20 More"
                }else if( this.itemsLeftText == "Show Me 20 More"){
                    this.itemsLeftText = "Load 20 More"
                }



                let itemsLeft = this.items.length - this.maxPerPage

                if ( itemsLeft < 20){
                    this.itemsLeftText = "Load 10 More"
                }

                if (this.maxPerPage > this.items.length){
                    this.hideLoadMore = true;
                    return;
                } 
                this.maxPerPage = this.maxPerPage + 20;
            },
            openModalItem(event){


                let elementClasses  = event.target.classList;
                elementClasses = Array.from(elementClasses);

                // console.log("Element Classes and Type");
                // console.log(elementClasses);
                // console.log(typeof elementClasses);

                if (elementClasses.includes('storyGridItemInner-0') || elementClasses.includes('storyGridItemInner-5') || elementClasses.includes('storyGridItemInner-10') || elementClasses.includes('storyGridItemInner-15') || elementClasses.includes('storyGridItemInner-20')) {
                    // console.log('Infograph click')
                    //do nothing
                } else {
                    this.$modal.show('story-modal');
                    this.modalInnerContent = event.target.lastElementChild.innerHTML;
                }


                // console.log("Event Target from openModal func");
                // console.log(event.target.classList);
                // this.$modal.show('story-modal');
                // console.log("Event.Target");
                // console.log(event.target);
                // console.log("Event.Target.lastElementChild.innerHTML");
                // console.log(event.target.lastElementChild.innerHTML);
                // this.modalInnerContent = event.target.lastElementChild.innerHTML;
            },
            openFeaturedModalItem(event) {
                this.$modal.show('story-modal');
                // console.log("Event.Target");
                // console.log(event.target);
                // console.log(event.target.nextSibling.innerHTML);
                this.modalInnerContent = event.target.nextSibling.innerHTML;
            },
            closeModal(){
                this.$modal.hide('story-modal');
            },
            setYTThumb(vidUrl) {
                var ytThumbLink, ytId;
                var videoUrl = vidUrl;
                var lastSlash = videoUrl.lastIndexOf("/");
                var endOfId = videoUrl.lastIndexOf("?");
                // handle different youtube shorts formats
                //.../43ehifuhsd OR
                //.../4hfuqewhf?uhufhweu
                // console.log(endOfId);
                if (endOfId === -1) {
                    ytId = videoUrl.slice(lastSlash);
                } else {
                    ytId = videoUrl.slice(lastSlash, endOfId);
                }

                // ytId = videoUrl.slice(lastSlash, endOfId);
                ytThumbLink = 'https://img.youtube.com/vi'+ytId+'/0.jpg'

                return ytThumbLink;
                
            },
            getFeaturedStories(apiToken) {
                return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/Article/GetAllByCategory/FeaturedVideo', {
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    var results = json.data;
                    this.featuredStories = results.filter((story) => story.teaser === 'Winner Stories Featured');
                    // console.log("Featured Winner Stories");
                    // console.log(this.featuredStories);
                })
            },
            interjectInfographics() {
                let items = this.items;
                let infographics = this.infographics;
                // var newArray = [...infographics, ...items];
                items.splice(0, 0, infographics[0]);
                items.splice(5, 0, infographics[1]);
                items.splice(10, 0, infographics[2]);
                items.splice(15, 0, infographics[3]);
                items.splice(20, 0, infographics[4]);
                // console.log("After splicing");
                this.items = items;
                // console.log(items);
                // console.log("Infographics");
                // console.log(infographics);
                // console.log("Items");
                // console.log(items);
                // this.items = newArray;
                this.isLoading = false;
            }
        },
        computed: {
            itemsLoaded() {
                // console.log(this.items.length)
                return this.items.slice(0, this.maxPerPage);
            }
        }
    };
</script>

<style lang="scss">
.vm--container{
    overflow: auto;

}
.vm--overlay{
    background-color: rgba(98, 91, 196, 0.95) !important;
    box-shadow: inset 0px 0 200px rgba(20,20,20,.5);
}
.load-more-btn{
    margin-top: 30px;
}

.featuredStories {
    margin: 0.4em 0;
}

.featuredStoriesWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media only screen and (min-width: 641px) {
        flex-direction: row;
    }
}

.featuredStoryBlock:hover {
    cursor: pointer;
}
</style>