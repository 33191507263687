<!-- ~/Views/Games/Pick3/Pick3-WinningNumbers.vue -->

<template>
    <div class="winningNumbersWrap">
        <!-- logo -->
        <a href="/games/draw-games/pick-3">
            <img v-bind:src="gameLogo" v-bind:alt="gameName" class="winNumGameLogo" />
        </a>
        <WinningNumbers :numbers="drawNumbers" :date="date" v-bind:key="drawNumbers.id"></WinningNumbers>
        
        <a href="/winning-numbers/check-your-numbers#tab5" class="button">Past Draws</a>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import WinningNumbers from '../../../ViewComponents/WinningNumbers/WinningNumbers.vue';

    export default {
        components: {
            WinningNumbers
        },
        props: [
            'gamesData'
        ],
        data: function() {
            return {
                games: this.gamesData,
                drawNumbers: [],
                gameLogo: '',
                gameName: '',
                errorMessage: 'Numbers currently not available. Please try again later.',
                date: ''
            }
        },
        mounted: function() {
            // this.getToken();
            // console.log(this.gamesData);
            this.setGames();
        },
        methods: {
            getToken() {
                // then hit API with token
                // this.getAPItoken()
                // .then((token) => {
                //     this.checkLocalNumbers(token)
                //     .then(() => {
                //         this.games = this.getStorageNumbers();
                //         // console.log(this.games);
                //         for (let i = 0; i < this.games.length; i++) {
                //             if (this.games[i].name === "Pick 3") {
                //                 let mega = this.games[i];

                //                 this.gameLogo = mega.logo;
                //                 this.gameName = mega.name;
                //                 this.drawDate = mega.drawDate;
                //                 this.drawNumbers = mega.numbers;
                //                 this.nextJackpot = 244; // megaresults.draws[0].nextPrizePool;
                //                 this.cashOption = 184.7; // megaresults.draws[0].nextPDCV;
                //             }
                //         }
                //     })
                    // console.log('Pick3 token: '+token);
                    // this.fetchData(token);
                    // this.fetchLogo(token);
                // });
            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/Pick3/GetLatestDraws", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var drawNumbers = json.data;

                    // var pick3results = json.data[0].numbers;
                    // console.log('pick3results: '+pick3results);
                    this.drawNumbers = drawNumbers;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
            },
            fetchLogo(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/Pick3/GetGameInformation", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var gameData = json.data;
                    // console.log(gameData)
                    this.gameLogo = gameData.gameLogoURL;
                    this.gameName = gameData.name;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });

            },
            ...mapActions ({
                checkLocalNumbers: 'getNumbers/checkLocalNumbers',
                getOLCDrawGames: 'getNumbers/getOLCDrawGames'
            }),
            ...mapGetters ({
                getStorageNumbers: 'getNumbers/getStorageNumbers'
            }),
            setGames() {
                this.games = this.gamesData;
                
                for (let i = 0; i < this.games.length; i++) {
                    if (this.games[i].StringIdentifier === "Pick3") {
                        this.gameName = this.games[i].Name;
                        this.date = this.games[i].Draws[0].DrawDate;
                        this.gameLogo = this.games[i].GameLogoURL;
                        this.drawNumbers = this.games[i].Draws;
                    }
                }
            }
        },
        watch: {
            gamesData() {
                this.setGames();
            }
        }
    };
</script>


<style lang="scss" scoped>
    /* style the unique aspects here */
    .winNumDate {
        display: inline-block;
        margin-right: $global-margin/2;
    }
</style>