<template>
    <div class="recent_numbers_wrap recent_numbers_mobile" v-if="loading === false">
        <!-- EXAMPLE ORBIT -->
        <div class="orbit orbit-wnMobile" id="orbit-master" role="region" data-orbit
            data-options="animInFromLeft:fade-in; animInFromRight:fade-in; animOutToLeft:fade-out; animOutToRight:fade-out;">
            <div class="orbit-wrapper">
                <div class="orbit-controls">
                    <input id="showBullets" name="showBullets" type="hidden" value="True">
                </div>
                <ul class="orbit-container redemption_ctas_slider">
                    <li v-for="game in games"
                        :class="game.StringIdentifier + ' item_alt winningNumbersItem cf orbit-slide is-active'"
                        :data-slide="game.index">
                        <figure class="orbit-figure">
                            <div class="winningNumbers-orbitHeader">
                                <a class="winningNumbersLogo" :href="'/games/draw-games/' + game.NodeAliasPath">
                                    {{ game.Name }}
                                </a>
                                <span class="date">
                                    {{ new Date(game.Draws[0].DrawDate) | moment("MM/DD/YYYY") }}
                                </span>
                            </div>
                            <div class="winningNumbersContent">

                                <div class="wrapperTimeNumbers"
                                    v-if="game.StringIdentifier === 'Pick3' || game.StringIdentifier === 'Pick4' || game.StringIdentifier === 'Pick5'">
                                    <span v-if="pick3 && game.StringIdentifier === 'Pick3'" class="time">MIDDAY</span>
                                    <span v-if="pick4 && game.StringIdentifier === 'Pick4'" class="time">MIDDAY</span>
                                    <span v-if="pick5 && game.StringIdentifier === 'Pick5'" class="time ">MIDDAY</span>
                                    <ul v-if="pick3 && game.StringIdentifier === 'Pick3'" class="balls">
                                        <li v-for="number in game.Draws[1].Numbers.slice(0, 5)" :key="number.Position">
                                            {{ number.Value }}</li>
                                    </ul>

                                    <ul v-if="pick4 && game.StringIdentifier === 'Pick4'" class="balls">
                                        <li v-for="number in game.Draws[1].Numbers.slice(0, 5)" :key="number.Position">
                                            {{ number.Value }}</li>
                                    </ul>

                                    <ul v-if="pick5 && game.StringIdentifier === 'Pick5'" class="balls">
                                        <li v-for="number in game.Draws[1].Numbers.slice(0, 5)" :key="number.Position">
                                            {{ number.Value }}</li>
                                    </ul>

                                </div>

                                <div class="wrapperTimeNumbers"
                                    v-if="game.StringIdentifier === 'Pick3' || game.StringIdentifier === 'Pick4' || game.StringIdentifier === 'Pick5'">
                                    <span v-if="game.StringIdentifier === 'Pick3' && pick3" class="time">EVENING</span>
                                    <span v-if="game.StringIdentifier === 'Pick4' && pick4" class="time">EVENING</span>
                                    <span v-if="game.StringIdentifier === 'Pick5' && pick5" class="time">EVENING</span>

                                    <span v-if="game.StringIdentifier === 'Pick3' && !pick3" class="time">MIDDAY</span>
                                    <span v-if="game.StringIdentifier === 'Pick4' && !pick4" class="time">MIDDAY</span>
                                    <span v-if="game.StringIdentifier === 'Pick5' && !pick5" class="time">MIDDAY</span>
                                    <ul class="balls">
                                        <li v-for="number in game.Draws[0].Numbers.slice(0, 5)" :key="number.Position">
                                            {{ number.Value }}</li>
                                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'megamil'"
                                            class="yellow">
                                            {{ game.Draws[0].Numbers[5].Value }}
                                        </li>
                                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'pball'"
                                            class="red">
                                            {{ game.Draws[0].Numbers[5].Value }}
                                        </li>
                                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'luckylife'"
                                            class="yellow">
                                            {{ game.Draws[0].Numbers[5].Value }}
                                        </li>
                                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'ClassicLotto'">
                                            {{ game.Draws[0].Numbers[5].Value }}
                                        </li>
                                        <li v-if="game.Draws[0].Numbers.length > 6" class="x" :style="{display: MegiplierStatus==='false' && game.StringIdentifier === 'megamil' ? 'none' : 'inline-block'}">
                                            {{ game.Draws[0].Numbers[6].Value }}
                                        </li>
                                    </ul>
                                </div>
                                
                                <div class="wrapperTimeNumbers" v-if="game.StringIdentifier != 'Pick3' && game.StringIdentifier != 'Pick4' && game.StringIdentifier != 'Pick5'">
                                    <ul class="balls">
                                        <li v-for="number in game.Draws[0].Numbers.slice(0, 5)" :key="number.Position">
                                            {{ number.Value }}</li>
                                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'megamil'"
                                            class="yellow">
                                            {{ game.Draws[0].Numbers[5].Value }}
                                        </li>
                                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'pball'"
                                            class="red">
                                            {{ game.Draws[0].Numbers[5].Value }}
                                        </li>
                                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'luckylife'"
                                            class="yellow">
                                            {{ game.Draws[0].Numbers[5].Value }}
                                        </li>
                                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'ClassicLotto'">
                                            {{ game.Draws[0].Numbers[5].Value }}
                                        </li>
                                        <li v-if="game.Draws[0].Numbers.length > 6" class="x">
                                            {{ game.Draws[0].Numbers[6].Value }}
                                        </li>
                                    </ul>
                                </div>

                                <span v-if="game.StringIdentifier === 'ClassicLotto'" class="kickerWin">
                                    <span class="wnLbl">KICKER: </span>
                                    <span class="wnVal" v-if="game.Draws[0].ExtendedNumbers.length > 0">
                                        {{ game.Draws[0].ExtendedNumbers[0].Value }}{{
                                            game.Draws[0].ExtendedNumbers[1].Value }}{{ game.Draws[0].ExtendedNumbers[2].Value
    }}{{ game.Draws[0].ExtendedNumbers[3].Value }}{{
    game.Draws[0].ExtendedNumbers[4].Value }}{{ game.Draws[0].ExtendedNumbers[5].Value
    }}
                                    </span>
                                </span>
                            </div>
                        </figure>
                    </li>
                </ul>
                <nav class="orbit-bullets">
                    <button v-for="(game, index) in games" class="orbit-buttons" :key="index" :id="index"
                        :data-slide="game.index" @click="selectOrbit"></button>
                </nav>
            </div>
        </div>
        <!-- ACTUAL WINNING NUMBERS -->
        <!-- <ul id="recent_numbers_list" class="winningNumbers cf winNumSlider">
            <li v-for="game in games" :class="game.identifier + ' item_alt winningNumbersItem cf'" :key="game.identifier">
                <a class="winningNumbersLogo" href="/Games/DrawGames/MegaMillions.aspx">
                    {{game.name}}
                </a>
                <div class="winningNumbersContent">
                    <span class="date">
                        {{new Date(game.draws[0].drawDate) | moment("MM/DD/YYYY")}}
                    </span>
                    <div v-if="game.identifier === 'pick3' || game.identifier === 'Pick4' || game.identifier === 'Pick5'">
                        <ul v-if="pick3 && game.identifier === 'pick3'" class="balls">
                            <li v-for="number in game.draws[1].numbers.slice(0, 5)" :key="number.position">{{number.value}}</li>
                        </ul>

                        <ul v-if="pick4 && game.identifier === 'Pick4'" class="balls">
                            <li v-for="number in game.draws[1].numbers.slice(0, 5)" :key="number.position">{{number.value}}</li>
                        </ul>

                        <ul v-if="pick5 && game.identifier === 'Pick5'" class="balls">
                            <li v-for="number in game.draws[1].numbers.slice(0, 5)" :key="number.position">{{number.value}}</li>
                        </ul>

                        <span v-if="pick3 && game.identifier === 'pick3'" class="wnVal">MIDDAY</span>
                        <span v-if="pick4 && game.identifier === 'Pick4'" class="wnVal">MIDDAY</span>
                        <span v-if="pick5 && game.identifier === 'Pick5'" class="wnVal">MIDDAY</span>
                    </div>

                    <ul class="balls">
                        <li v-for="number in game.draws[0].numbers.slice(0, 5)" :key="number.position">{{number.value}}</li>
                        <li v-if="game.draws[0].numbers.length > 5 && game.identifier === 'megamil'" class="yellow">
                            {{game.draws[0].numbers[5].value}}
                        </li>
                        <li v-if="game.draws[0].numbers.length > 5 && game.identifier === 'pball'" class="yellow">
                            {{game.draws[0].numbers[5].value}}
                        </li>
                        <li v-if="game.draws[0].numbers.length > 5 && game.identifier === 'luckylife'" class="red">
                            {{game.draws[0].numbers[5].value}}
                        </li>
                        <li v-if="game.draws[0].numbers.length > 5 && game.identifier === 'ClassicLotto'">
                            {{game.draws[0].numbers[5].value}}
                        </li>
                        <li v-if="game.draws[0].numbers.length > 6" class="x">
                            {{game.draws[0].numbers[6].value}}
                        </li>
                    </ul>
                    
                    <span v-if="game.identifier === 'ClassicLotto'">
                            <span class="wnLbl">KICKER: Kicker Missing from API</span>
                    </span>

                    <span v-if="game.identifier === 'pick3' || game.identifier === 'Pick4' || game.identifier === 'Pick5'" class="kickerWin">
                        <span v-if="game.identifier === 'pick3' && pick3" class="wnVal">EVENING</span>
                        <span v-if="game.identifier === 'Pick4' && pick4" class="wnVal">EVENING</span>
                        <span v-if="game.identifier === 'Pick5' && pick5" class="wnVal">EVENING</span>

                        <span v-if="game.identifier === 'pick3' && !pick3" class="wnVal">MIDDAY</span>
                        <span v-if="game.identifier === 'Pick4' && !pick4" class="wnVal">MIDDAY</span>
                        <span v-if="game.identifier === 'Pick5' && !pick5" class="wnVal">MIDDAY</span>
                    </span>
                </div>
                
            </li>
        </ul> -->
    </div>
</template>
<script>
import $ from 'jquery';
import { mapActions, mapGetters } from 'vuex';
import Foundation from "@/foundation-sites/js/foundation.js";

export default {
    props: [
        'close'
    ],
    data: function () {
        return {
            token: '',
            games: [],
            pick3: false,
            pick4: false,
            pick5: false,
            loading: true,
            MegiplierStatus: this.getMegiplierStatus(),
        }
    },
    mounted: function () {
        this.getToken();
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                })
                .then(() => {
                    this.getNumbers();
                });
        },
        getNumbers() {
            // let games = JSON.parse(localStorage.getItem('olcDrawNumbers'));
            let games = document.getElementById('cmsNumbers').value;
            games = JSON.parse(games);

            if (games == undefined || games == "" || games == null) {
                // this.checkLocalNumbers(this.token)
                // .then(() => {
                //     setTimeout(() => {
                // games = JSON.parse(localStorage.getItem('olcDrawNumbers'));
                games = document.getElementById('cmsNumbers').value;
                games = JSON.parse(games);
                // this.games = games;
                this.setNumbers(games);
                // }, 10000);
                // })
            } else {
                // this.games = games;
                this.setNumbers(games);
            }
        },
        setNumbers(g) {
            let games = g;

            for (let i = 0; i < games.length; i++) {
                // console.log(games[i])
                let game = games[i],
                    draw1 = game.Draws[0],
                    draw2 = game.Draws[1];

                    if (game.StringIdentifier === 'megamil') {
                        game.sortOrder = 1;
                    }

                    if (game.StringIdentifier === 'pball') {
                        game.sortOrder = 2;
                    }

                    if (game.StringIdentifier === 'luckylife') {
                        game.sortOrder = 3;
                    }

                    if (game.StringIdentifier === 'rollingcash5') {
                        game.sortOrder = 4;
                    }

                    if (game.StringIdentifier === 'ClassicLotto') {
                        game.sortOrder = 5;
                    } 

                if (game.StringIdentifier === 'Pick3') {
                    let draw1Date = new Date(draw1.DrawDate),
                        draw2Date = new Date(draw2.DrawDate);

                        game.sortOrder = 6;


                    if (draw1Date.getYear() === draw2Date.getYear() && draw1Date.getDay() === draw2Date.getDay() && draw1Date.getMonth() === draw2Date.getMonth()) {
                        this.pick3 = true
                    } else {
                        this.pick3 = false;
                    }
                }

                if (game.StringIdentifier === 'Pick4') {
                    let draw1Date = new Date(draw1.DrawDate),
                        draw2Date = new Date(draw2.DrawDate);

                        game.sortOrder = 7;

                    if (draw1Date.getYear() === draw2Date.getYear() && draw1Date.getDay() === draw2Date.getDay() && draw1Date.getMonth() === draw2Date.getMonth()) {
                        this.pick4 = true
                    } else {
                        this.pick4 = false;
                    }
                }

                if (game.StringIdentifier === 'Pick5') {
                    let draw1Date = new Date(draw1.DrawDate),
                        draw2Date = new Date(draw2.DrawDate);

                        game.sortOrder = 8;

                    if (draw1Date.getYear() === draw2Date.getYear() && draw1Date.getDay() === draw2Date.getDay() && draw1Date.getMonth() === draw2Date.getMonth()) {
                        this.pick5 = true
                    } else {
                        this.pick5 = false;
                    }
                }
            }

            games.sort((a, b) => {
                return a.sortOrder - b.sortOrder;
            })

            this.games = games;

            this.loading = false;
            setTimeout(() => {
                $(document).foundation();
                new Foundation.Orbit($('.orbit-wnMobile'))
            }, 1400);
        },
        selectOrbit(e) {
            let buttons = $('#winning-numbers-menu-mobile>.recent_numbers_wrap>.orbit>.orbit-wrapper>.orbit-bullets>.orbit-buttons');
            buttons.removeClass('is-active');
            // console.log(buttons)
            let slides = $('#winning-numbers-menu-mobile>.recent_numbers_wrap>.orbit>.orbit-wrapper>.orbit-container>.orbit-slide');
            slides.css('display', 'none');
            e.target.classList.add('is-active');
            let orbit = slides[e.target.id];
            orbit.style.display = 'block';
        },
        ...mapActions({
            checkLocalNumbers: 'getNumbers/getOLCDrawGames'
        }),
        ...mapGetters({
            getGames: 'getNumbers/getGames',
            getStorageNumbers: 'getNumbers/getStorageNumbers'
        })
    }
};
</script>
<style lang="scss" scoped>
.recent_numbers_mobile {
    z-index: 10;

    #orbit-master {
        margin-top: 0px;
    }

    .balls {
        display: flex;
        position: unset;
        margin-left: 0;

        li {
            border-radius: 50%;
            border: 1px solid #ccc;
            background-color: #fff;
            padding: 4px 6px;
            font-size: 1.2rem;
            margin: 0px 2px;
            color: #000;
            font-weight: 600;
            line-height: 1.5;
            min-width: 2.2em;
            text-align: center;

            &.noRound {
                border: none;
            }
        }

        .yellow {
            background-color: $ballYellow;
            color: $gray-darkest;
        }

        .red {
            background-color: $ol_red;
            color: #fff;
        }

        .green {
            background-color: $green;
            color: #fff;
        }

        .x {
            color: #fff;
            border: none;
            background-color: transparent;

            &:before {
                content: "x ";
                font-size: 90%;
            }
        }
    }

    .winningNumbersContent {
        color: #fff;
    }

    .winningNumbersItem {
        position: relative;
    }

    .orbit {
        margin-top: 40px;

        .orbit-container {
            display: block !important;
            height: 160px !important;
            width: calc(100% - 20px);
            padding: 10px;
            background-color: #6762b7;
        }

        .winningNumbers-orbitHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            // padding-right: 20px;
        }

        @media only screen and (min-width: 680px) {
            display: none;
        }

        .orbit-bullets {
            bottom: 5px;
        }
    }
}
</style>