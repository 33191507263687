<template>
    <div class="myApplicationsWrap">

        <div class="moduleContent cf welcomeIntro" v-if="!isChainSalesRep">
            <span v-if="isUserLogged" v-html="pageContent.welcomeAuthed"></span>
            <span v-else v-html="pageContent.welcomePublic"></span>
        </div>

        <hollow-dots-spinner :animation-duration="1000"
                             :dot-size="15"
                             :dots-num="3"
                             color="#0FD8C9"
                             v-if="isLoading" />
        
        <div class="moduleContent appGridWrapper" v-if="!isLoading && isUserLogged">
            <h3>My Applications</h3>
            
            <div v-if="errorMessageShow" class="ErrorLabel alert-error">There was an error processing your request.</div>
            <div v-if="showChains && !isChainSalesRep && !isChainManager" class="chainAppFormStart">
                <div class="formRow cf chainMasterSelect">
                    <label for="masterChainList">Masterchain App:</label>
                    <select v-model="masterChain" id="masterChainList" class="chainMasterListSelect" :disabled="!isPartOfExistingChain">
                        <option value="null" v-if="isPartOfExistingChain">--</option>
                        <option v-for="(master, index) in masterChainList" :key="index" :value="master.chainAppGuid">{{ master.chainName }}</option>
                    </select>
                    <div v-if="masterChainError" class="error">Please select a master chain</div>
                </div>
                <div class="formRow checkboxRow cf">
                    <input type="checkbox" id="isPartOfExistingChain" v-model="isPartOfExistingChain" @change="loadMasterChains">
                    <label for="isPartOfExistingChain">This is part of an existing chain</label>
                </div>
                <div class="formRow radio-list-horizontal cf" v-if="!isPartOfExistingChain">
                    <label class="radio"><input type="radio" name="chainType" v-model="chainType" value="0" /> F-Code </label>
                    <label class="radio"><input type="radio" name="chainType" v-model="chainType" value="1" /> A-Code </label>
                    <div v-if="chainTypeError" class="error">Please select a chain type</div>
                </div>
                <br>
            </div>
            <form id="myAppsSearch" class="eAppFilter">
                <label for="query" id="queryLabel" class="filterLbl">Search by Business Name</label>
                <input name="query" placeholder="Business Name" ref="searchInput" @keydown.enter="handleSearch" class="filterInput">
                <button ref="searchButton" @click="handleSearch" class="btn--filter button btn-primary">Search Applications</button>
                <button ref="clearSearchButton" @click="clearSearch" v-if="showClearSearch" class="btn--filterClear button btn-primary">Clear Search</button>
            </form>
            <button v-if="isPartOfExistingChain && showChains && !isChainSalesRep && !isChainManager" id="btnNewChain" @click="createNewChain" class="button btnStartNewApp">Create New Chain Location Application</button>
            <button v-if="!isPartOfExistingChain && showChains && !isChainManager" @click="createNewChain" id="btnNewApp" class="button">Create New Chain Application</button>
            <button v-if="!showChains" @click="createNewApplication" id="btnNewApp" class="button">Create New Application</button>
            
            <ApplicationGrid :gridData="gridData"
                             :headers="gridHeaders"
                             :columns="gridColumns"
                             :sortOrder="sortOrder"
                             :sortColumn="sortBy"
                             @sortListBy="sortListBy"
                             @changePage="updatePage"
                             :currentPage="currentPageReactive"
                             :totalPages="totalPageReactive"
                             :itemsPerPage="pageSize"
                             :showChains="showChains"
                             ref="appGrid">
            </ApplicationGrid>

        </div>
    </div>
</template>


<script>
    import $ from 'jquery';
    import ApplicationGrid from '../../../Views/Eapplication/dashboard/application-grid.vue';
    import { HollowDotsSpinner } from 'epic-spinners';
    // import SignInRegister from '../../SignInRegister/SignInRegister.vue';
    export default {
        components: {
            HollowDotsSpinner,
            ApplicationGrid,
            // SignInRegister
        },
        data() {
            return {
                searchQuery: '',
                showClearSearch: false,
                gridHeaders: ['App #','Business Name', 'Location', 'Status', 'Created On'],
                gridColumns: ['applicationNumber','businessName', 'location', 'applicationStatus', 'createdOn'],
                gridData: [],
                placeholder: 'Business Name',
                isUserLogged: false,
                isLoading: false,
                API_URL: this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/dashboard/GetApplicationListForRetailer`,
                userId: 0,
                totalPageCount: 0,
                currentPage: 1,
                pageSize: 25,
                token: '',
                sortBy: '',
                sortOrder: '',
                pageContent: {},
                contentKey: 'eApp-Landing-Page',
                welcomePublic: '',
                welcomeAuthed: '',
                showChains: false,
                isChainUser: false,
                isChainSalesRep: false,
                isChainManager: false,
                chainRoles: ['eApp-ChainSalesRep', 'eApp-RegionalManager', 'eApp-ChainApplicant', 'eAppApplicant', 'eApp-ChainManager'],
                masterChain: '',
                masterChainList: [],
                isPartOfExistingChain: false,
                chainType: '',
                masterChainError: false,
                chainTypeError: false,
                errorMessageShow: false,
                district: ''
            }
        },
        mounted: function () {
            this.isUserLogged = this.isUserAuthed();  
            this.init();
        },
        methods: {
            init() {
                this.showChains = document.getElementById('isChains').value;
                // console.log(this.showChains);
                if (this.showChains == 'True') {
                    this.showChains = true;
                    this.chainRoles.forEach(element => {
                        // must have at least one chain role
                        if (this.isUserInRole(element)) {
                            this.isChainUser = true;
                        }
                    });
                    // Chain Sales Rep uses this as a dashboard
                    this.isChainSalesRep = this.isUserInRole('eApp-ChainSalesRep') || this.isUserInRole('eApp-RegionalManager');
                    this.isChainManager = this.isUserInRole('eApp-ChainManager');
                    // Set the columns
                    this.gridHeaders = ['Business Name', 'Location', 'Application Date', 'Status'];
                    this.gridColumns = ['businessName', 'location', 'createdOn', 'applicationStatus'];
                } else {
                    this.showChains = false;
                }
                this.getToken();
            },
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getContent();
                    if(this.isUserLogged) {
                        this.getApplications();
                    }
                });
            },
            getContent() {
                let cookieContent = JSON.parse(localStorage.getItem('eapp_lp_content'));
                if (cookieContent !== undefined && cookieContent !== "" && cookieContent !== null) {
                    this.pageContent = JSON.parse(cookieContent);
                    this.setTheStage();
                } else {
                    this.fetchContent();
                }
            },
            fetchContent() {
                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+'/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey='+this.contentKey, {
                    headers: {
                        'Authorization': 'Bearer '+this.token
                    }
                })
                .then(response => response.json())
                .then(json => {
                    let element = json.data[0].centralContent;
                    localStorage.setItem('eapp_lp_content', JSON.stringify(element)); 
                    this.pageContent = JSON.parse(element);
                    this.setTheStage();
                    
                })
            },
            setTheStage() {
                this.welcomePublic = this.pageContent.welcomePublic;
                this.welcomeAuthed = this.pageContent.welcomeAuthed;
                // re-set jQuery so link works.
                $("#globalLogin, .btn_popupLogIn").on('click', function (event) {
                    event.preventDefault();
                    $("#globalLogInModal").show();
                    $("#globalLoginBlocker").show();
                })
            },
            getApplications(){
                let uId = this.getLoggedInUserId();
                if(uId){
                    this.userId = uId;
                    this.sortBy = "BusinessName";
                    this.sortOrder = "asc";
                    this.tryGetFromQueryString().then(() => {
                        this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder);
                    });
                }
            },             
            async createNewChainApplication(){                
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        isMasterChain: this.isMasterChain,
                        isPartOfChain: this.isPartOfExistingChain,
                        masterChainGuid: this.masterChain,
                        isFranchise: this.chainType == 1,
                        isCorporate: this.chainType == 0
                    })
                };
                let newAppGuid = await (await fetch(this.CREATE_NEW_CHAIN_APP_API_URL, appsRequestOptions)).json();
                // TO DO: Add a response check and handle any errors.
                let currentDomain = window.location.origin
                window.location = currentDomain + `/eapplications/eapplication?id=` + newAppGuid + '&step=business-1';
            },        
            async createNewApplication() {
                const appsRequestOptions = {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    }
                };
                let newAppGuid = await (await fetch(this.CREATE_NEW_APP_API_URL, appsRequestOptions)).json();
                let currentDomain = window.location.origin
                window.location = currentDomain + '/eapplications/eapplication?id=' + newAppGuid + '&step=business-1';
            },        
            async fetchData(token, _pageNumber, _pageSize, _businessName, _sortBy, _sortOrder) {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        pageNumber: _pageNumber,
                        pageSize: _pageSize,
                        businessName: _businessName,
                        sortBy: _sortBy,
                        sortOrder: _sortOrder
                    })
                };
                let applicationsRequest = await (await fetch(this.GET_APPS_API_URL, appsRequestOptions)).json();
                // console.log(applicationsRequest);
                this.gridData = applicationsRequest.results;
                this.totalPageCount = applicationsRequest.totalPageCount;
                this.isLoading = false;
            },
            tryGetFromQueryString() {
                
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('page')) {
                    this.currentPage = Number(urlParams.get('page'));
                }
                if (urlParams.has('businessName')) {
                    this.searchQuery = urlParams.get('businessName');
                }
                if (urlParams.has('sortBy')) {
                    this.sortBy = urlParams.get('sortBy');
                }
                if (urlParams.has('sortOrder')) {
                    this.sortOrder = urlParams.get('sortOrder');
                }
                return Promise.resolve();
            },
            sortListBy({ key, order }) {
                this.sortBy = key;
                if (order === 'asc') {
                    this.sortOrder = 'desc';
                }
                else {
                    this.sortOrder = 'asc';
                }
                this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, order);
            },
            updatePage(pageNumber) {
                this.currentPage = pageNumber;
                this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder);
            },
            clearSearch() {
                this.$refs.searchInput.value = '';
                this.searchQuery = '';
                this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder);
                this.showClearSearch = false;
            },
            handleSearch() {
                let value = this.$refs.searchInput.value;
                this.searchQuery = value;
                this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder);
                this.showClearSearch = true;
            },
            async createNewChain() {
                // check if part of existing chain
                this.masterChainError = false;

                if (this.isPartOfExistingChain) {
                    // get the selected master & set to: this.masterChain
                    // if no selection then throw error
                    if (this.masterChain == '' || this.masterChain == 'null') {
                        this.masterChainError = true;
                    }
                }

                // check for selected chainType
                if (this.chainType == '') {
                    // throw error
                    this.chainTypeError = true;
                } else {
                    this.chainTypeError = false;
                }

                if (!this.chainTypeError && !this.masterChainError) {
                    await this.createNewChainApplication();
                }
            },
            async loadMasterChains() {
                // we should also store the list in session and only fetch from the API the first time, 
                //in case someone gets clicky with it.
                if (!this.masterChainList.length > 0) {
                    if (this.isPartOfExistingChain) {
                        // populate the master chain menu list
                        this.isLoading = true;
                        const appsRequestOptions = {
                            method: "POST",
                            headers: {
                                'Authorization': 'Bearer ' + this.token,
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                businessName: '',
                                sortBy: 'businessname',
                                sortOrder: 'asc',
                            })
                        };
                        let response = await (await fetch(this.GET_APP_RETAILER_LIST_API_URL, appsRequestOptions)).json();
                        if (response != null && response.statusCode == 200) {
                            this.masterChainList = response.data.masterApplicationList;
                            this.isLoading = false;
                            this.chainType = null;
                        }
                        else {
                            this.errorMessageShow = true;
                            this.isLoading = false;
                        }
                       }
                
                }
            }
        },
        computed: {
            currentPageReactive() {
                return this.currentPage;
            },
            totalPageReactive() {
                return this.totalPageCount;
            },
            GET_APPS_API_URL() {
                let url = '';
                if (this.showChains) {
                    url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/dashboard/GetChainApplicationListForRetailer?userId=' + this.userId;
                } else {
                    url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/dashboard/GetApplicationListForRetailer?userId=' + this.userId;
                }
                return url;
            },
            CREATE_NEW_APP_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/RegularApplication/NewApplication/' + this.userId;
            },
            CREATE_NEW_CHAIN_APP_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/ChainApplication/NewApplication/' + this.userId;
            },
            GET_APP_RETAILER_LIST_API_URL() {
                let url = '';
                url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/ApplicationAdministration/MasterApplicationACodeList/` + this.userId;
                return url;
            },
            isMasterChain() {
                return !this.isPartOfExistingChain;
            }
        }
    }
</script>