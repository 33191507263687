<template>
    <div class="wrapper-prizesRemaining">
        <div class="dcf_submitPrizesRemainingForm olcForm dcf_forms">
            <form data-abide novalidate data-validate-on-blur="true" id="PrizesRemainingForm">
                <fieldset class="grid-x">
                    <div class="columns medium-12 large-3 cell">
                        <input type="number" class="getValue" placeholder="Game #" pattern="[0-9]" minlength="3"
                            maxlength="3" @input="gameIDInput" />
                        <button class="button btn_dcfConfirmRetailerForm" @click="goSubmit" disabled="true">GO</button>
                        <div class="form-error error errorInputID">Please, enter exactly three numbers.</div>
                    </div>
                    <div class="dividerWord columns medium-12 large-1 cell">
                        <span>or</span>
                    </div>
                    <div class="columns medium-12 large-5 cell">
                        <select class="getValue" @change="gameIDInput">
                            <option selected="selected" value="0">-- Select A Game --</option>
                            <option v-for="item in PrizesRemainingList" :value="item.gameCode" :key="item.gameCode">${{
                                item.ticketPrice
                            }} – {{ item.gameName }} ({{ item.gameCode }})</option>
                        </select>
                        <button class="button btn_dcfConfirmRetailerForm" @click="goSubmit" disabled="true">GO</button>
                    </div>
                    <div class="dividerWord columns medium-12 large-1 cell">
                        <span>or</span>
                    </div>
                    <div class="columns medium-12 large-2 cell">
                        <button class="button btn_dcfConfirmRetailerForm" @click="allSubmit">SEE ALL</button>
                    </div>
                </fieldset>
            </form>
        </div>

        <div class="grid-x grid-margin-x wrapper-list">

            <div class="columns medium-12 large-6 cell list-res" v-for="item in listToShow" :value="item.gameCode"
                :key="item.gameCode">
                <span class="title">({{ item.gameCode }}) {{ item.gameName }} ${{ item.ticketPrice }}</span>
                <div class="grid-x">
                    <div class="columns small-6 medium-6 large-6 cell"><span class="subTitle">Prizes</span></div>
                    <div class="columns small-6 medium-6 large-6 cell"><span class="subTitle">Remaining</span></div>
                </div>
                <div class="grid-x" v-for="list in item.prizeRemainingValues">
                    <div class="columns small-6 medium-6 large-6 cell">
                        <!-- <span v-if="(list.prizeValue != 0 && list.prizeValue < 1000)">${{ list.prizeValue }}</span>
                        <span v-if="(list.prizeValue != 0 && list.prizeValue > 999)">${{ list.prizeValue.toLocaleString() }}</span>
                        <span v-else-if="(list.prizeValue == 0)">{{ list.description }}</span> -->
                        <span>{{ list.description }}</span>
                    </div>
                    <div class="columns small-6 medium-6 large-6 cell" v-if="list.prizesLeft < 1000"><span>{{ list.prizesLeft }}</span></div>
                    <div class="columns small-6 medium-6 large-6 cell" v-if="list.prizesLeft > 999"><span>{{ list.prizesLeft.toLocaleString() }}</span></div>
                </div>
            </div>

        </div>
    </div>
</template>
  
<script>
export default {
    components: {},
    data: function () {
        return {
            PrizesRemainingList: {},
            listToShow: {}
        }
    },
    mounted: function () {
        let existingData = sessionStorage.getItem('PrizesRemainingList');
        if (existingData !== null && existingData !== undefined) {
            this.PrizesRemainingList = JSON.parse(existingData);
        } else {
            this.getToken();
        }
    },
    methods: {
        gameIDInput(e) {
            let inputVal = e.target.value;
            if (inputVal.length < 3 || inputVal.length > 3) {
                e.target.closest('.cell').querySelector("button").setAttribute('disabled', '');
            }

            if (inputVal.length === 3) {
                e.target.closest('.cell').querySelector("button").removeAttribute('disabled');
            }
        },

        goSubmit(e) {
            e.preventDefault();
            let inputVal = e.target.closest('.cell').querySelector(".getValue").value;
            // find by gameId // use parseInt() String To Number
            // let fGameId = this.PrizesRemainingList.filter(x => x.gameCode === parseInt(inputVal));
            // gameId is internal, so use gameCode instead, no need to parseInt - 5/13/24
            let fGameId = this.PrizesRemainingList.filter(x => x.gameCode === inputVal);
            // console.log(fGameId);
            this.listToShow = fGameId;
            // Reset Form
            document.querySelector("#PrizesRemainingForm").reset();
        },

        allSubmit(e) {
            e.preventDefault();
            this.listToShow = this.PrizesRemainingList;
        },

        getToken() {
            // then hit API with token
            this.getAPItoken().then((token) => {
                this.fetchData(token);
            });
        },
        fetchData(token) {
            var apiToken = token;

            return fetch(
                process.env.VUE_APP_APIPROTOCOL +
                process.env.VUE_APP_APIURL +
                "/1.0/Games/ScratchOffs/ScratchOffGame/GetFullPrizesRemainingList",
                {
                    headers: {
                        Authorization: "Bearer " + apiToken,
                    },
                }
            )
                .then((response) => response.json())
                .then((json) => {
                    var results = json.data;
                    //initially sort the data by Game Number
                    results = results.sort(function(a,b) {return parseInt(a.gameCode) - parseInt(b.gameCode) });
                    results.forEach((item) => {
                        item.prizeRemainingValues.forEach((value) => {
                            //get rid of any trailing white space on description
                            value.description =  value.description.trimEnd();
                        });
                        //sort the array of Prize Values ascending
                        item.prizeRemainingValues.sort(function(a,b) { return a.prizeValue - b.prizeValue });
                        // get rid of the trailing white space on gameCode field
                        item.gameCode = item.gameCode.trim();
                    });
                    sessionStorage.setItem('PrizesRemainingList', JSON.stringify(results));
                    this.PrizesRemainingList = JSON.parse(sessionStorage.getItem('PrizesRemainingList'));
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
        }
    },
    computed: {

    },
};
</script>
<style lang="scss" scoped>
.wrapper-prizesRemaining {
    margin-bottom: 2em;

    .dcf_submitPrizesRemainingForm {
        background-color: #f1efee;
        padding: 20px;

        .columns {
            display: flex;
            align-items: center;
            position: relative;
        }

        .dividerWord {
            justify-content: center;
            position: relative;
            height: 62px;
            margin-bottom: 0;
    border-bottom: 0;

            @include breakpoint(960px down) {
                height: 40px;
            }


            &:before {
                content: "";
                position: absolute;
                left: 50%;
                background-color: #CBCBCB;
                width: 1px;
                height: 100%;

                @include breakpoint(960px down) {
                    left: 0;
                    width: 100%;
                    height: 1px;
                }
            }

            span {
                position: relative;
                z-index: 1;
                background-color: #F1EFEE;
                bottom: 0;
                text-indent: 0;
                @include breakpoint(960px down) {
                    bottom: 1px;
                    padding: 0 10px;
                }
            }
        }

        select {
            // fix Line Selected
            line-height: 1.25;
        }

        input,
        button,
        select {
            display: inline-block;
            margin-bottom: 0;

            &.is-invalid-input {
                border-color: #cc4b37;
                background-color: #faedeb;
            }
        }

        input,
        select {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 1.6rem;
            // @media #{$medium-only} {
            //     margin-right: 0;
            // }
        }

        button {
            margin: auto;
            @include breakpoint(960px up) {
                margin: 0 auto;
            }
        }

        .errorInputID {
            position: absolute;
            margin: 0;
            bottom: -16px;
            line-height: 1.2rem;
        }
    }

    .wrapper-list {
        margin-top: 20px;
        margin-bottom: 20px;

        .list-res {
            margin-bottom: 30px;

            span {
                display: block;
                padding: 5px 0;
                font-weight: 300;
            }

            .title {
                font-weight: 700;
                text-align: center;
            }

            .subTitle {
                font-weight: 700;
                text-transform: uppercase;
            }

            .columns {
                border-top: 1px solid #B9BDC3;
                border-bottom: 1px solid #B9BDC3;

                &:nth-last-child(1n) {
                    border-bottom: none;
                }
            }
        }
    }
}
</style>  