<template>
    <div class="survey-widget">
        
        <span v-for="survey in surveysArr" :id="survey.id">
            <surveyWidget :survey-arr="survey"></surveyWidget>
        </span>
        
        

    </div>
</template>


<script>
    import surveyWidget from "./SurveyWidget-ContentComponent.vue";

    export default {
        props: {
            surveys: Array
        },
        components: {
            surveyWidget
        },
        data: function () {
            return {
                surveysArr: [],
                surveyValue: ''
            }
        },
        mounted: function () {
            // when component is used within a widget:
            var hiddenInput = document.getElementById('surveyArray');

            if (hiddenInput !== undefined && hiddenInput !== null) {
                if (hiddenInput.value !== '') {
                    this.surveyValue = hiddenInput.value;
                    let convertToArray = this.surveyValue.split(",");
                    this.surveysArr = convertToArray;
                }

            // when component is child of another component
            } else if (this.surveys.length > 0) {
                this.surveysArr = this.surveys;
            }

            // console.log(this.surveysArr);
            
        },
        methods: {
        }
    };
</script>

<style lang="scss" scoped>

</style>


<!-- I have a new challenge for you... for OLC we need to repurpose the survey wizard to handle a new request.
 
I have started setting up a widget that will accept and array of survey IDs. The idea is that you can place this widget into a page, supply a comma separated list of survey IDs and the page will serve up all of the surveys that are active .Like with the List, we need to validate if the user has already completed a survey. 
 
The purpose is to allow us to schedule several surveys to run sequentially. 
 
The primary functionality:
fetch the list of surveys by ID
check if the survey is active and published (maybe the API doesn't even return it?)
check if the user has already taken each survey
display survey(s) -->