<template>
    <div class="eAppModule cf eAppSOB" v-if="!isLoading">

        <h2>Sale of Business</h2>

        <SignInRegister v-if="!showSOBform"></SignInRegister>
        <div v-if="errorMessageShow" class="ErrorLabel alert-error">
            There was an error processing your request.
        </div>
        <div class="sobForm" v-if="showSOBform && !submitSuccess">
            <h3>Pending Sale Of Business Notice and Sales Agent Responsibilities Agreement</h3>
            <p>
                I,
                <input type="text" v-model="application.businessInformation.businessOwnerName" id="txbxCurrentOwnerName" class="inlineInput" placeholder="Current Owner Name" />,
                as owner of the sales agent location
                <input type="text" v-model="application.businessInformation.businessOwnerLocation" id="txbxCurrentOwnerLocation" class="inlineInput" placeholder="Address" Width="260" />
                Agent #
                <input type="text" v-model="application.businessInformation.businessOwnerAgentNumber" id="txbxCurrentOwnerAgentNumber" class="inlineInput" />("Business"),
                hereby notifies the Ohio Lottery Commission (the "Lottery") that I am in the process of selling my Business to
                <input type="text" v-model="application.businessInformation.businessBuyerName" id="txbxBuyerName" class="inlineInput" placeholder="Buyer Name" />, who has also applied for an Ohio Lottery Sales Agent License. My estimated date of transfer of the Business will be on
                <datepicker :disabledDates="disabledDates" v-model="application.businessInformation.businessTransferDate" placeholder="Select Date" name="txbxTransferDate" :format="customFormatter" class="inlineInput"></datepicker>
                I acknowledge and understand the following terms and conditions of this Agreement:
            </p>
            <ol>
                <li>that the Lottery is relying on the above-referenced date as the official date of the transfer of the Business in order to final settle my account with respect to my Lottery Sales Agent License; </li>
                <li>that I am solely responsible for contacting the Lottery in writing regarding any changes to the above-referenced date of transfer; </li>
                <li>that I am solely responsible for any Lottery sales activities and for making timely, prompt and accurate payments to the Lottery by electronic funds transfer for the sale of Lottery tickets; and </li>
                <li>that I am responsible for notifying the Lottery as to the "actual date of transfer" should such transfer occur prior to the above-reference date. </li>
            </ol>
            <p>IN WITNESS WHEREOF, the undersigned have executed this Agreement as of the date and year set forth below. </p>

            <br />
            <span class="error" v-if="ownerNameError">Please complete the Current Owner Name</span>
            <span class="error" v-if="ownerLocationError">Please complete the Current Owner Location</span>
            <span class="error" v-if="ownerAgentNumberError">Please complete the Current Owner AgentNumber</span>
            <span class="error" v-if="buyerNameError">Please complete the Buyer Name</span>
            <span class="error" v-if="transferDateError">Please complete the Transfer Date</span>
            <span class="error" v-if="transferDateValidError">Please enter a valid date</span>
            <span class="error" v-if="transferDateAfterTodayError">The date must be greater than today</span>

            <div class="formRow eAppSignature appSigManual">

                <label id="lblSignature" for="txbxSignature"><span class="req">*</span>Signature:</label>

                <input type="text" id="txbxSignature" v-model="signature" class="appSignature" />
            </div>
            <div class="formRow checkboxRow">
                <input type="checkbox" id="signatureCheckbox" v-model="checked" />               
                <label for="signatureCheckbox">Enter your legal name above and check the box to digitally sign this document.</label>
            </div>
            <span class="error" v-if="signatureError">Please enter your signature</span>
            <span class="error" v-if="signatureAgreeError">You must sign and check this box to proceed.</span>
            <div class="btn-actions">
                <button id="btnSellBusiness" @click="onSaveClick" class="button btnSOBsubmit">Confirm</button>
            </div>

        </div>

        <div class="sobSuccess" v-if="submitSuccess">
            <h3>Sale Of Business submitted successfully</h3>
            <p>Your sale of business was saved successfully. Thanks!</p>
        </div>


    </div>
</template>

<script>
    import Datepicker from "vue-moment-datepicker";
    import moment from 'moment';
    import SignInRegister from '/ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
            SignInRegister,
            Datepicker
        },
        data: function () {
            return {
                disabledDates: {
                    to: new Date()
                },            
                showSOBform: false,
                validRoles: ['eApp-Retailer','eAppApplicant'],
                submitSuccess: false,
                token: '',
                userId: 0,
                isLoading: true,
                errorMessageShow: false,
                applicationFound: false,
                eappId: '',
                application: {},
                ownerNameError: false,
                ownerLocationError: false,
                ownerAgentNumberError: false,
                buyerNameError: false,
                transferDateError: false,
                transferDateValidError: false,
                transferDateAfterTodayError: false,
                signature: '',
                signatureAgree: false,
                signatureError: false,
                signatureAgreeError: false,
                checked: false
            }
        },
        mounted: function () {
            
            this.init();
        },

        computed:
        {
            GET_APP_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/user/` + this.userId + `/eapplication/` + this.eappId;
            },
            SAVE_APP_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/SaveApplicationSOB/` + this.userId;
            },
        },
        methods: {
            init(){
                this.validRoles.forEach(element => {
                    if (this.isUserInRole(element)) {
                        this.showSOBform = true;
                    }
                });

                let uId = this.getLoggedInUserId();
                if (uId) {
                    this.userId = uId;
                    this.getAPItoken()
                        .then((token) => {
                            this.token = token;
                            this.tryGetFromQueryString().then(() => {
                                this.fetchData();
                            });
                        });
                }
            },
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy');

            },
            async fetchData() {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                };
                let response = await (await fetch(this.GET_APP_API_URL, appsRequestOptions)).json();

                if (response != null && response.statusCode == 200) {
                    this.application = response.data;
                    this.readOnly = this.application.applicationStatus > 1; 
                }
                else {
                    // console.log('error!');
                    this.errorMessageShow = true;
                    this.applicationFound = false;
                }

                this.isLoading = false;
            },
            tryGetFromQueryString() {

                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('a')) {
                    this.eappId = urlParams.get('a');
                }
                return Promise.resolve();

            },
            validate() {
                this.ownerNameError = false;
                this.ownerLocationError = false;
                this.ownerAgentNumberError = false;
                this.buyerNameError = false;
                this.transferDateError = false;
                this.transferDateValidError = false;
                this.transferDateAfterTodayError = false;
                this.signatureError = false;
                this.signatureAgreeError = false;

                if (this.isNullOrEmpty(this.application.businessInformation.businessOwnerName)) {
                    this.ownerNameError = true;
                    return false;
                }

                if (this.isNullOrEmpty(this.application.businessInformation.businessOwnerLocation)) {
                    this.ownerLocationError = true;
                    return false;
                }

                if (this.isNullOrEmpty(this.application.businessInformation.businessOwnerAgentNumber)) {
                    this.ownerAgentNumberError = true;
                    return false;
                }

                if (this.isNullOrEmpty(this.application.businessInformation.businessBuyerName)) {
                    this.buyerNameError = true;
                    return false;
                }

                if (this.isNullOrEmpty(this.application.businessInformation.businessTransferDate)) {
                    this.transferDateError = true;
                    return false;
                }
                let date = (new Date(this.application.businessInformation.businessTransferDate)).toLocaleDateString('en-US');
                
                if (!this.dateValidation(date)) {
                    this.transferDateValidError = true;
                    return false;
                }
                else {
                    this.application.businessInformation.businessTransferDate = date;
                }

                let today = new Date();
                let todaysDate = today.setHours(0, 0, 0, 0);

                if (this.application.businessInformation.businessTransferDate <= todaysDate) {
                    this.transferDateAfterTodayError = true;
                    return false;
                }

                if (this.isNullOrEmpty(this.signature)) {
                    this.signatureError = true;
                    return false;
                }

                if (this.checked != true) {
                    this.signatureAgreeError = true;
                    return false;
                }

                return true;
            },
            isNullOrEmpty(propValue) {
                if (propValue == '' || propValue == null || propValue == undefined) {
                    return true;
                }
                return false;
            },
            async onSaveClick() {
                //validate form
                let valid = this.validate();

                if (valid) {
                    await this.postSaveData()
                        .then(
                            (response) => {
                                if (response != null && response.statusCode == 200) {
                                    // this.updateUrl();
                                    // console.log('success');
                                    this.submitSuccess = true;
                                    this.errorMessageShow = false;
                                    this.isLoading = false;
                                }
                                else {
                                    this.isLoading = false;
                                    this.errorMessageShow = true;
                                }

                            }
                        );
                }

              
            },
            updateUrl(step) {
                let url = window.location.origin + '/eapplications';
               
                
                window.location.href = url;
            },

            async postSaveData() {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        this.application
                    )
                };

                let response = await (await fetch(this.SAVE_APP_API_URL, appsRequestOptions)).json();
                return Promise.resolve(response);
            },
        }
    };
</script>

<style lang="scss" scoped>

</style>