<template>
    <div>
        <img v-if="!success && !alreadyentered && !wrongcode" :src="isMobile ? enterSecretTextMobile : enterSecretText" alt="You've found the Secret Game" class="secretGame-enterSecret-Text">

        <img v-if="success" :src="enterSecretTextSuccessfullyEntered" alt="Hey, You Found it!" class="secretGame-enterSecret-Text-Success">
        <img v-if="alreadyentered" :src="enterSecretTextAlreadyEntered" alt="Hello Again!" class="secretGame-enterSecret-Text-Already">
        <img v-if="wrongcode" :src="isMobile ? enterSecretTextWrongCodeMobile : enterSecretTextWrongCode" alt="Nice Try! Keep Searching." class="secretGame-enterSecret-Text-Wrong">


        <div v-if="showPromotionCodeEntry && !isKYCVerified" class="promoCodeNotVerified module_callOut txtCenter">
            <h3>Looking for Promotion Code&nbsp;Entry?</h3>
            <p>We have updated security for MyLotto Rewards. You must verify your ID before using any promotional codes.</p>
            <a @click="goVerify" class="button btn-primary">Verify My Id</a>
        </div>


        <div v-if="showPromotionCodeEntry && isKYCVerified" class="promocodeentry" :class="widgetCssClass, cssClass, customClass">
            <div class="ticket-entry-form">
                <span v-html="content" class="ticket-entry-content"></span>
                <div class="input-group" v-if="!success && !alreadyentered && !wrongcode">
                    <input class="input-group-field" type="text" :placeholder="placeholder" v-model="inputValue" :disabled="disabled">
                    <div class="input-group-button">
                        <input class="button" type="submit" :value="submit"  @click="go">
                    </div>
                </div>

                <div class="error-ticket text-center" v-if="showErrMsg">
                    <p v-if="errorMessage">{{ errorMessage }}</p>
                    <p v-if="gameCodeError && validGame">{{ gameCodeError }}</p>
                    <button class="button secondary" value="Clear Fields" @click="clearInput">Try Again</button>
                </div>

                <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading"/>

                <div class="ticket-entry-msg" v-if="showScanResultMessage">
                    <div class="ticket-entered"> 
                        <div class="success-ticket text-center" >
                            <p>{{ scanResultText }}</p>
                        </div>
                    </div>
                </div>                
            </div>

        </div>

        <SignInRegister v-if="!showPromotionCodeEntry"></SignInRegister>
    </div>
    
</template>

<script>

    import { HollowDotsSpinner } from 'epic-spinners'
    import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        props: ['contentKey', 'placeholderProp', 'submitTextProp', 'customClass'],
        components: {
            HollowDotsSpinner,
            SignInRegister
        },
        data: function () {
            return {
                isMobile: true,
                enterSecretText: '/getattachment/d3489c2d-54e6-4642-86dc-db01bc95b186/2024-Holiday_Secret-Game_YouFoundText.png',
                enterSecretTextMobile:'/getattachment/203da467-3446-46da-86f4-5f4ce2334a86/2024-Holiday_Secret-Game_YouFoundText_Mobile.png',

                enterSecretTextSuccessfullyEntered:'/getattachment/490a347c-e3db-41f5-a872-3d0ba641c388/2024-Holiday_Secret-Game_SuccessfullyEntered.png',

                enterSecretTextAlreadyEntered:'/getattachment/092f642a-a4ee-4796-9de6-6ba6a9fa2800/2024-Holiday_Secret-Game_AlreadyEntered.png',

                enterSecretTextWrongCode:'/getattachment/40f6bb7a-e430-4672-ac8d-e8520c4462d5/2024-Holiday_Secret-Game_WrongCode.png',
                enterSecretTextWrongCodeMobile:'/getattachment/765612b7-fb8e-403e-b71c-ec14dcff9848/2024-Holiday_Secret-Game_WrongCode_Mobile.png',

                success: false,
                alreadyentered: false,
                wrongcode: false,

                showPromotionCodeEntry: true,
                token: '',
                placeholder: 'ENTER THE SECRET CODE',
                submit: 'GO',
                entered: 'Entered Promo Codes',
                content: '',
                cssClass: '',
                inputValue: '',
                widgetContentKey: '',
                widgetCssClass: '',
                widgetCustomContent: '',
                gameLogo: '',
                logo: false,
                gameNumber: '',
                gamePrice: '',
                gameCodeError: '',
                isLoading: false,
                showResults: false,
                showGameCodeResults: false,
                showErrMsg: false,
                isLogoExist: false,
                disabled: false,
                resultsArray: [],
                validPromo: false,
                showScanResultMessage: false,
                scanResultMessage: '',
                kycMode: process.env.VUE_APP_KYCMODE,
                isKYCVerified: false,
                user: {}
            }
        },
        mounted: function () {
            let apiToken = this.$cookies.get('apiToken');
            this.checkIfMobile();
            window.addEventListener('resize', this.checkIfMobile);

            if(apiToken.user.username == "mobilepublic@mtllc.com"){
                this.showPromotionCodeEntry = false;
                return
            }
            //check for KYC flag
            // if (apiToken.user.KYCVerified) {
            //     this.isKYCVerified = true;
            // }

            let widgetContentKey = document.getElementById('content-key'),
                widgetCssClass = document.getElementById('custom-css'),
                widgetCustomContent = document.getElementById('custom-content');
            

            if(widgetContentKey !== null){ 
                this.contentKey = widgetContentKey.value;
                this.widgetCssClass = widgetCssClass.value;
                this.widgetCustomContent = widgetCustomContent.value;
                 
            }

            
            this.fetchContentByKey();
            this.getKYCVerified();
            // console.log("KYC MODE: " + this.kycMode);

        },
        methods: {
            checkIfMobile() {
                this.isMobile = window.matchMedia("(max-width: 640px)").matches;
            },
            go(){
                this.isLoading = true;
                this.showGameCodeResults = false;
                this.getToken();
                // Add ability to launch modal from here for special promos based on a true/false prop passed from parent page.
            },
            clearInput(){
                this.inputValue = ""
                this.showErrMsg = false;
                this.wrongcode = false;
                this.showGameCodeResults = true;
                this.showGameCodeResults = false;
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.fetchData();
                });
            },
            fetchData() {
                let isnum = /^\d+$/.test(this.inputValue);
                if (isnum){
                    // console.log('only numbers');
                    if (this.inputValue.length === 23 || this.inputValue.length > 29) {
                        // console.log('too long');
                        // Show: The promotion code entered was not recognized.
                    } else {
                        this.submitTicket();
                    }
                } else {
                    // console.log('Not just Numbers');
                    this.submitTicket();
                }
            },
            submitTicket() {
                const urlProtocol = this.getProtocol();
                let forSubmission = {
                    "serialNumber": this.inputValue,
                    "userId": this.$cookies.get('TokenInfo').User.UserId,
                    "gameNumericIdentifier": '000'
                }

                let requestOptions = {
                    method: 'POST',
                    headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ this.token
                    },
                    body: JSON.stringify(forSubmission)
                };

                return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/TicketChecker/SubmitTicket", requestOptions)
                .then(response => response.json())
                .then(json => {
                    // console.log(json);
                    let data = json.data;

                    if (json.error === null) {

                        if(data.scanResultMessage == "There are no active drawings for this promotion."){
                            this.isLoading = false;
                            this.showErrMsg = true;
                            this.errorMessage = "There are no active drawings for this promotion."
                        }
                        else if(data.scanResultMessage.length){
                            
                            this.isLoading = false;
                            this.showScanResultMessage = true;
                            this.scanResultMessage = data.scanResultMessage

                            if(this.scanResultMessage == 'This promotion code has already been used.'){
                                this.alreadyentered = true;
                                this.success = false;
                                this.wrongcode = false;

                            }else{
                                this.success = true;
                                this.alreadyentered = false;
                                this.wrongcode = false;
                            }

                            this.showErrMsg = false;
                        }
                        else{                            
                            let result = {
                                icon: this.gameLogo,
                                serial: data.serialNumber,
                                message: data.submitMessage,
                                points: data.rewardsPointsValue
                            }

                            this.resultsArray.push(result);

                            this.showResults = true;
                        }

                    } else {
                        this.isLoading = false;
                        this.showErrMsg = true;
                        this.showScanResultMessage = false;
                        json.error.message === "Invalid Serial Number" ? this.errorMessage = "The correct code word is hidden in the video below. Can you find it?" : this.errorMessage = json.error.message;

                        this.alreadyentered = false;
                        this.success = false;
                        this.wrongcode = true;
                    }
                    
                })
                .catch(error => {
                    this.isLoading = false;
                    this.showErrMsg = true;
                    error.message === "Invalid Serial Number" ? this.errorMessage = "The correct code word is hidden in the video below. Can you find it?" : this.errorMessage = error;
                    // console.log(error.message)
                });
            },
            fetchContentByKey(){
                this.getAPItoken()
                .then((token) => {
                    let apiToken = token;
                    let ContentKey = this.contentKey;

                    return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey="+ContentKey, { 
                        headers: {
                            'Authorization': 'Bearer '+apiToken,
                        }
                    })
                    .then(response => response.json())
                    .then(json => {
                        // console.log('placeholder '+ this.placeholderProp, 'heading '+ this.headingProp, 'submit '+ this.submitTextProp)

                        if (json.data.length > 0) {
                            let content = json.data[0].centralContent;
                            let parseContent = JSON.parse(content);

                            this.placeholder = parseContent.placeholder;
                            this.submit = parseContent.submit;
                            this.entered = parseContent.entered;
                            this.content = parseContent.content;
                            this.cssClass = parseContent.cssclass;
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
                });
            },
            getKYCVerified() {
                //get the userId
                this.user = this.$cookies.get('apiToken');
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + this.user.user.userId;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.user.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    // console.log(json);
                    // console.log(json.data);
                    //always true
                    this.isKYCVerified = json.data.userIsVerified;

                    // console.log("User is verified?" + json.data.userIsVerified);

                    if (!this.kycMode) {
                        this.isKYCVerified = true;
                    }

                    if (json.data.errorMessage !== '') {
                       console.log(json.data.errorMessage);
                    }

                    // this.isKYCVerified = true;
                    // console.log(`KYC : ${this.isKYCVerified}`);
                })
                .catch((error) => {
                    // return (this.errorMessage = error)
                    console.log(error);
                })
                 
            },
            goVerify() {
                //set the return link into a cookie
                //go to verify page
                let goURL = window.location.origin + "/mylotto-rewards/my-account/verify-my-identity";
                this.$cookies.set("kycReturnUrl", JSON.stringify(window.location));
                window.location.assign(goURL);
            }
           
        },
        computed: {
            scanResultText() {
                if (this.scanResultMessage === 'This promotion code has already been used.') {
                    return 'You can only enter once per entry period, but rest assured we have your entry!';
                    //return 'You\'re now officially entered. Check the dates below for up to 3 chances to win!';                    
                } else {
                    return 'You\'re now officially entered. Check the dates below for up to 3 chances to win!';
                    //return 'You can only enter once per entry period, but rest assured we have your entry!';
                }
            }
        },
    };
</script>

<style lang="scss" scoped>
    //image styles
    .secretGame-enterSecret-Text {
        width: 100%;
        max-width: 350px;
        height: auto;
    }
    @media (min-width:641px){
        .secretGame-enterSecret-Text {
            max-width: initial;
        }
    }

    .secretGame-enterSecret-Text-Wrong {
        width: 50%;
        max-width: 350px;
        height: auto;
    }
    @media (min-width:641px){
        .secretGame-enterSecret-Text-Wrong {
            width: 90%;
            max-width: initial;
        }
    }

    .secretGame-enterSecret-Text-Already {
        width: 70%;
        height: auto;
    }

    .secretGame-enterSecret-Text-Success {
        width: 90%;
        height: auto;
    }

    //whole form
    .ticket-entry-form {
        background-color: transparent !important;
        color: rgba(213, 32, 41, 1);
        padding: 0px 10px !important;

    }

    //text input line
    .input-group{
        display:flex !important;
        width:100%;
        height:100%;
        align-items: center;

        input{
            border-radius: 39px;
            background-color: rgba(255, 255, 255, 1);
            color: rgba(213, 32, 41, 1);
            font-family: "Roboto Slab";
            font-size: clamp(14px, 2vw, 25px);
            font-weight: 700;
            width:90%;
            margin: 10px 5px;
        }
        .input-group-button input{
             color: rgb(255, 255, 255);
            background-color: rgb(37,127,64);
            padding: 5px 15px;
            margin: 15px 5px;
            letter-spacing: 2px;
        }
    }

    @media (min-width: 641px){
        .input-group .input-group-button input{
            padding: 10px 20px;
            margin: 15px 5px;
        }
    }

    //invalid entry error line
    .error-ticket {
        p {
            color: white;

            display: inline-block;
            vertical-align: middle;
            margin-top: 10px;
            line-height: initial;
            
            font-family: "Roboto Slab";
            font-size: 18px;
            font-weight: 400;
        }
        .button.secondary{
            color: rgba(213, 32, 41, 1);
            background-color: white;

            text-transform: none;
            letter-spacing: unset;
            display: inline-block;
            min-width: 65px;
            font-size: 20px;
            padding: 14px;

            font-family: "Roboto Slab";
            font-weight: 700;
        }
        @media(min-width:641px){
            p {
                font-size: 24px;
                margin-top: 20px;
            }
            .button.secondary{
                font-size: 30px;
                min-width: 200px;
            }
        }
    }

    .success-ticket{
        display:flex;
        align-items: center;
        p {
            color: white;
            display: inline-block;
            vertical-align: middle;
            line-height: initial;
            margin-top: 20px;

            font-family: "Roboto Slab";
            font-size: 18px;
            font-weight: 400;
        }
        @media(min-width:641px){
            p {
                font-size: 24px;
            }
        }
    }

    /*Verify ID Entry Form Styles*/
    .promoCodeNotVerified{
        background: transparent;
        color: white;
        
        h3{
            color: white;
            font-size: clamp(14px, 3vw, 25px) !important;
            font-weight: 500;
        }
        p{
            font-size: clamp(10px, 2vw, 18px);
            margin-bottom: 5px;
            line-height: initial; 
        }
        .button.btn-primary{
            background-color: rgb(37,127,64) !important;
            letter-spacing: 2px !important;
            width: clamp(50px, 100vw, 150px);
            font-size: clamp(10px, 1vw, 15px);
            min-height: 2rem;
            height: 3vw;
        }
        .button.btn-primary:hover{
            background-color: rgb(24, 85, 42) !important;
        }
    }
</style>