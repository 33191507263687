<template>
    <div class="club-member-survey" v-if="showMemberSurvey">
        <div class="contestIntro">
            <p>You must be signed in to your MyLotto Rewards account to participate in this survey.</p>

            <p>We need your input! Take five minutes and share your opinions to help us tailor our communications to you.</p>

            <p><strong>If you qualify and complete this short survey by June 19, 2023, you will be entered into a drawing for a book of $5 Scratch-Offs, worth $250 in value! </strong></p>

            <p>If you're on a mobile phone, please rotate your phone to landscape/horizontal orientation to complete the survey.</p>
        </div>


        <div class="alert callout error-msg" ref="errorMsgRef" v-if="isError">
            <p><i class="fi-alert"></i> {{ errorMsg }}</p>
        </div>

        <div class="callout success" v-if="isSuccess">
            <p>The survey was sent successfully</p>
        </div>

        <div class="contentRow questions-wrapper" v-if="questions.length">

            <p class="survey_direction">Please think about the specific lottery games you play most often or are interested in playing. For each of the following statements, please select the response that represents how closely you relate to one statement versus the other when it comes to playing lottery games.</p>

            <div class="question"  :question-id="question.questionID" v-for="(question, index) in questions.slice(0,8)" :key="question.id">
                <h3>{{ question.text }}</h3>
                
                <div class="text-center orDiv">
                    <span class="icon-chevron-left"></span>
                    <span>OR</span>
                    <span class="icon-chevron-right"></span>
                </div>

                <div class="select-answer">
                    <span class="question-label">{{ question.answers[0].answerText }}</span>

                    <div class="text-center orDivMobile">
                        <span class="icon-chevron-up"></span>
                        <span>OR</span>
                        <span class="icon-chevron-down"></span>
                    </div>
                    
                    <span class="horizontal-radio-buttons">

                        <label :for="question.answers[0].answerID"> 
                            <input type="radio" value="1" @click="selectedRadio($event)" :name="question.questionID" /> 1 </label>
                        <label :for="question.answers[0].answerID"> 
                            
                            <input type="radio" value="2" @click="selectedRadio($event)" :name="question.questionID"/> 2 </label>

                        <span class="show-for-medium"> | </span>

                        <label :for="question.answers[1].answerID"> 
                            <input type="radio" value="3" @click="selectedRadio($event)" :name="question.questionID" /> 3 </label>
                        <label :for="question.answers[1].answerID"> 
                            <input type="radio" value="4"  @click="selectedRadio($event)" :name="question.questionID" /> 4 </label>

                    </span>

                    <span class="question-label">{{ question.answers[1].answerText }}</span>
                </div>

                <div id="ValidationError" name="ValidationError"></div>
            </div>

            <p class="survey_direction">Please think about the specific lottery games you play most often or are interested in playing. When playing lottery games, how much do you agree or disagree with the following statements about playing lottery games?</p>

            <div class="question" :question-id="question2.questionID" v-for="(question2, index) in questions.slice(8, 10)" :key="question2.id">
                <h3>{{ question2.text }}</h3>

                <div class="aditional-text">
                    <p>{{ question2.aditionalText }}</p>
                </div>

                
                <div class="vertical-answers">

                    <span class="vertical-radio" v-for="answers in question2.answers" :key="answers.id">
                        <label :for="answers.answerID"> 
                            <input type="radio" :id="answers.answerID" :value="answers.answerText" @click="selectedRadio($event)" :name="question2.questionID" />
                            {{ answers.answerText }}
                        </label>
                    </span>
                </div>
            </div>

            <p class="survey_direction">Please think about the specific lottery games you play most often or are interested in playing. How influential are the following aspects when considering playing lottery games?</p>

            <div class="question" :question-id="question3.questionID" v-for="(question3, index) in questions.slice(10, 12)" :key="question3.id">
                <h3>{{ question3.text }}</h3>

                <div class="aditional-text">
                    <p>{{ question3.aditionalText }}</p>
                </div>
                
                <div class="vertical-answers">

                    <span class="vertical-radio" v-for="answers in question3.answers" :key="answers.id">
                        <label :for="answers.answerID">
                            <input type="radio" :id="answers.answerID" :value="answers.answerText" @click="selectedRadio($event)" :name="question3.questionID" />
                            {{ answers.answerText }}
                        </label>
                    </span>
                </div>
            </div>

            

            <div class="btn-wrapper">
                <button class="button" @click="submitAnswers" >Submit</button>
            </div>

        </div>

    </div>
</template>


<script>
    export default {
        components: {
        },
        data: function () {
            return {
                questions: [],
                answerQuestions: [],
                surveyId: '',
                userId: '',
                userName: '',
                apiTokenGlobal: '',
                isError: false,
                isSuccess: false,
                errorMsg: '',
                showMemberSurvey: false
            }
        },
        mounted: function () {
            let getCurrentUser = this.$cookies.get('apiToken');
            if(getCurrentUser.user.email !== "mobilepublic@mtllc.com"){
                this.showMemberSurvey = true
            }

            this.getToken();
        },
        methods: {
            onSubmit(values) {
                // console.log(JSON.stringify(values, null, 2));
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(apiToken) {
                let getCurrentUser = this.$cookies.get('apiToken');
                this.userId = getCurrentUser.user.userId;
                // this.userName = getCurrentUser.user.username;
                //let userId = parseInt(this.userID)
                this.apiTokenGlobal = apiToken;

                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Survey/Survey/GetActiveSurveys?userID="+this.userId, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    // console.log(json)
                    let activeSurveys = json.data
                    let hasActiveSurvey = false
                    
                    for(let i = 0; i < activeSurveys.length; i++){
                        // console.log(activeSurveys[i])
                        if(activeSurveys[i].surveyIdentifier == "MLRClubMemberSurvey"){
                            hasActiveSurvey = true
                        }else{
                            this.isError = true;
                            this.errorMsg = "Member has already participated on this survey."
                            
                            this.$nextTick(() => document.getElementsByClassName("error-msg")[0].scrollIntoView({ behavior: "smooth", inline: "nearest" }) )
                            return
                        }
                    }

                    if(hasActiveSurvey){
                        return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Survey/Survey/GetSurveyByIdentifier?surveyIdentifier=MLRClubMemberSurvey", {
                            headers: {
                                'Authorization': 'Bearer '+apiToken,
                            },
                        })
                        .then(response => response.json())
                        .then(json => {
                            // console.log(json)
                            let data = json.data;
                            this.questions = data.questions

                            // console.log(data)

                        })
                        .catch(error => {
                            // console.error("There was an error!", error);
                            return this.errorMessage = error;
                        });
                    }

                    

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });

                
            },

            selectedRadio(event){
                let questionId = event.target.getAttribute('name')
                let responseText = event.target.value
                let answerId = event.target.parentNode.getAttribute('for')

                let questionsObj = {
                    "questionId": parseInt(questionId),
                    "answers": [
                        {
                        "answerId": parseInt(answerId),
                        "responseText": responseText
                        }
                    ]
                }

                function pushToArray(arr, obj) {
                    const index = arr.findIndex((e) => e.questionId === obj.questionId);

                    if (index === -1) {
                        arr.push(obj);
                    } else {
                        arr[index] = obj;
                    }
                }

                pushToArray(this.answerQuestions, questionsObj)

            },
            submitAnswers(){

                // console.log(this.userId)

                let answerObj = {
                    "surveyID": 13,
                    "userId": this.userId,
                    "questions": this.answerQuestions
                }

                const apiToken = this.apiTokenGlobal;

                const requestOptions = {
                    method: 'POST',
                    headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+apiToken
                    },
                    body: JSON.stringify(answerObj)
                };

                fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Survey/Survey/SubmitAnswers", requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        // console.log(data)

                        if(data.error){
                            this.isError = true;
                            this.errorMsg = data.error.message

                            this.$nextTick(() => document.getElementsByClassName("error-msg")[0].scrollIntoView({ behavior: "smooth", inline: "nearest" }) )

                        }else{
                            this.isError = false;
                        }
                    })
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>