<template>
    <div class="rotw2025">
        <!-- <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-8 large-8">
                <FeaturedRetailerDetail :subtractDays="subtractDays"></FeaturedRetailerDetail>
            </div>
            <div class="cell small-12 medium-4 large-4">
                <FeaturedRetailerCalendar :subtractDays="subtractDays" @data-fetched="handleDataFetched"></FeaturedRetailerCalendar>
                <div class="module red_header number_check_form">
                    <h3>Keno Testimonials</h3>
                    <div class="keno_testimonies_sidebar">
                            <img alt="Keno" src="/getattachment/f62ca30f-0feb-4e9a-a89f-678dfcb0f894/keno.png">
                            <p>Bring more customers into your store with Keno.</p>
                            <a class="button red_btn" href="/retailers/keno">VIEW</a>
                        </div>
                </div>
            </div>
        </div> -->
        <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-8 large-8 removePad">
                <h2>Retailer-of-the-Week</h2>
                <div class="for_retailers">
                    <div class="loadingContent" v-if="allArticles.length < 1"><strong>Loading...</strong></div>
                    <div id="retailer_of_the_week" v-else>
                        <div v-if="tempLoad">
                            <hollow-dots-spinner
                                :animation-duration="1000"
                                :dot-size="15"
                                :dots-num="3"
                                color="#0FD8C9"
                            />
                        </div>
                        <div class="retailer_week" v-if="primaryArticle.date && !tempLoad">Week of {{ customFormatDisplay(primaryArticle.date) }} </div>

                            <div class="retailer_title" v-if="!tempLoad">
                                {{primaryArticle.title}}
                            </div>

                            <div class="retailer_location" v-if="!tempLoad">
                                <div class="retailer_address">{{ primaryArticle.streetAddress }}</div>
                                <div class="retailer_county">{{ primaryArticle.city }}, {{  primaryArticle.state }}</div>
                            </div>

                            <!-- <div class="retailer_body desktopOnly" v-if="primaryArticle.content != '' && !tempLoad">
                                <span v-html="primaryArticle.content"></span>
                            </div> -->
                        </div>
                        <div class="retailer_photo_container" v-if="!tempLoad">
                            
                            <div class="retailer_photo" v-if="primaryArticle.image1URL != ''">
                                <img id="" :src="primaryArticle.image1URL" alt="Retailer Photo">
                            </div>
        
                            <div class="retailer_photo_caption">{{primaryArticle.image1Caption}}</div>

                            <div class="retailer_photo2" v-if="primaryArticle.image2URL != ''">
                                <img id="" :src="primaryArticle.image2URL" alt="Retailer Photo" >
                            </div>
        
                            <div class="retailer_photo_caption" v-if="primaryArticle.image2Caption != ''">{{primaryArticle.image2Caption}}</div>

                        </div>
                        <div class="retailer_body" v-if="primaryArticle.content != '' && !tempLoad">
                            <span v-html="primaryArticle.content"></span>
                        </div>
                </div>
            </div>
            <div class="cell small-12 medium-4 large-4 removePad">
                <div class="fullArticlesSearch">
                    <h3>Find a Retailer-of-the-Week</h3>
                    <h4>Search by name</h4>
                    <div class="combo-box">
                        <input 
                            type="text"
                            v-model="searchQuery"
                            @input="filteredItems"
                            placeholder="Retailer Name"
                            @focus="isDropdownVisible = true"
                            @blur="hideDropdown"
                        />
                        <ul v-if="isDropdownVisible && filteredItems.length" class="dropdown">
                            <li 
                                v-for="(item, index) in filteredItems"
                                :key="index"
                                @mousedown.prevent="selectItem(item)"
                            >
                                {{ item.locationName }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="findretailer_picker">
                    <h4>Search by date</h4>
                        <datepicker placeholder="Select Date" name="inputRetailerDate" :format="customFormatter" @input="searchArticleByDate" v-model="dateToSearch"></datepicker>
                        <!-- <button class="button" @click="go">GO</button> -->
                        <div v-if="articleDateError" class="error">Please select another date.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import moment from 'moment';
import Datepicker from "vue-moment-datepicker";
import moment from 'moment';
import { HollowDotsSpinner } from 'epic-spinners'

export default {
    components: {
        Datepicker,
        HollowDotsSpinner

    },
    data: function () {
        return {
            subtractDays: 100,
            apiData: null,
            allArticles: [],
            primaryArticle: '',
            isDropdownVisible: false,
            filteredItems: [],
            searchQuery: '',
            searchQueryId: '',
            showError: false,
            dateToSearch: null,
            articleDateError: false,
            tempLoad: true
        }
    },
    mounted: function () {
        this.getAllFeaturedRetailers();
    },
    methods: {
        handleDataFetched(data) {
            this.apiData = data;
        },
        customFormatter(date) {
                // console.log(moment(date).format('YYYY-MM-DD'));
                return moment(date).format('YYYY-MM-DD');
        },
        customFormatDisplay(date) {
                // console.log(moment(date).format('YYYY-MM-DD'));
                return moment(date).format('LL');
        },
        getAllFeaturedRetailers() {
                let apiToken = $cookies.get('apiToken'),
                    token = apiToken.token,
                    url = process.env.VUE_APP_APIURL,
                    today = new Date();

                    today = this.customFormatter(today);

                return fetch(url + `/1.0/Games/Article/GetByDateRangeAndCategory?dateFrom=2018-01-01&dateTo=${today}&category=FeaturedRetailer`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(response => response.json())
                .then(json => {
                    this.allArticles = json.data;
                    // console.log("All of the articles from Parent Call");
                    // console.log(this.allArticles);
                    
                    // for now just reversing the array
                    this.allArticles = this.allArticles.reverse();

                    //set the initial primary Article as the last one from the array (most recent)
                    this.primaryArticle = this.allArticles[0];
                    // console.log(this.primaryArticle);
                    //remove temp Load
                    this.tempLoad = false;
                })
                .catch(error => {
                    // console.log('Error from retrieving all ROTW');
                    console.log(error);
                });
                
            },
            filterItems() {
                this.filteredItems = this.allArticles.filter((item) => 
                    item.locationName.toLowerCase().includes(this.searchQuery.toLowerCase())
                );
            },
            selectItem(item) {
                this.searchQuery = item.locationName;
                this.searchQueryId = item.articleID;
                this.isDropdownVisible = false;

                // console.log("Search term : " + this.searchQuery);
                // console.log("Search through all the articles");
                // console.log("All Articles");
                // console.log(this.allArticles);
                //filter the article by the search term
                let searchTerm = this.allArticles.filter((article) => article.articleID === this.searchQueryId);
                // console.log("The single article:")
                // console.log(searchTerm);
                //conduct the search on the specific article
                this.searchSingleArticle(searchTerm);
            },
            hideDropdown() {
                setTimeout(() => {
                    this.isDropdownVisible = false;
                }, 100);
            },
            searchSingleArticle(itemArr) {
                this.tempLoad = true;
                // console.log("Now in the search function");
                // console.log(itemArr);
                //set the article as primary
                // this.primaryArticle = itemArr[0];

                setTimeout(() => {
                    this.primaryArticle = itemArr[0];
                    this.tempLoad = false;
                }, 3000)
            },
            searchArticleByDate() {
                this.tempLoad = true;
                let dateVal = this.dateToSearch;
                dateVal = this.customFormatter(dateVal)
                // console.log("Date value from datpicker");
                // console.log(dateVal);
                let articleWithinDateRange = this.filterByDateRange(this.allArticles, dateVal);
                // console.log("The article in date range is: ");
                // console.log(articleWithinDateRange);

                if (articleWithinDateRange === 'undefined' || articleWithinDateRange === undefined) {
                    this.articleDateError = true;
                } else {
                    this.articleDateError = false;
                    //set the articleWithinDateRange as the primary article to display
                    // this.primaryArticle = articleWithinDateRange;
                    setTimeout(() => {
                        this.primaryArticle = articleWithinDateRange;
                        this.tempLoad = false;
                    }, 3000)
                }
            },
            filterByDateRange(items, targetDate) {
                const target = new Date(targetDate);
                const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;
                return items.find(item => {
                    const itemDate = new Date(item.date);
                    return Math.abs(itemDate - target) <= oneWeekInMillis;
                });
            }
    },
    computed: {
    },
    watch: {
            searchQuery() {
                this.filterItems();
            }
        }
};
</script>

<style lang="scss" scoped>
    .combo-box {
        position: relative;
        width: 100%;
    }

    .combo-box input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .dropdown {
        position: absolute;
        width: 100%;
        border: 1px solid #ccc;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        z-index: 1000;
        list-style-type: none;
        margin-left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 300px;
        font-size: 1.4rem;
    }

    .dropdown li {
        padding: 10px;
        cursor: pointer;
    }

    .dropdown li:hover {
        background-color: #f0f0f0;
    }

    .rotw2025 {
        .grid-padding-x > .cell.removePad {
            padding-left: 0;
            padding-right: 0;
        }
        h2 {
            font-size: 2.4rem;
            margin-bottom: 3rem;
        }
        .for_retailers {
            #retailer_of_the_week {
                @media only screen and (min-width: 640px) {
                    grid-area: title;
                }
            }
            .retailer_photo_container {
                float: unset;
                width: unset;
                margin-left: 0;
                max-width: unset;

                @media only screen and (min-width: 640px) {
                    max-width: 450px;
                    grid-area: image;
                }
            }

            .retailer_title {
                font-size: 2.6rem;
                line-height: 3.2rem;
            }

            .retailer_week {
                margin-bottom: 2rem;
            }

            .retailer_address {
                font-size: 1.6rem;
            }

            .retailer_county {
                font-size: 1.6rem;
            }

            .retailer_photo_caption {
                margin: .5rem 0 3rem;
            }

            .retailer_body {
                margin-bottom: 3rem;

                @media only screen and (min-width: 640px) {
                    grid-area: content;
                }
            }

            @media only screen and (min-width: 640px) {
                display: grid;
                margin-right: 1.2rem;
                grid-template-columns: 60% 40%;
                grid-template-areas: 
                    "title image"
                    "content content"
            }
        }

        h3 {
            font-size: 2rem;
        }

        h4 {
            font-size: 1.8rem;
        }
    }
</style>