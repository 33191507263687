<template>
    <div class="highPayoutWNRotator cf">

        <VueSlickCarousel v-bind="settings" class="highPayoutWN-wrapper">

            <div>
                <div class="logo-game" id="highPayoutWNRotator-logo">
                    <img v-bind:src="pick3gameLogo" v-bind:alt="pick3gameName" class="winNumGameLogo" />
                </div>

                <div id="highPayoutWNRotator-alert">
                    <span class="highpayout-winning-number"></span>
                    <div class="highPayoutWNRotator-drawingBox">
                        <div class="highPayoutWNRotator-dates-numbers">
                            <div class="highPayoutWNRotator-label" v-if="pm">
                                MIDDAY
                            </div>
                            <div class="highPayoutWNRotator-label" v-if="!pm">
                                EVENING
                            </div>
                            <WinningNumbers :numbers="pick3drawNumbers" :date="pick3drawDate"></WinningNumbers>
                            
                        </div>
                        

                   
                        <div class="highPayoutWNRotator-dates-numbers">
                            <div class="highPayoutWNRotator-label" v-if="!pm">
                                MIDDAY
                            </div>
                            <div class="highPayoutWNRotator-label" v-if="pm">
                                EVENING
                            </div>
                            <WinningNumbers :numbers="pick3drawNumbersMidday" :date="pick3drawDateNumbersMidday"></WinningNumbers>
                            
                        </div>
                        
                    </div>
                </div>
            </div>

            <div>
                <div class="logo-game" id="highPayoutWNRotator-logo">
                    <img v-bind:src="pick4gameLogo" v-bind:alt="pick4gameName" class="winNumGameLogo" />
                </div>

                <div id="highPayoutWNRotator-alert">
                    <span class="highpayout-winning-number"></span>
                    <div class="highPayoutWNRotator-drawingBox">
                        <div class="highPayoutWNRotator-dates-numbers">
                            <div class="highPayoutWNRotator-label" v-if="pm">
                                MIDDAY
                            </div>
                            <div class="highPayoutWNRotator-label" v-if="!pm">
                                EVENING
                            </div>
                            <WinningNumbers :numbers="pick4drawNumbers" :date="pick4drawDate"></WinningNumbers>
                        </div>
                        

                   
                        <div class="highPayoutWNRotator-dates-numbers">
                            <div class="highPayoutWNRotator-label" v-if="!pm">
                                MIDDAY
                            </div>
                            <div class="highPayoutWNRotator-label" v-if="pm">
                                EVENING
                            </div>
                            <WinningNumbers :numbers="pick4drawNumbersMidday" :date="pick4drawDateNumbersMidday"></WinningNumbers>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div>
                <div class="logo-game" id="highPayoutWNRotator-logo">
                    <img v-bind:src="pick5gameLogo" v-bind:alt="pick5gameName" class="winNumGameLogo" />
                </div>

                <div id="highPayoutWNRotator-alert">
                    <span class="highpayout-winning-number"></span>
                    <div class="highPayoutWNRotator-drawingBox">
                        <div class="highPayoutWNRotator-dates-numbers">
                            <div class="highPayoutWNRotator-label" v-if="pm">
                                MIDDAY
                            </div>
                            <div class="highPayoutWNRotator-label" v-if="!pm">
                                EVENING
                            </div>
                            <WinningNumbers :numbers="pick5drawNumbers" :date="pick5drawDate"></WinningNumbers>
                        </div>
                        

                   
                        <div class="highPayoutWNRotator-dates-numbers">
                            <div class="highPayoutWNRotator-label" v-if="!pm">
                                MIDDAY
                            </div>
                            <div class="highPayoutWNRotator-label" v-if="pm">
                                EVENING
                            </div>
                            <WinningNumbers :numbers="pick5drawNumbersMidday" :date="pick5drawDateNumbersMidday"></WinningNumbers>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </VueSlickCarousel>
        
        
    </div>
    
</template>

<script>
import {mapGetters} from 'vuex';
import WinningNumbers from '../../../ViewComponents/WinningNumbers/WinningNumbersHighPayout.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import moment from 'moment';

    export default {
        components: {
            WinningNumbers,
            VueSlickCarousel
        },
        data: () => ({
            user: {},
            games: [],
            token: '',

            pick3drawNumbers: [],
            pick3drawNumbersMidday: [],
            pick3drawDate: '',
            pick3drawDateNumbersMidday: '',
            pick3gameLogo: '',
            pick3gameName: '',

            pick4drawNumbers: [],
            pick4drawNumbersMidday: [],
            pick4drawDate: '',
            pick4drawDateNumbersMidday: '',
            pick4gameLogo: '',
            pick4gameName: '',

            pick5drawNumbers: [],
            pick5drawNumbersMidday: [],
            pick5drawDate: '',
            pick5drawDateNumbersMidday: '',
            pick5gameLogo: '',
            pick5gameName: '',
            
            date: '',
            settings: {
                "dots": false,
                "dotsClass": "slick-dots custom-dot-class",
                "edgeFriction": 0.35,
                "infinite": false,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1
            },
            loaded: false,
            pm: false
        }),
        mounted: function() {
            this.getToken();
        },
        methods: {
            getLocalGames() {
                // let games = JSON.parse(window.localStorage.getItem('olcDrawNumbers'));
                let games = document.getElementById('cmsNumbers').value;
                games = JSON.parse(games);

                // console.log(games);
                
                this.games = games;

                // Set Evening/Midday Labels
                let draws;

                for (let i = 0; i < this.games.length; i++) {
                    if (this.games[i].StringIdentifier === "Pick3") {
                        this.pick3gameName = this.games[i].Name;
                        this.pick3drawDate = this.games[i].Draws[1].DrawDate;
                        this.pick3drawDateNumbersMidday = this.games[i].Draws[0].DrawDate;
                        this.pick3gameLogo = this.games[i].GameLogoURL;
                        this.pick3drawNumbers = this.games[i].Draws[1].Numbers;
                        this.pick3drawNumbersMidday = this.games[i].Draws[0].Numbers;

                        draws = this.games[i].Draws;
                        let draw1 = draws[0],
                            draw2 = draws[1],
                            draw1Date = moment(draw1.DrawDate),
                            draw2Date = moment(draw2.DrawDate);

                        draw1Date.isSame(draw2Date, 'date') === true ? this.pm = true : this.pm = false;
                    }

                    if (this.games[i].StringIdentifier === "Pick4") {
                        this.pick4gameName = this.games[i].Name;
                        this.pick4drawDate = this.games[i].Draws[1].DrawDate;
                        this.pick4drawDateNumbersMidday = this.games[i].Draws[0].DrawDate;
                        this.pick4gameLogo = this.games[i].GameLogoURL;
                        this.pick4drawNumbers = this.games[i].Draws[1].Numbers;
                        this.pick4drawNumbersMidday = this.games[i].Draws[0].Numbers;
                    }

                    if (this.games[i].StringIdentifier === "Pick5") {
                        this.pick5gameName = this.games[i].Name;
                        this.pick5drawDate = this.games[i].Draws[1].DrawDate;
                        this.pick5drawDateNumbersMidday = this.games[i].Draws[0].DrawDate;
                        this.pick5gameLogo = this.games[i].GameLogoURL;
                        this.pick5drawNumbers = this.games[i].Draws[1].Numbers;
                        this.pick5drawNumbersMidday = this.games[i].Draws[0].Numbers;
                    }
                }

                

                this.loaded = true;
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.token = token;   
                    this.getLocalGames();
                });
            },
            ...mapGetters ({
                getGames: 'getNumbers/getGames',
                getStorageNumbers: 'getNumbers/getStorageNumbers'
            })
        }
    };
</script>


<style lang="scss" scoped>
    
</style>