<template>
    <div class="winning-numbers-wrapper">        
        <p>To check your numbers, pick a game below:</p>

        <div class="check_numbers_tabs tabbed_content">
            <tabs>
                <tab v-for="game in games" :key="game.tabClass" :title="game.title" :showTitle="false">
                    <CheckYourNumbersTab 
                    :gameName="game.gameName"
                    :titleGameName="game.titleGameName" 
                    :descGame="game.descGame" 
                    :threadNames="game.threadNames" 
                    :inputsQuantity="game.inputsQuantity" 
                    :startNumber="game.startNumber" 
                    :endNumber="game.endNumber" 
                    :formatPickDate="game.formatPickDate" 
                    :pastResults="game.pastResults" 
                    :winner="game.winner" 
                    :frequentlyNumbers="game.frequentlyNumbers">
                    </CheckYourNumbersTab>
             
                    <DayCalculator></DayCalculator>
             
                </tab>
            </tabs>
        </div>
        <!-- <input type="date" id="archiveDateModule" style="width:40%;display:inline-block;margin-right:10px">
        <a id="archiveSubmit" class="button button--bluGo archiveSubmit" href="">See Results</a> -->
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import Tab from '../../ViewComponents/Tabs/Tab.vue';
    import Tabs from '../../ViewComponents/Tabs/Tabs.vue';
    import CheckYourNumbersTab from './CheckYourNumbersTab.vue';
    import DayCalculator from '../../ViewComponents/WinningNumbers/CheckNumbersPanels/DayCalculator.vue';
    import moment from 'moment';

    // import moment from 'moment'
    export default {
        components: {
            Tab,
            Tabs,
            CheckYourNumbersTab,
            DayCalculator
        },
        data: function () {
            return {
                megaMillions: "MegaMillions",
                luckyForLife: "LuckyForLife",
                classicLoto: "ClassicLotto",
                rollingCashFive: "RollingCashFive",
                powerBall: "PowerBall",
                pick3: "Pick3",
                pick4: "Pick4",
                pick5: "Pick5",
                games: []
            }
        },
        mounted: function () {
            this.games = this.getGames();
            this.tabs = this.getTabClasses();

            // console.log(this.games);

            setTimeout(() => {
                var tabClasses = this.tabs;

                var tabs = document.getElementsByClassName("tabs")[0].getElementsByTagName("li");
                var arrLi = Array.from(tabs);


                for(let i = 0;i < arrLi.length; i++){
                    var index = i;
                    if (i < tabClasses.length){
                        arrLi[i].children[0].classList.add(tabClasses[index])
                    }
                }

                let url = window.location.hash;
                // console.log(url)

                // remove active from first tab...unless it IS the first tab!
                if (window.location.hash !== '#tab0') {
                    arrLi[0].classList.remove('is-active');
                }
                
            }, 500);

            setTimeout(() => {
                let datePicker = document.getElementById('archiveDateModule'),
                    archiveResult = document.getElementById('archiveSubmit');

                    archiveResult.addEventListener('click', this.goToArchive);
            }, 1000);
        },
        methods: {
            goToArchive(e) {
                e.preventDefault();
                let datePicker = document.getElementById('archiveDateModule'),
                    archiveDate = this.customFormatter(datePicker.value);

                let pagePath = '/winning-numbers/winning-numbers-archive?date='

                if (archiveDate !== '') {
                    window.location.assign(pagePath + archiveDate);
                } else {
                    // show an error
                }
            },
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy');
            },
            registerInteract() {
                console.log('click')
            },
            ...mapGetters ({
                getGames: 'checkYourNumbers/getGames',
                getTabClasses: 'checkYourNumbers/getTabClasses'
            })
        }
    };
</script>

<style lang="scss">
    
</style>