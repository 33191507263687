export default {
    namespaced: true,
    state: () => ({
        token: ''
    }),
    mutations: {
        mutateToken (state, payload) {
            let token = payload;

            // console.log(token);

            state.token = token;
        }
    },
    actions: {
        setToken({commit}, payload) {
            const apiToken = payload;

            commit('mutateToken', apiToken);
        }
    },
    getters: {
        getToken(state) {
            if (state.token === '') {
                // console.log('Returning 0')
                return 0;
            } else {
                // console.log('Returning Token')
                // console.log(state.token)
                return state.token.token;
            }
        }
    }
}