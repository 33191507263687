<template>
    <div class="">
        <header id="eAppHeader" class="cf">
            <h1 class="cf">Retailer Network</h1>
        </header>

        <SignInRegister v-if="!isLoading && !isUserLogged"></SignInRegister>

        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />
        <div v-if="!isLoading && isUserLogged && updateFound" class="eAppUpdates">

            <div class="eAppHeader cf">
                <!-- ifIsInRole: eApp-Retailer || eAppPrincipal -->
                <a v-if="showApplicationUpdatesHome" class="button eAppDashBtn" href="/eApplications/Updates">Application Updates Home</a>
                <!-- ifIsInRole: eApp-Retailer && eApp-ChainApplicant -->
                <a v-if="showApplicationsHome" class="button eAppDashBtn" href="/eApplications">Application Home</a>
                <!-- ifIsInRole: eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-RegionalManager || eAppDistrictRep || eApp-Security -->
                <a v-if="showApplicationDashboard" class="button eAppDashBtn" href="/eApplications/Dashboard/Regular">Application Dashboard</a>
                <!-- ifIsInRole: eApp-ChainSalesRep || eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-Security -->
                <a v-if="showChainApplicationDashboard" class="button eAppDashBtn" href="/eApplications/Dashboard/Chain">Chain Application Dashboard</a>

                <h1>Agent Number: <strong>{{ updateWrapper.agentNumber }}</strong></h1>
                <h3 style="clear:both;">Batch Number: <strong>{{ updateWrapper.batchNumber }}</strong></h3>
            </div>


            <div class="eAppModule">
                <div class="moduleContent cf saveForm reviewBackground appReview">
                    <h3 v-if="userRole.codename == 'eApp-CentralLicensingManager' && principalsAddedRemoved.length > 0">Added/Removed Principals</h3>
                    <table v-if="userRole.codename == 'eApp-CentralLicensingManager' && principalsAddedRemoved.length > 0" class="eAppListTbl eAppBGcheckTble">
                            <tbody>
                                <tr class="firstRow">
                                    <th>
                                        <label><strong>Principal Name</strong></label>
                                    </th>
                                    <th>
                                        <label><strong>Ownership &percnt;</strong></label>
                                    </th>
                                    <th>
                                        <label><strong>Update Type</strong></label>
                                    </th>
                                </tr>
                                <tr v-for="(principal, index) in principalsAddedRemoved" :key="index">
                                    <td v-if="principal.principalFullName.length > 0" style="padding-left:0px">
                                        {{ principal.principalFullName }}
                                    </td>
                                    <td v-else style="padding-left:0px">
                                        {{ principal.principalFirstName }} {{ principal.principalLastName }}
                                    </td>
                                    <td style="padding-left:0px">
                                        {{ principal.principalPercentOwnership }}%
                                    </td>
                                    <td style="padding-left:0px">
                                        <span v-if="principal.principalOperation == 0">Added</span>
                                        <span v-if="principal.principalOperation == 2">Removed</span>
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                    <h3 v-if="userRole.codename == 'eApp-CentralLicensingManager' && principalsPercentChange.length > 0">Updated Principals</h3>
                    <h3 v-else-if="userRole.codename !== 'eApp-CentralLicensingManager'">Background check</h3>
                    <div v-if="userRole.codename == 'eApp-Security' && principalsBgCheck.length > 0 || userRole.codename == 'eApp-RegionalSecurity' && principalsBgCheck.length > 0 || userRole.codename == 'eApp-CentralLicensingManager' && principalsPercentChange.length > 0" class="bgCheckSecurity">
                        <table v-if="userRole.codename == 'eApp-Security' || userRole.codename == 'eApp-RegionalSecurity'" class="eAppListTbl eAppBGcheckTble">
                            <tbody v-for="(principal, index) in principalsBgCheck" :key="index">
                                <tr class="firstRow">
                                    <td>
                                        <label>Principal:</label>
                                    </td>
                                    <td v-if="principal.principalFullName.length > 0" style="padding-left:0px">
                                        {{ principal.principalFullName }}
                                    </td>
                                    <td v-else>
                                        <strong>{{ principal.principalFirstName }} {{ principal.principalLastName }}</strong>
                                    </td>
                                    <td>
                                        <label>Application Date:</label>
                                    </td>
                                    <td>
                                        {{ customFormatter(principal.principalInformationUpdateLastModified) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tdBGcheckInfo" colspan="2">
                                        <a @click="showDetails(principal.principalInformationUpdateID)"
                                            class="bgCheckShow toggleContentBtn">Show Details</a>
                                    </td>
                                    <td>
                                        <label>Percent Ownership:</label>
                                    </td>
                                    <td>
                                        {{ principal.principalPercentOwnership }} %
                                    </td>
                                </tr>
                                <tr :id="'principal' + principal.principalInformationUpdateID" style="display:none;"
                                    class="BGcheckMoreInfo toggleContent">
                                    <td colspan="4" class="eAppBGcheckTbleDetails">
                                        <table class="">
                                            <tr>
                                                <td>
                                                    <label>Mailing Address:</label>
                                                </td>
                                                <td>
                                                    {{ principal.principalStreetAddress }}<br />
                                                    {{ principal.principalCity }},
                                                    {{ principal.principalState }}
                                                    {{ principal.principalZip }}
                                                    {{ principal.principalZipPlus4 }}
                                                </td>
                                                <td>
                                                    <label>E-mail Address:</label>
                                                </td>
                                                <td>
                                                    {{ principal.principalEmail }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Home Phone:</label>
                                                </td>
                                                <td>
                                                    {{ principal.principalHomePhoneNumber }}
                                                </td>
                                                <td>
                                                    <label>Mobile Phone:</label>
                                                </td>
                                                <td>
                                                    {{ principal.principalCellPhoneNumber }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Date of Birth:</label>
                                                </td>
                                                <td>
                                                    {{ customFormatter(principal.principalDateOfBirth) }}
                                                </td>
                                                <td>
                                                    <label>Social Security Number:</label>
                                                </td>
                                                <td>
                                                    {{ principal.principalSSN }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="bgCheckAnswerCol">
                                                    <div class="bgCheckAnswer">
                                                        {{ booleanFormatter(principal.principalAnyOffense) }}
                                                    </div>
                                                    <label>Has the applicant been convicted of any offense in any
                                                        jurisdiction?</label>
                                                    {{ principal.principalAnyOffenseExplanation }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="bgCheckAnswerCol">
                                                    <div class="bgCheckAnswer">
                                                        {{ booleanFormatter(principal.principalGamblingOffense) }}
                                                    </div>
                                                    <label>Has the applicant been convicted of any offense involving
                                                        gambling?</label>
                                                    {{ principal.principalGamblingOffenseExplanation }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="bgCheckAnswerCol">
                                                    <div class="bgCheckAnswer">
                                                        {{ booleanFormatter(principal.principalLotteryRuleViolation) }}
                                                    </div>
                                                    <label>Has the applicant been found to violate any rule, regulation or order of
                                                        the Ohio Lottery Commission?</label>
                                                    {{ principal.principalLotteryRuleViolationExplanation }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="bgCheckAnswerCol">
                                                    <div class="bgCheckAnswer">
                                                        {{ booleanFormatter(principal.principalFraudOffense) }}
                                                    </div>
                                                    <label>Has the applicant been found guilty or subjected to disciplinary action,
                                                        by any agency, court or authority of any kind, for an offense involving
                                                        fraud or misrepresentation?</label>
                                                    {{ principal.principalFraudOffenseExplanation }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="bgCheckAnswerCol">
                                                    <div class="bgCheckAnswer">
                                                        {{ booleanFormatter(principal.principalLicensePermit) }}
                                                    </div>
                                                    <label>Has the applicant had any state license or permit revoked or
                                                        suspended?</label>
                                                    {{ principal.principalLicensePermitExplanation }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="bgCheckAnswerCol">
                                                    <div class="bgCheckAnswer">
                                                        {{ booleanFormatter(principal.principalPastFinancialInterest) }}
                                                    </div>
                                                    <label>Has the applicant ever had any financial interest in a location that had
                                                        an Ohio Lottery license?</label>
                                                    {{ principal.principalPastFinancialInterestExplanation }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="bgCheckAnswerCol">
                                                    <div class="bgCheckAnswer">
                                                        {{ booleanFormatter(principal.principalCurrentFinancialInterest) }}
                                                    </div>
                                                    <label>Does the applicant currently have any financial interest in a location
                                                        that has an Ohio Lottery license?</label>
                                                    {{ principal.principalCurrentFinancialInterestExplanation }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="bgCheckAnswerCol">
                                                    <div class="bgCheckAnswer">
                                                        {{ booleanFormatter(principal.principalAffairs) }}
                                                    </div>
                                                    <label>Does any other person or entity other than the applicant, its employees,
                                                        or family participate in the management of applicant's affairs? </label>
                                                    {{ principal.principalAffairsExplanation }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="bgCheckAnswerCol">
                                                    <div class="bgCheckAnswer">
                                                        {{ booleanFormatter(principal.principalIsVendor) }}
                                                    </div>
                                                    <label>Are you a vendor, employee or agent of any vendor of the Ohio Lottery
                                                        Commission? </label>
                                                    {{ principal.principalIsVendorExplanation }}
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Federal Status:</label>
                                    </td>
                                    <td>
                                        <select v-model="principal.principalFBIBackgroundCheckStatus" id="FBIStatus"
                                            class="bgStatusSelect">
                                            <option value="null">Select...</option>
                                            <option value="0">Processing</option>
                                            <option value="1">Outstanding</option>
                                            <option value="2">Approved</option>
                                            <option value="-1">Rejected</option>
                                        </select>
                                    </td>
                                    <td>
                                        <label>Federal Received Date:</label>
                                    </td>
                                    <td>
                                        <datepicker class="check-datepicker" :inline="true" name="FBIReceivedDate"
                                            :format="customFormatter" v-model="principal.principalFBIReceivedDate"
                                            :disabledDates="disabledDates">
                                        </datepicker>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>BCI Status:</label>
                                    </td>
                                    <td>
                                        <select v-model="principal.principalBCIBackgroundCheckStatus" id="BCIStatus"
                                            class="bgStatusSelect">
                                            <option value="null">Select...</option>
                                            <option value="0">Processing</option>
                                            <option value="1">Outstanding</option>
                                            <option value="2">Approved</option>
                                            <option value="-1">Rejected</option>
                                        </select>
                                    </td>
                                    <td>
                                        <label>BCI Received Date:</label>
                                    </td>
                                    <td>
                                        <datepicker class="check-datepicker" :inline="true" name="BCIReceivedDate"
                                            :format="customFormatter" v-model="principal.principalBCIReceivedDate"
                                            :disabledDates="disabledDates">
                                        </datepicker>
                                    </td>
                                </tr>
                                <tr class="dividerRow">
                                    <td colspan="2">
                                        <label>Documentation:
                                            <span v-if="principal.principalSecurityAssociatedFile === '' || principal.principalSecurityAssociatedFile === null || principal.principalSecurityAssociatedFile === undefined">
                                                &nbsp;
                                            </span>
                                            <a v-else
                                                @click="downloadFile(index)">Click Here to View</a>
                                        </label>
                                        <!-- <input type="file" id=""
                                            @input="event => handleFile(event, index)" /> -->

                                        <span>Upload File <span v-if="principal.principalSecurityAssociatedFile != ''"> or Change existing one </span></span>

                                        <VueFileAgent 
                                        
                                            :ref="`vueFileAgent${index}`"
                                            :multiple="false"
                                            :deletable="false"
                                            :meta="true" :linkable="true"
                                            :accept="'.pdf,.doc,.docx,.ppt,.pptx,.gif,.jpg,.jpeg,.png,.zip'" :theme="'list'"
                                            :maxSize="'5MB'" :maxFiles="1" :helpText="'Choose file'" :errorText="{
                                                type: 'Invalid file type. Allowed extensions: pdf, doc, docx, ppt, pptx, gif, jpg, jpeg, png, zip.',
                                                size: 'Files should not exceed 5mb in size'
                                            }" @select="handleFile($event, principal.principalInformationUpdateID)">
                                        </VueFileAgent>
                                    </td>
                                    <td colspan="2" style="padding-top: 34px;">
                                        <label>Approval/Denial Notes:</label>
                                        <textarea v-model="principal.principalSecurityNotes" type="text" id="NotesPerPrincipal"
                                            class="bgCheckNotes"></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-else class="eAppListTbl eAppBGcheckTble">
                            <tbody>
                                <tr class="firstRow">
                                    <th>
                                        <label><strong>Principal Name</strong></label>
                                    </th>
                                    <th>
                                        <label><strong>Ownership &percnt;</strong></label>
                                    </th>
                                    <th>
                                        <label><strong>Email</strong></label>
                                    </th>
                                </tr>
                                <tr v-for="(principal, index) in principals" :key="index">
                                    <td style="padding-left:0px">
                                        <strong>OLD: </strong>{{ principal.oldName }}<br>
                                        <strong>NEW: </strong>{{ principal.newName }}
                                    </td>
                                    <td style="padding-left:0px">
                                        <strong>OLD: </strong>{{ principal.oldOwnership }}%<br>
                                        <strong>NEW: </strong>{{ principal.newOwnership }}%
                                    </td>
                                    <td style="padding-left:0px">
                                        <strong>OLD: </strong>{{ principal.oldEmail }}<br>
                                        <strong>NEW: </strong>{{ principal.newEmail }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- general principal status for non-Security roles -->
                    <div v-else-if="userRole.codename !== 'eApp-CentralLicensingManager'">
                        <ul class="principalList">
                            <li v-for="(principal, index) in updateWrapper.principalGroupUpdate.principalUpdates" v-bind:key="index" class="principalItem cf">
                                <div class="principalInfo">
                                    <span class="icon-lock"></span>
                                    <span class="principalName" v-if="principal.principalFirstName !== '' && principal.principalFirstName !== null && principal.principalLastName !== '' && principal.principalLastName !== null">
                                        {{ principal.principalFirstName }} {{ principal.principalLastName }}
                                    </span>
                                    <span class="principalName" v-else>
                                        {{ principal.principalFullName }}
                                    </span>
                                </div>
                                <div class="principalBtns">
                                    <div>
                                        Status: <strong>
                                            <span v-if="principal.principalBackgroundCheckProcessed">Reviewed</span>
                                            <span v-else="principal.principalAuthorizedBackgroundCheck">Recieved</span>
                                        </strong>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- Areas for Notes by specific role -->
                    <div v-if="userRole == 'eApp-RegionalManager' || userRole == 'eAppDistrictRep' || userRole == 'eApp-CentralSalesManager'"
                        class="moduleContent cf reviewNotes appReview">
                        <h3>Notes</h3>
                        <textarea v-model="application.backgroundCheckNotes" class="reviewNotes "></textarea>

                    </div>

                    <div v-if="userRole.codename !== 'eApp-CentralLicensingManager'" class="btn-actions">
                        <a @click="saveBgCheck()" name="Save" id="bgCheckSave"
                            class="btn btn-primary btnSave" title="Save the principal updates">Save</a>
                            
                        <a @click="markAsReviewed()" name="MarkAsReviewed" id="bgMarkAsReviewed"
                            class="button" title="Save the principal updates">Mark As Reviewed</a>
                    </div>
                    <div v-else>
                        <div v-if="statusAppUpdate == 1" class="btn-actions">
                            <!-- Empty Div -->
                        </div>
                        <div class="licensing-btn-actions">
                            <a @click="rejectClick()" class="button" title="Reject">Reject</a>
                            
                            <a @click="approveClick()"  class="button" title="Approve">Approve</a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import moment from "moment";
import Datepicker from "vue-moment-datepicker";
import { HollowDotsSpinner } from 'epic-spinners';
import SignInRegister from '/ViewComponents/SignInRegister/SignInRegister.vue';
import UpdatePrincipals from '/Views/Eapplication/updates/update-principals.vue';

export default {
    components: {
        HollowDotsSpinner,
        SignInRegister,
        UpdatePrincipals,
        Datepicker
    },
    data() {
        return {
            statusAppUpdate: '',
            isUserLogged: false,
            updateGUID: '', // primary identifier            
            updateWrapper: {}, // JSON for entire Update
            principalsBgCheck: [],
            principalsPercentChange: [],
            principalsAddedRemoved: [],
            userRole: '',
            isUserAllowed: false, // proper role and identified on the Update
            isLoading: false,
            disabledDates: {
                from: new Date()
            },
            updateFound: false,
            userId: 0,
            agentId: '0',
            token: '',
            readOnly: false,
            eftUpdate: {},
            showEftUpdate: false,
            hasRenewal: false,
            renewal: {},
            principals: [],
            showReviewButton: false,
            showRejectButton: false,
            showApproveButton: false,
            showSubmitButton: false,
            reviewMessage: '',
            failedIrs: false,
            failedState: false,
            failedDBA: false,
            failedBank: false,
            failedPayment: false,
            failedSignature: false,
            showReviewButton: false,
            showRejectRMButton: false,
            showRejectLicButton: false,
            securityRole: ['eApp-Security'],
            applicantRole: ['eApp-Retailer'],
            licensingManagerRole: ['eApp-CentralLicensingManager'],
            showApplicationUpdatesHome: false,
            applicationUpdatesHomeRoles: ['eApp-Retailer', 'eAppPrincipal'],
            showApplicationsHome: false,
            applicationsHomeRoles: ['eApp-Retailer', 'eApp-ChainApplicant'],
            showApplicationDashboard: false,
            applicationDashboardRoles: ['eApp-CentralLicensingManager', 'eApp-CentralSalesManager', 'eAppDistrictRep', 'eApp-EFTReviewer', 'eApp-RegionalManager', 'eApp-Security'],
            showChainApplicationDashboard: false,
            chainApplicationDashboardRoles: ['eApp-CentralLicensingManager', 'eApp-CentralSalesManager', 'eApp-EFTReviewer', 'eApp-Security','eApp-ChainSalesRep', 'eApp-RegionalManager']
        }
    },
    mounted: function () {
        $(document).foundation();

        this.isUserLogged = this.isUserAuthed();

        if (this.isUserLogged) {
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getContent();
                });
        }

        // get the user ID
        this.userId = this.getLoggedInUserId();
        // this.userId = 131; // Licensing
        // this.userId = 136; // Security
        this.showButtons();

        // get the agent number
        this.agentId = this.getURLQuery('an');
        this.isLoading = false;

        // get app status
        this.getAppStatus()

    },
    computed: {
        GET_UPDATE_WRAPPER_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/user/' + this.userId + '/reviewupdate/' + this.updateGUID;
        },
        GET_LICENSE_DATA_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Retailer/LicenseDetail/' + this.agentId;
        },
        SAVE_UPDATE(){
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/UpdateSecurityPrincipalInformation/?userId='+ this.userId;
        },
        MARK_AS_REVIEWED(){
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/ReviewUpdatePrincipalGroup';
        },
        APPROVE_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/ApproveUpdatePrincipalGroup';
        },
        REJECT_TO_APPLICANT_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/RejectUpdatePrincipalGroupToApplicant';
        }


    },
    methods: {
        showDetails(principalId) {
            let divId = 'principal' + principalId;
            let div = document.getElementById(divId);
            var style = window.getComputedStyle(div);
            let hidden = style.display === 'none';
            if (hidden) {
                div.style.display = 'contents';
            }
            else {
                div.style.display = 'none';
            }
        },
        
        customFormatter(date) {
            let momentum = moment(date).format('L');
            return momentum;
        },
        booleanFormatter(boolean) {
            if (boolean == '0') {
                return 'No';
            }
            else if (boolean == '1') {
                return 'Yes';
            }
            else {
                return '';
            }
        },
        getContent() {
            this.isLoading = true;
            this.getUpdateWrapper();
        },
        showButtons() {
            this.applicationDashboardRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationDashboard = true;
                }
            });

            this.chainApplicationDashboardRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showChainApplicationDashboard = true;
                }
            });

            this.applicationUpdatesHomeRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationUpdatesHome = true;
                }
            });

            this.applicationsHomeRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationsHome = true;
                }
            });
            this.securityRole.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showReviewButton = true;
                    this.showRejectButton = false;
                    this.showApproveButton = false;
                    this.showSubmitButton = false;
                }
            });
            this.licensingManagerRole.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showReviewButton = false;
                    this.showRejectButton = true;
                    this.showApproveButton = true;
                    this.showSubmitButton = false;
                }
            });
            this.applicantRole.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showReviewButton = false;
                    this.showRejectButton = false;
                    this.showApproveButton = false;
                    this.showSubmitButton = true;
                }
            });
        },
        getUpdateWrapper() {
            if (this.testMode) {
                this.setTestData()
                    .then((updateData) => {
                        this.updateWrapper = updateData;
                        this.parseUpdate();
                    });
            } else {
                // get the wrapper GUID from the querystring 'updateId'
                // to be used to populate the updateWrapper
                this.updateGUID = this.getURLQuery('updateId');

                if (this.updateGUID == '') {
                    this.updateFound = false;
                } else {
                    let uId = this.getLoggedInUserId();
                    if (uId) {
                        // this.userId = uId;
                        // this.userId = 131;
                        this.fetchData();
                    }
                }
            }

        },
        downloadFile(index) {
            var _this = this;

            var binaryString = window.atob(_this.updateWrapper.principalGroupUpdate.principalUpdates[index].principalSecurityAssociatedFile);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            var blob = new Blob([bytes], { type: _this.updateWrapper.principalGroupUpdate.principalUpdates[index].principalSecurityAssociatedFileExtension });
            var objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
        },
        handleFile(event, principalId) {
            // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
            var _this = this;
            var file = event[0].file;
            var fileName = event[0].file.name;
            var fileType = event[0].file.type
            var principal = _this.updateWrapper.principalGroupUpdate.principalUpdates.find((e) => { return e.principalInformationUpdateID == principalId; });
            var index = _this.updateWrapper.principalGroupUpdate.principalUpdates.indexOf(principal);

            const reader = new FileReader();
            reader.onload = function (e) {
                _this.updateWrapper.principalGroupUpdate.principalUpdates[index].principalSecurityAssociatedFile = e.target.result.split(',')[1];
                _this.updateWrapper.principalGroupUpdate.principalUpdates[index].principalSecurityAssociatedFileExtension = fileType;
                _this.updateWrapper.principalGroupUpdate.principalUpdates[index].principalSecurityAssociatedFileName = fileName;
            };

            reader.readAsDataURL(file);
        },
        async fetchData() {
            const appsRequestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
            };

            this.updateWrapper = await (await fetch(this.GET_UPDATE_WRAPPER_API_URL, appsRequestOptions)).json();

            if (this.updateWrapper) {
                this.updateFound = true;
                this.isLoading = false;

                this.principalsBgCheck = this.updateWrapper.principalGroupUpdate.principalUpdates.filter(obj =>{
                    return obj.principalOperation === 0;
                })

                // console.log(this.principalsBgCheck)

                this.principalsAddedRemoved = this.updateWrapper.principalGroupUpdate.principalUpdates.filter(obj =>{
                    return (obj.principalOperation === 0 || obj.principalOperation === 2);
                })

                // console.log(this.principalsAddedRemoved)

                this.principalsPercentChange = this.updateWrapper.principalGroupUpdate.principalUpdates.filter(obj =>{
                    return obj.principalOperation === 1;
                })

                // console.log(this.principalsPercentChange)

            } else {
                // using "hasRenewalUpdates" because we don't need to even see this form if there is not an update
                this.updateFound = false;
            }
            if (this.userRole = "eApp-CentralLicensingManager") {
                this.getOldPrincipalData();
            }
            this.parseUpdate();
            this.userRole = this.getEappRole();
        },
        async getOldPrincipalData() {
            const appsRequestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
            };

            this.licenseData = await (await fetch(this.GET_LICENSE_DATA_URL, appsRequestOptions)).json();

            let oldPrincipals = this.licenseData.principals,
                newPrincipals = this.updateWrapper.principalGroupUpdate.principalUpdates,
                principals = [];



            for (let i = 0; i < newPrincipals.length; i++) {
                let newPrincipal = newPrincipals[i];

                for (let w = 0; w < oldPrincipals.length; w++) {
                    let oldPrincipal = oldPrincipals[w];

                    if (newPrincipal.principal_IAD_RowID === oldPrincipal.principal_IAD_RowID) {
                        principals.push({
                            oldName: oldPrincipal.principalName,
                            newName: newPrincipal.principalFullName,
                            oldOwnership: oldPrincipal.principalPercentOwnership,
                            newOwnership: newPrincipal.principalPercentOwnership,
                            oldEmail: oldPrincipal.principalEmail,
                            newEmail: newPrincipal.principalEmail
                        });
                    }
                }
            }

            this.principals = principals;
        },
        parseUpdate() {
            this.agentId = this.updateWrapper.agentNumber;

            // is renewal?
            this.hasRenewal = this.updateWrapper.hasRenewal;

            //principals
            if (this.updateWrapper.principalGroupUpdate !== null) {
                this.principalGroupUpdate = this.updateWrapper.principalGroupUpdate;
            }
            else {
                this.principalGroupUpdate = {};
                this.principalGroupUpdate.principalUpdates = [];
            }

            this.isLoading = false;
        },
        async saveBgCheck(){
            this.isLoading = true;
            let body = {};
            this.updateWrapper.principalGroupUpdate = this.principalGroupUpdate;
            body.updateWrapper = this.updateWrapper;
            body.userId = this.userId;

            // console.log(body.updateWrapper)


            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body.updateWrapper
                )
            };

            await (await fetch(this.SAVE_UPDATE, appsRequestOptions)).json();
            this.isLoading = false;

            return Promise.resolve();
        },
        async markAsReviewed(){
            this.isLoading = true;
            let body = {};
            
            body.userId = this.userId;
            body.updateWrapperGuid = this.getURLQuery('updateId');
            body.message = '';

            // console.log(body)


            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            await (await fetch(this.MARK_AS_REVIEWED, appsRequestOptions)).json();

            let updateDashboard = "/eapplications/dashboard/regular?appType=updates&status=pending"
                window.location.href = updateDashboard;

            return Promise.resolve();
        },
        async approveClick() {
            this.isLoading = true;
            let body = {};
            body.updateWrapperGuid = this.updateGUID;
            body.userId = this.userId;

            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            await (await fetch(this.APPROVE_API_URL, appsRequestOptions)).json();

            window.location.href = "/eapplications/dashboard/regular"

            return Promise.resolve();
        },
        async rejectClick() {
            this.isLoading = true;
            let body = {};
            body.updateWrapperGuid = this.updateGUID;
            body.userId = this.userId;

            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            await (await fetch(this.REJECT_TO_APPLICANT_API_URL, appsRequestOptions)).json();

            window.location.href = "/eapplications/dashboard/regular"

            return Promise.resolve();
        },
        
        tryGetFromQueryString() {
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);

            if (urlParams.has('updateWrapperGuid')) {
                this.updateGUID = urlParams.get('updateWrapperGuid');
            }
        },

        getAppStatus(){
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);

            if (urlParams.has('status')) {
                this.statusAppUpdate = urlParams.get('status');
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.btn-actions{
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    gap: 20px;
}

.licensing-btn-actions{
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 20px;
}

</style>