<template>
    <div class="grid-container story-detail">
        <div class="storyContent content grid-x">
            <div class="responsive_video cell" 
                    v-if="videoUrl">
                <iframe 
                    :src="`https://www.youtube.com/embed/${videoUrl}`" 
                    frameborder="0" 
                    :title="storyTitle"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen>
                </iframe>
            </div>

            <div class="responsive_img" :class="{ 'medium-6': photoPathUrl }" v-if="photoPathUrl">
                <img :src="photoPathUrl" :title="storyTitle" />
            </div>

            <div class="contentRow cell" :class="{ 'medium-6': photoPathUrl }">
                <div class="row">
                    <div>
                        <h1>
                            <span>{{storyTitle}}</span>
                        </h1>
                        <div class="storyUser">by <span class="userName">
                            <span>{{ firstName }} </span>
                            <span>{{ lastName }}</span>
                        </span>
                        </div>
                        <div class="storyText">
                            <span>{{ storyText}}</span>
                        </div>
                    </div>
                    <div class="storyLocation col-sm-6">
                        <span class="icon-map-marker icon"></span>
                        <span>{{city}}</span>, <span>{{state}}</span>
                    </div>
                </div>
                
                <div class="footBtns">
                    <a href="/mylotto-rewards/winners" class="button">See More Stories</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        components: {
            
        },
        data: function () {
            return {    
                firstName: "",
                lastName: "",
                city: "",
                state: "",
                storyTitle: "",
                storyText: "",
                videoUrl: "",
                titleCardPath: "",
                titleCardPathUrl: "",
                photoPath: "",
                photoPathUrl: ""
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(apiToken) {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });

                let guid = params.guid;

                return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/WinnerStories/GetWinnerStoriesByGuid/'+guid, {
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    const results = json.data;
                    // console.log(results)
                    this.firstName = results.firstName;
                    this.lastName = results.lastName;
                    
                    this.photoPath = results.photoPath;
                    this.photoPathUrl = results.photoPathUrl;

                    this.city = results.city;
                    this.state = results.state;
                    this.storyTitle = results.storyTitle;
                    this.storyTitle = results.storyTitle;
                    this.storyText = results.storyText;
                    this.videoUrl = results.videoUrl;
                    this.titleCardPath = results.titleCardPath;
                    this.titleCardPathUrl = results.titleCardPathUrl;

                    const youtube_regex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|shorts\/|\?vi?=|\&vi?=)([^#\&\?]*).*/;
                    const parsed = results.videoUrl.match(youtube_regex);

                    if(this.videoUrl){
                        
                        if (parsed && parsed[2]) {
                            this.videoUrl = parsed[2];
                        } else {
                            console.error(url, parsed);
                        }
                    }


                })
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss">

</style>