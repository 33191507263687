<template>
    <div class="recent_numbers_wrap winningNumbersMenu">
        <div v-if="loading">Loading Numbers...</div>
        <ul id="recent_numbers_list" class="winningNumbers cf winNumSlider">
            <li v-for="game in games" :class="game.StringIdentifier + ' item_alt winningNumbersItem cf'" :key="game.StringIdentifier">
                <!-- <div>{{game.name}}</div> -->
                <a class="winningNumbersLogo" :href="'/games/draw-games/' + game.NodeAliasPath">
                    {{game.Name}}
                </a>
                <div class="winningNumbersContent">
                    <span class="date">
                        {{new Date(game.Draws[0].DrawDate) | moment("MM/DD/YYYY")}}
                    </span>
                    <div v-if="game.StringIdentifier === 'Pick3' || game.StringIdentifier === 'Pick4' || game.StringIdentifier === 'Pick5'">

                        <span v-if="pick3 && game.StringIdentifier === 'Pick3'" class="time">MIDDAY</span>
                        <span v-if="pick4 && game.StringIdentifier === 'Pick4'" class="time">MIDDAY</span>
                        <span v-if="pick5 && game.StringIdentifier === 'Pick5'" class="time">MIDDAY</span>

                        <ul v-if="pick3 && game.StringIdentifier === 'Pick3'" class="balls">
                            <li v-for="number in game.Draws[1].Numbers.slice(0, 5)" :key="number.position">{{number.Value}}</li>
                        </ul>

                        <ul v-if="pick4 && game.StringIdentifier === 'Pick4'" class="balls">
                            <li v-for="number in game.Draws[1].Numbers.slice(0, 5)" :key="number.position">{{number.Value}}</li>
                        </ul>

                        <ul v-if="pick5 && game.StringIdentifier === 'Pick5'" class="balls">
                            <li v-for="number in game.Draws[1].Numbers.slice(0, 5)" :key="number.position">{{number.Value}}</li>
                        </ul>
                    </div>


                    <span v-if="game.StringIdentifier === 'Pick3' || game.StringIdentifier === 'Pick4' || game.StringIdentifier === 'Pick5'">
                        <span v-if="game.StringIdentifier === 'Pick3' && pick3" class="time">EVENING</span>
                        <span v-if="game.StringIdentifier === 'Pick4' && pick4" class="time">EVENING</span>
                        <span v-if="game.StringIdentifier === 'Pick5' && pick5" class="time">EVENING</span>

                        <span v-if="game.StringIdentifier === 'Pick3' && !pick3" class="time">MIDDAY</span>
                        <span v-if="game.StringIdentifier === 'Pick4' && !pick4" class="time">MIDDAY</span>
                        <span v-if="game.StringIdentifier === 'Pick5' && !pick5" class="time">MIDDAY</span>
                    </span>
                    <ul class="balls">
                        <li v-for="number in game.Draws[0].Numbers.slice(0, 5)" :key="number.position">{{number.Value}}</li>
                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'megamil'" class="yellow">
                            {{game.Draws[0].Numbers[5].Value}}
                        </li>
                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'pball'" class="red">
                            {{game.Draws[0].Numbers[5].Value}}
                        </li>
                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'luckylife'" class="yellow">
                            {{game.Draws[0].Numbers[5].Value}}
                        </li>
                        <li v-if="game.Draws[0].Numbers.length > 5 && game.StringIdentifier === 'ClassicLotto'">
                            {{game.Draws[0].Numbers[5].Value}}
                        </li>
                        <li v-if="game.Draws[0].Numbers.length > 6" class="x" :style="{display: MegiplierStatus==='false' && game.StringIdentifier === 'megamil' ? 'none' : 'inline-block'}">
                            {{game.Draws[0].Numbers[6].Value}}
                        </li>
                    </ul>
                    <span v-if="game.StringIdentifier === 'ClassicLotto'" class="kickerWin">
                        <span class="wnLbl">KICKER: </span>
                        <span class="wnVal" v-if="game.Draws[0].ExtendedNumbers.length > 0">
                            {{ game.Draws[0].ExtendedNumbers[0].Value }}{{ game.Draws[0].ExtendedNumbers[1].Value }}{{ game.Draws[0].ExtendedNumbers[2].Value }}{{ game.Draws[0].ExtendedNumbers[3].Value }}{{ game.Draws[0].ExtendedNumbers[4].Value }}{{ game.Draws[0].ExtendedNumbers[5].Value }}
                        </span>
                    </span>
                </div>
                
            </li>
        </ul>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

    export default {
        data: function () {
            return {
                token: '',
                games: '',
                pick3: false,
                pick4: false,
                pick5: false,
                loading: true,
                MegiplierStatus: this.getMegiplierStatus(),
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                })
                .then(() => {
                    this.getNumbers();
                });
            },
            getNumbers() {
                // let games = JSON.parse(localStorage.getItem('olcDrawNumbers'));
                let games = document.getElementById('cmsNumbers').value;
                // games = JSON.parse(games);

                // this.checkLocalNumbers(this.token)
                // .then(() => {
                    let _this = this;
                    // setTimeout(() => {
                        // games = JSON.parse(localStorage.getItem('olcDrawNumbers'));
                        // games = document.getElementById('cmsNumbers').value;
                        games = JSON.parse(games);
                        this.games = games;
                        _this.setNumbers(games);
                    // }, 4000);
                // })
            },
            setNumbers(g) {
                let games = [ ...g];

                for (let i = 0; i < games.length; i++) {
                    let game = games[i],
                        draw1 = game.Draws[0],
                        draw2 = game.Draws[1];

                    if (game.StringIdentifier === 'megamil') {
                        game.sortOrder = 1;
                    }

                    if (game.StringIdentifier === 'pball') {
                        game.sortOrder = 2;
                    }

                    if (game.StringIdentifier === 'luckylife') {
                        game.sortOrder = 3;
                    }

                    if (game.StringIdentifier === 'rollingcash5') {
                        game.sortOrder = 4;
                    }

                    if (game.StringIdentifier === 'ClassicLotto') {
                        game.sortOrder = 5;
                    }                    

                    if (game.StringIdentifier === 'Pick3') {
                        let draw1Date = new Date(draw1.DrawDate),
                            draw2Date = new Date(draw2.DrawDate);

                        game.sortOrder = 6;

                        if (draw1Date.getYear() === draw2Date.getYear() && draw1Date.getDay() === draw2Date.getDay() && draw1Date.getMonth() === draw2Date.getMonth()) {
                            this.pick3 = true
                        } else {
                            this.pick3 = false;
                        }
                    }

                    if (game.StringIdentifier === 'Pick4') {
                        let draw1Date = new Date(draw1.DrawDate),
                            draw2Date = new Date(draw2.DrawDate);

                            game.sortOrder = 7;

                        if (draw1Date.getYear() === draw2Date.getYear() && draw1Date.getDay() === draw2Date.getDay() && draw1Date.getMonth() === draw2Date.getMonth()) {
                            this.pick4 = true
                        } else {
                            this.pick4 = false;
                        }
                    }

                        if (game.StringIdentifier === 'Pick5') {
                        let draw1Date = new Date(draw1.DrawDate),
                            draw2Date = new Date(draw2.DrawDate);

                            game.sortOrder = 8;

                        if (draw1Date.getYear() === draw2Date.getYear() && draw1Date.getDay() === draw2Date.getDay() && draw1Date.getMonth() === draw2Date.getMonth()) {
                            this.pick5 = true
                        } else {
                            this.pick5 = false;
                        }
                    }
                }

                games.sort((a, b) => {
                    return a.sortOrder - b.sortOrder;
                })

                this.games = games;

                this.loading = false;
                this.$forceUpdate();
            },
            ...mapActions ({
                checkLocalNumbers: 'getNumbers/getOLCDrawGames'
            }),
            ...mapGetters ({
                getGames: 'getNumbers/getGames',
                getStorageNumbers: 'getNumbers/getStorageNumbers'
            })
        }
    };
</script>
<style lang="scss" scoped>
</style>