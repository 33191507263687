<template>
    <div class="module">
        <div class="dcf_submitRetailersForm olcForm dcf_forms">
            <form data-abide novalidate data-validate-on-blur="true" id="RetailersForm">
                <fieldset class="grid-x grid-margin-x grid-margin-y" v-if="resStatusCode != 200">
                    <p class="reqNote"><span class="req">*</span> All fields required</p>
                    <div class="halfForm columns small-12 medium-6 cell">
                        <label for="txtEmailAddress">Email Address <span class="req">*</span></label>
                        <input type="text" id="txtEmailAddress" v-model="form.AdjustmentForm.EmailAddress" class="reqTxt"
                            required pattern="email" />
                        <span class="form-error error regError" data-form-error-on="pattern">Invalid email
                            address.</span>
                        <span class="form-error error regError">Please provide an email address.</span>
                    </div>

                    <div class="halfForm columns small-12 medium-6 cell show-for-large"></div>

                    <div class="halfForm columns small-12 medium-6 cell">
                        <label for="txtRetailerNumber">Retailer Number <span class="req">*</span></label>
                        <input type="text" id="txtRetailerNumber" v-model="form.AdjustmentForm.RetailerNumber"
                            class="reqTxt" required pattern="number" />
                        <span class="form-error error regError">Please provide a retailer number.</span>
                    </div>

                    <div class="halfForm columns small-12 medium-6 cell">
                        <label for="txtRetailerName">Retailer Name <span class="req">*</span></label>
                        <input type="text" id="txtRetailerName" v-model="form.AdjustmentForm.RetailerName" class="reqTxt"
                            required pattern="^[a-zA-Z0-9 ]*$" />
                        <span class="form-error error regError">Please provide a retailer name.</span>
                    </div>

                    <div class="halfForm columns small-12 medium-6 cell">
                        <label for="txtRetailerAddress">Retailer Address <span class="req">*</span></label>
                        <input type="text" id="txtRetailerAddress" v-model="form.AdjustmentForm.RetailerAddress"
                            class="reqTxt" required pattern="^[a-zA-Z0-9 ]*$" />
                        <div class="form-error error errortextOnly">Please provide text and numbers only.</div>
                    </div>

                    <div class="halfForm columns small-6 medium-3 cell">
                        <label for="txtCity">City <span class="req">*</span></label>
                        <input type="text" id="txtCity" v-model="form.AdjustmentForm.RetailerCity" class="reqTxt" required
                            pattern="^[a-zA-Z0-9 ]*$" />
                        <span class="form-error error regError">Please provide a city name.</span>
                    </div>

                    <div class="halfForm columns small-6 medium-3 cell">
                        <label for="txtZipCode">Zip Code <span class="req">*</span></label>
                        <input type="text" id="txtZipCode" minlength="5" maxlength="5"
                            v-model="form.AdjustmentForm.RetailerZip" class="reqTxt" required pattern="[0-9]{5}" />
                        <span class="form-error error zipError">Please provide five numbers only.</span>
                    </div>

                    <div class="halfForm columns small-12 medium-6 cell">
                        <label for="txtRetailerContact">Retailer Contact <span class="req">*</span></label>
                        <input type="text" id="txtRetailerContact" v-model="form.AdjustmentForm.RetailerContact"
                            class="reqTxt" required pattern="^[a-zA-Z0-9 ]*$" />
                        <span class="form-error error regError">Please provide a retailer contact.</span>
                    </div>

                    <div class="halfForm columns small-12 medium-6 cell">
                        <label for="txtPhone">Phone Number <span class="req">*</span></label>
                        <input type="text" id="txtPhone" v-model="form.AdjustmentForm.Phone" class="reqTxt" required
                            pattern="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$" @change="phoneFormat" placeholder="Ex. (555) 555 5555" />
                        <span class="form-error error regError">Please provide a phone number: Ex. ### ### ####</span>
                    </div>

                    <div class="halfForm columns small-12 medium-6 cell">
                        <label for="txtCallCenterName">Call Center Name <span class="req">*</span></label>
                        <input type="text" id="txtCallCenterName" v-model="form.AdjustmentForm.CallCenterContact"
                            class="reqTxt" required pattern="^[a-zA-Z0-9 ]*$" />
                        <span class="form-error error regError">Please provide a call center name.</span>
                    </div>

                    <div class="halfForm columns small-6 medium-3 cell">
                        <label for="txtDateOfContact">Date of Contact <span class="req">*</span></label>
                        <datepicker :disabledDates="state.disabledDates" placeholder="Select Date" name="inputArchiveDate"
                            id="txtDateOfContact" v-model="form.AdjustmentForm.ContactDate" required></datepicker>
                        <!-- <input type="text" class="reqTxt"  /> -->
                        <span class="form-error error regError">Please provide a date of contact.</span>
                    </div>

                    <div class="halfForm columns small-6 medium-3 cell">
                        <label for="txtTimeOfContact">Time of Contact <span class="req">*</span></label>
                        <!-- <input type="time" id="txtTimeOfContact" v-model="form.AdjustmentForm.ContactTime"
                            @blur="timeFormat" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]$" required /> -->
                            <select name="" id="txtTimeOfContact" v-model="form.AdjustmentForm.ContactTime" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]$" required>
                                <option disabled value="">Select time</option>
                                <option value="12:00 AM">12:00 AM</option>
                                <option value="12:15 AM">12:15 AM</option>
                                <option value="12:30 AM">12:30 AM</option>
                                <option value="12:45 AM">12:45 AM</option>
                                <option value="1:00 AM">1:00 AM</option>
                                <option value="1:15 AM">1:15 AM</option>
                                <option value="1:30 AM">1:30 AM</option>
                                <option value="1:45 AM">1:45 AM</option>
                                <option value="2:00 AM">2:00 AM</option>
                                <option value="2:15 AM">2:15 AM</option>
                                <option value="2:30 AM">2:30 AM</option>
                                <option value="2:45 AM">2:45 AM</option>
                                <option value="3:00 AM">3:00 AM</option>
                                <option value="3:15 AM">3:15 AM</option>
                                <option value="3:30 AM">3:30 AM</option>
                                <option value="3:45 AM">3:45 AM</option>
                                <option value="4:00 AM">4:00 AM</option>
                                <option value="4:15 AM">4:15 AM</option>
                                <option value="4:30 AM">4:30 AM</option>
                                <option value="4:45 AM">4:45 AM</option>
                                <option value="5:00 AM">5:00 AM</option>
                                <option value="5:15 AM">5:15 AM</option>
                                <option value="5:30 AM">5:30 AM</option>
                                <option value="5:45 AM">5:45 AM</option>
                                <option value="6:00 AM">6:00 AM</option>
                                <option value="6:15 AM">6:15 AM</option>
                                <option value="6:30 AM">6:30 AM</option>
                                <option value="6:45 AM">6:45 AM</option>
                                <option value="7:00 AM">7:00 AM</option>
                                <option value="7:15 AM">7:15 AM</option>
                                <option value="7:30 AM">7:30 AM</option>
                                <option value="7:45 AM">7:45 AM</option>
                                <option value="8:00 AM">8:00 AM</option>
                                <option value="8:15 AM">8:15 AM</option>
                                <option value="8:30 AM">8:30 AM</option>
                                <option value="8:45 AM">8:45 AM</option>
                                <option value="9:00 AM">9:00 AM</option>
                                <option value="9:15 AM">9:15 AM</option>
                                <option value="9:30 AM">9:30 AM</option>
                                <option value="9:45 AM">9:45 AM</option>
                                <option value="10:00 AM">10:00 AM</option>
                                <option value="10:15 AM">10:15 AM</option>
                                <option value="10:30 AM">10:30 AM</option>
                                <option value="10:45 AM">10:45 AM</option>
                                <option value="11:00 AM">11:00 AM</option>
                                <option value="11:15 AM">11:15 AM</option>
                                <option value="11:30 AM">11:30 AM</option>
                                <option value="11:45 AM">11:45 AM</option>
                                <option value="12:00 PM">12:00 PM</option>
                                <option value="12:15 PM">12:15 PM</option>
                                <option value="12:30 PM">12:30 PM</option>
                                <option value="12:45 PM">12:45 PM</option>
                                <option value="1:00 PM">1:00 PM</option>
                                <option value="1:15 PM">1:15 PM</option>
                                <option value="1:30 PM">1:30 PM</option>
                                <option value="1:45 PM">1:45 PM</option>
                                <option value="2:00 PM">2:00 PM</option>
                                <option value="2:15 PM">2:15 PM</option>
                                <option value="2:30 PM">2:30 PM</option>
                                <option value="2:45 PM">2:45 PM</option>
                                <option value="3:00 PM">3:00 PM</option>
                                <option value="3:15 PM">3:15 PM</option>
                                <option value="3:30 PM">3:30 PM</option>
                                <option value="3:45 PM">3:45 PM</option>
                                <option value="4:00 PM">4:00 PM</option>
                                <option value="4:15 PM">4:15 PM</option>
                                <option value="4:30 PM">4:30 PM</option>
                                <option value="4:45 PM">4:45 PM</option>
                                <option value="5:00 PM">5:00 PM</option>
                                <option value="5:15 PM">5:15 PM</option>
                                <option value="5:30 PM">5:30 PM</option>
                                <option value="5:45 PM">5:45 PM</option>
                                <option value="6:00 PM">6:00 PM</option>
                                <option value="6:15 PM">6:15 PM</option>
                                <option value="6:30 PM">6:30 PM</option>
                                <option value="6:45 PM">6:45 PM</option>
                                <option value="7:00 PM">7:00 PM</option>
                                <option value="7:15 PM">7:15 PM</option>
                                <option value="7:30 PM">7:30 PM</option>
                                <option value="7:45 PM">7:45 PM</option>
                                <option value="8:00 PM">8:00 PM</option>
                                <option value="8:15 PM">8:15 PM</option>
                                <option value="8:30 PM">8:30 PM</option>
                                <option value="8:45 PM">8:45 PM</option>
                                <option value="9:00 PM">9:00 PM</option>
                                <option value="9:15 PM">9:15 PM</option>
                                <option value="9:30 PM">9:30 PM</option>
                                <option value="9:45 PM">9:45 PM</option>
                                <option value="10:00 PM">10:00 PM</option>
                                <option value="10:15 PM">10:15 PM</option>
                                <option value="10:30 PM">10:30 PM</option>
                                <option value="10:45 PM">10:45 PM</option>
                                <option value="11:00 PM">11:00 PM</option>
                                <option value="11:15 PM">11:15 PM</option>
                                <option value="11:30 PM">11:30 PM</option>
                                <option value="11:45 PM">11:45 PM</option>
                            </select>
                        <span class="form-error error regError">Please provide a time of contact.</span>
                    </div>

                    <div class="halfForm columns small-12 medium-6 cell">
                        <label for="txtGameType">Game Type <span class="req">*</span></label>
                        <input type="text" id="txtGameType" v-model="form.AdjustmentForm.GameType" class="reqTxt" required
                            pattern="^[a-zA-Z0-9 ]*$" />
                        <span class="form-error error regError">Please provide Game type.</span>
                    </div>

                    <div class="halfForm columns small-12 medium-6 cell">
                        <label for="txtAdjustmentAmountRequested">Adjustment Amount Requested <span
                                class="req">*</span></label>
                        <div class="fieldLabel-money">
                            <span class="money-ico">$</span>
                            <input type="text" id="txtAdjustmentAmountRequested"
                                v-model="form.AdjustmentForm.AdjustmentAmount" class="reqTxt" required
                                pattern="^[0-9]*\.[0-9]{2}$" placeholder="0.00" @change="decFormat" />
                            <span class="form-error error regError">Please provide a decimal number only: Ex.
                                0.00</span>
                        </div>

                    </div>

                    <div class="fullForm radioGroup halfForm columns small-12 medium-12 cell">
                        <label>Reason for Request (please select one or use the Other field to provide an explanation of
                            the problem)</label>

                        <span class="rd">
                            <input type="radio" v-model="form.AdjustmentForm.RequestReason" name="RequestReason" id="unable"
                                value="unable" required @change="radioCheck($event)" />
                            <label for="unable"> Unable to cancel ticket(s) due to missing bar code.</label>
                        </span>

                        <span class="rd">
                            <input type="radio" v-model="form.AdjustmentForm.RequestReason" name="RequestReason"
                                id="paperJam" value="paperJam" @change="radioCheck($event)" />
                            <label for="paperJam"> Paper jam. Reprint attached.</label>
                        </span>

                        <span class="rd">
                            <input type="radio" v-model="form.AdjustmentForm.RequestReason" name="RequestReason"
                                id="terminalDisabled" value="terminalDisabled" @change="radioCheck($event)" />
                            <label for="terminalDisabled"> Terminal disabled while wager was in process; no ticket
                                issued. Reprint attached.</label>
                        </span>

                        <span class="rd">
                            <input type="radio" v-model="form.AdjustmentForm.RequestReason" name="RequestReason"
                                id="TerminalServices" value="TerminalServices" @change="radioCheck($event)" />
                            <label for="TerminalServices"> Terminal Services. Copy of service report attached.</label>
                        </span>

                        <span class="rd">
                            <input type="radio" v-model="form.AdjustmentForm.RequestReason" name="RequestReason" id="other"
                                value="other" @change="radioCheck($event)" />
                            <label for="other"> Other </label>
                            <input type="text" v-model="form.AdjustmentForm.ReasonAdditionalInfo" id="ReasonAdditionalInfo"
                                class="reqTxt" pattern="^[a-zA-Z0-9 ]*$" required disabled />
                        </span>
                    </div>

                    <div class="grid-x grid-margin-x grid-margin-y upload-content">
                        <p>ALL REQUEST MUST BE ACCOMPANIED BY THE CORRECT DOCUMENTATION.</p>

                        <div class="columns small-12 medium-6 cell select-input">
                            <label>Number of Tickets Attached <span class="req">*</span></label>
                            <select @change="selectFiles($event)">
                                <option value="1" selected>1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                            <small class="smallNote">File formats allowed: jpg, jpeg, .png, or .pdf. No larger than
                                7mb.</small>
                        </div>

                        <div class="columns small-12 medium-6 cell file-input" id="ticket-0">
                            <label>Ticket Image 1</label>
                            <input type="file" @input="form.FileOne = $event.target.files[0]" @change="validFile($event)"
                                accept="image/png, image/jpeg, application/pdf" required
                                pattern="^.+?\.(pdf|PDF|jpg|JPG|jpeg|JPEG|png|PNG)$" id="0" />
                            <div class="form-error error fileType">Provided file format is not supported.</div>
                            <div class="error fileSize">File is too large. Please upload a file less than 7mb.</div>
                        </div>

                        <div class="columns small-12 medium-6 cell file-input" id="ticket-1" v-if="showFile1">
                            <label>Ticket Image 2</label>
                            <input type="file" @input="form.FileTwo = $event.target.files[0]" @change="validFile($event)"
                                accept="image/png, image/jpeg, application/pdf"
                                pattern="^.+?\.(pdf|PDF|jpg|JPG|jpeg|JPEG|png|PNG)$" id="1" />
                            <div class="form-error error fileType">Provided file format is not supported.</div>
                            <div class="error fileSize">File is too large. Please upload a file less than 7mb.</div>
                        </div>

                        <div class="columns small-12 medium-6 cell file-input" id="ticket-2" v-if="showFile2">
                            <label>Ticket Image 3</label>
                            <input type="file" @input="form.FileThree = $event.target.files[0]" @change="validFile($event)"
                                accept="image/png, image/jpeg, application/pdf"
                                pattern="^.+?\.(pdf|PDF|jpg|JPG|jpeg|JPEG|png|PNG)$" id="2" />
                            <div class="form-error error fileType">Provided file format is not supported.</div>
                            <div class="error fileSize">File is too large. Please upload a file less than 7mb.</div>
                        </div>
                    </div>

                    <input type="hidden" id="txFormType" v-model="form.FormType" />
                    <input type="hidden" id="txUserId" v-model="form.UserId" />
                    <button type="submit" class="button btn_dcfConfirmRetailerForm" :disabled="isLoading" :class="{disabled: isLoading}">SUBMIT ADJUSTMENT REQUEST</button>
                </fieldset>

                <fieldset class="grid-x grid-margin-x" v-if="isLoading">
                    <div class="halfForm columns small-12 medium-12 cell">
                        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" />
                    </div>
                </fieldset>

                <fieldset class="grid-x grid-margin-x" v-if="resStatusCode === 200">
                    <div class="halfForm columns small-12 medium-12 cell">
                        <h2>Your form has been submitted.</h2>
                        <p>{{ resMessage }}</p>
                    </div>
                </fieldset>

            </form>
        </div>
    </div>
</template>
  
<script>
import Datepicker from "vue-moment-datepicker";
import { HollowDotsSpinner } from 'epic-spinners';

import $ from 'jquery';
export default {
    components: {
        Datepicker,
        HollowDotsSpinner
    },
    data: function () {
        return {
            form: {
                UserId: this.$cookies.get('apiToken').user.userId,
                FormType: 1,
                FileOne: '',
                FileTwo: '',
                FileThree: '',
                AdjustmentForm: {
                    EmailAddress: '',
                    RetailerNumber: '',
                    RetailerName: '',
                    RetailerAddress: '',
                    RetailerCity: '',
                    RetailerZip: '',
                    RetailerContact: '',
                    Phone: '',
                    CallCenterContact: '',
                    ContactDate: '',
                    ContactTime: '',
                    GameType: '',
                    NumberOfTickets: 1,
                    AdjustmentAmount: '',
                    RequestReason: '',
                    ReasonAdditionalInfo: '',
                    Successful: false,
                    TicketImages: [],
                    fileName: '',
                    PdfBytes: '',
                    // ItemCreatedWhen: '',
                }
            },

            showFile1: false,
            showFile2: false,
            arrValidFiles: [],
            state: {
                disabledDates: {
                    from: ''
                }
            },
            resStatusCode: null,
            resMessage: '',
            isLoading: false,
        }
    },
    mounted: function () {
        let _this = this;
        _this.getCurrentDate();

        $('#RetailersForm').foundation();

        $('#RetailersForm').on("formvalid.zf.abide", function () {
            let checker = arr => arr.every(Boolean);
            if (checker(_this.arrValidFiles)) {
                _this.getToken();
            }
        }).on("submit", function (ev) {
            ev.preventDefault();
        });

    },
    methods: {
        getCurrentDate() {
            const dateObj = new Date();
            let year = dateObj.getUTCFullYear();
            let month = dateObj.getUTCMonth() + 1; //months from 1-12
            let day = dateObj.getUTCDate();
            this.state.disabledDates.from = new Date(year, month - 1, day + 1);
        },
        validFile(e) {
            // console.log(e.target.id);
            // console.log(this.arrValidFiles[1]);
            // this.arrValidFiles[1] = true
            // console.log(this.arrValidFiles);
            if (e.target.files[0] != undefined) {
                let fileSize = e.target.files[0].size;
                let size = Math.round((fileSize / 1024));
                // console.log(size);
                if (size >= 7000) {
                    // console.log('error');
                    this.arrValidFiles[e.target.id] = false;
                    e.target.closest('.file-input').querySelector('.fileSize').style.display = "block";
                    e.target.closest('.file-input').querySelector('input').classList.add('is-invalid-size');
                } else {
                    this.arrValidFiles[e.target.id] = true;
                    e.target.closest('.file-input').querySelector('.fileSize').style.display = "none";
                    e.target.closest('.file-input').querySelector('input').classList.remove('is-invalid-size');
                }
            }
        },

        decFormat(e) {
            let val = e.target.value;
            if (val !== '') {
                let formatVal = this.decimalFormat(val);
                if (formatVal !== false) {
                    this.form.AdjustmentForm.AdjustmentAmount = formatVal;
                    // console.log(val, this.decimalFormat(val));
                }

            }
        },

        phoneFormat(e) {
            let val = e.target.value;
            let regx = new RegExp(/\s{2,}/);
            if (val !== '') {
                let formatVal = regx.test(val);

                if (formatVal !== false) {
                    let formatPhone = val.replace(/\s/g, '-')
                    this.form.AdjustmentForm.Phone = formatPhone;
                    // console.log(val, this.decimalFormat(val));
                }

            }
        },
        timeFormat(e) {
            let val = e.target.value;
            let sep = val.split(':');
            let hh = parseInt(sep[0]);
            let mm = parseInt(sep[1]);

            function between(mm, min, max) {
                return mm >= min && mm <= max;
            }

            if (val !== '') {
                if (between(mm, 0, 9)) {
                    this.form.AdjustmentForm.ContactTime = hh + ':00';
                } else if (between(mm, 10, 14)) {
                    this.form.AdjustmentForm.ContactTime = hh + ':15';
                } else if (between(mm, 15, 29)) {
                    this.form.AdjustmentForm.ContactTime = hh + ':30';
                } else if (between(mm, 30, 44)) {
                    this.form.AdjustmentForm.ContactTime = hh + ':45';
                } else if (between(mm, 45, 59)) {
                    if (hh === 23) {
                        this.form.AdjustmentForm.ContactTime = '01:00';
                    } else {
                        this.form.AdjustmentForm.ContactTime = hh + 1 + ':00';
                    }
                    // this.form.AdjustmentForm.ContactTime = +1hs;
                }
            }


        },
        radioCheck(e) {
            let val = e.target.value;
            // console.log(val);
            if (val == 'other') {
                $('#ReasonAdditionalInfo').prop('disabled', false);
            } else {
                $('#ReasonAdditionalInfo').prop('disabled', true).removeClass('is-invalid-input');
            }
        },
        selectFiles(e) {
            let val = e.target.value;
            this.form.AdjustmentForm.NumberOfTickets = val;
            // console.log(val);
            // console.log(this.form.AdjustmentForm.NumberOfTickets);
            if (val == '1') {
                this.showFile1 = false;
                this.showFile2 = false;
                document.querySelector("#ticket-1 input, #ticket-2 input").removeAttribute('required');
                document.querySelector("#ticket-1 input, #ticket-2 input").value = null;
                this.form.FileTwo = '';
                this.form.FileThree = '';
            }
            if (val == '2') {
                this.showFile1 = true;
                this.showFile2 = false;
                document.querySelector("#ticket-1 input").setAttribute('required', '');
                document.querySelector("#ticket-2 input").removeAttribute('required');
                document.querySelector("#ticket-2 input").value = null;
                this.form.FileThree = '';
            }
            if (val == '3') {
                this.showFile1 = true;
                this.showFile2 = true;
                document.querySelector("#ticket-1 input, #ticket-2 input").setAttribute('required', '');
            }
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

        },
        async fetchData(token) {
            this.isLoading = true;
            var apiToken = token;
            var urlProtocol = this.getProtocol();

            let fd = new FormData();

            fd.append('UserId', this.form.UserId);
            fd.append('FormType', this.form.FormType);
            // fd.append('FileOne', this.form.FileOne);
            // fd.append('FileTwo', this.form.FileTwo);
            // fd.append('FileThree', this.form.FileThree);

            fd.append('AdjustmentForm', JSON.stringify(this.form.AdjustmentForm));

            if (this.form.FileOne != '') {
                fd.append('FileOne', this.form.FileOne)
            }

            if (this.form.FileTwo != '') {
                fd.append('FileTwo', this.form.FileTwo)
            }

            if (this.form.FileThree != '') {
                // fd.append('name', this.form.FileThree.name)
                fd.append('FileThree', this.form.FileThree)
            }

            return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Form/SubmitAdjustmentEntry", {
                method: 'POST',
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    // 'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken,
                },
                body: fd
            })
                .then(res => res.json())
                .then(res => {

                    if (res.statusCode == 200) {
                        this.resStatusCode = res.statusCode;
                        this.resMessage = res.data;
                        document.getElementById('RetailersForm');
                    }

                    this.isLoading = false;
                })
                .catch(error => {
                    // console.log(error);
                    this.isLoading = false;
                });
        },
    },
    computed: {},
};
</script>