<template>
    <div class="progressive-jackpot-value">
        <p v-if="this.error" class="">
            There was an error fetching the jackpot value. Please try again later. 
        </p>
        <span v-else>
			Progressive Jackpot:  <span class="wnVal">${{ numberWithCommas(jackpot) }}</span> <span class="pjTime">{{ customFormatter(jackpotLastUpdatedDate)}}</span>
        </span>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        components: {
        },
        data: function() {
            return {
                familyId: document.getElementById('familyId').value,
                jackpot: '',
                jackpotLastUpdatedDate: '',
                error: false
            }
        },
        mounted: function() {
            this.getToken();
        },
        methods: {
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy h:mm A');
            },
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            getToken() {
                this.getAPItoken().then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/OnlineGames/OnlineGame/GetProgressiveGames", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let response = json.data;  

                    // console.log(response);
                    if(response == "" || response == null || response == "undefined") {
                        this.error = true;

                    } else {
                        const familyIdMatch = response.filter(x => x.familyID == this.familyId);
                        // console.log (familyIdMatch)

                        if(familyIdMatch == "" || familyIdMatch == null || familyIdMatch == "undefined") {
                            this.error = true;
                        } else {
                            this.jackpot = this.getHighestJackpot(familyIdMatch[0]);
                            this.jackpotLastUpdatedDate = familyIdMatch[0].jackpotLastUpdatedDate;
                        }
                    }

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
            },
            getHighestJackpot(game) {
                let gamesArray = game.games;
                let jackpotArray = [];
                gamesArray.forEach((element) => jackpotArray.push(element.jackpotAmount));
                jackpotArray.sort(function(a,b){
                    return b - a;
                });
                return jackpotArray[0];
            }
        }
    };
</script>


<style lang="scss" scoped>
</style>