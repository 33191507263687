<template>
    <div class="cf adminDashboards">
       
        <h3>{{ dashTitle }} Dashboard</h3>

        <SignInRegister v-if="!isUserLogged"></SignInRegister>

        <section class="module" v-if="isUserLogged">

            <div v-if="!userEappRole" class="InfoLabel alert-message cf">
                This page requires special credentials to access. Contact your administrator if you have questions.
            </div>

            <!-- Link to Chain Dashboard for Chain Roles only -->
            <a v-if="!isChainUser" class="button button-secondary eAppDashBtn" href="/eApplications/Dashboard/Regular"><span class="icon-chevron-double-left"></span>Application Dashboard</a>

            <table class="masterInfoTble">
                <tr>
                    <td><h5>{{masterInfo.businessDoingBusinessAs}}</h5></td>
                    <td><label>Created on:</label> {{masterInfo.createdOn}}</td>
                    <td class="actions"><a :href="'/eapplications/eapplication?id='+masterInfo.applicationGuid" class="button">Review</a></td>
                </tr>
                <tr>
                    <td><label>Application Number:</label>{{masterInfo.applicationNumber}}</td>
                    <td><label>City:</label> {{masterInfo.businessCity}}</td>
                    <td>
                        <a v-if="isChainSalesRep && masterInfo.isApplicationCorporateMaster && userId == masterInfo.associatedSalesRep" class="button button-secondary eAppDashBtn" :href="'/eapplications/eapplication?id='+masterInfo.applicationGuid">Add Location</a>
                        <a v-if="isChainApplicant && !masterInfo.isApplicationCorporateMaster && userId == masterInfo.CreatedBy" class="button button-secondary eAppDashBtn" href="/eapplications/dashboard/chains">Add Location</a>                       
                    </td>
                </tr>
            </table>

            <!-- Retailer Name search -->
            <div class="eAppFilter cf" v-if="isEappUser">
                <form id="search">
                    <label for="query" id="queryLabel" class="filterLbl">Search by Retailer Name</label>
                    <input name="query" id="query" placeholder="Retailer Name" ref="searchInput" @keydown.enter="handleSearch" class="filterInput">
                    <button ref="searchButton" @click="handleSearch" id="btnFilter" class="button btn-primary">Search Applications</button>
                    <button ref="clearSearchButton" @click="clearSearch" class="btn-primary" v-if="showClearSearch">Clear Search</button>
                </form>
            </div>

            <!-- Tabs for Applications/Updates view toggle -->
            <ul class="tabs" v-if="!isCLMuser && isEappUser">
                <li class="tabs-title " :class="{ 'is-active': appType == 'regular' }" @click="getAppsByType('regular')"><span>Applications</span></li>
                <li class="tabs-title" :class="{ 'is-active': appType == 'updates' }" @click="getAppsByType('updates')"><span>Updates</span></li>
            </ul>

            <!-- Status switcher -->
            <div class="eAppStatusFilter" v-if="isEappUser">
                <span :class="{ selected: activeStatus == 'pending' }" @click="getAppsByStatus('pending')">Pending</span> |
                <span :class="{ selected: activeStatus == 'open' }" @click="getAppsByStatus('open')">Open</span> |
                <span :class="{ selected: activeStatus == 'rejected' }" @click="getAppsByStatus('rejected')">Rejected</span>
            </div>

            <!-- Apps associated to this user && of selected app type && of the selected status -->
            <div class="eAppList cf" v-if="!isLoading && isEappUser">

                <table class="eAppListTbl" v-if="gridData && gridData.length">
                    <thead>
                        <tr>
                            <th v-for="(header, index) in headers" :key="index">
                                <span @click="sortBy(header, sortOrder)" :class="{ active: sortColumn == header, sortable: header!=='District', sortOrder}">
                                    {{ header }}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="appType == 'regular'">
                        <tr v-for="(row, index) in gridData" :key="index" class="detailRow">
                            <td>
                                {{row['applicationNumber']}}
                            </td>
                            <td>

                                <a :href="'/eapplications/eapplication?id='+row['businessGuid']">{{row['businessName']}}</a>

                                <div v-if="showQuickview">
                                    <!-- quickview only for SR, RM, & CLM Roles -->
                                    <a :href="'#qv'+index" @click="openQuickview(index)" class="quickViewOpen">Quickview</a> |
                                    <a :href="'/eapplications/eapplication?id='+row['businessGuid']">Review</a>

                                    <!-- create modal for Quickview -->
                                    <div :id="'qv'+index" data-reveal class="quickView reveal">
                                        <button class="close-button" data-close aria-label="Close modal" type="button">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <strong>
                                            {{row['businessLegalName']}} -
                                            {{row['applicationNumber']}}
                                        </strong>

                                        <div class="quickViewAddress">
                                            {{row['businessStreetAddress']}}<br />
                                            {{row['businessCity']}}, {{row['businessZip']}}
                                        </div>

                                        <!-- for Sales Rep -->
                                        <div v-if="userEappRole.codename == 'eAppDistrictRep' || userEappRole.codename == 'eApp-RegionalManager'">
                                            <span class="alertSOB" v-if="row['saleOfBusiness'] && userEappRole.codename == 'eAppDistrictRep'">
                                                <br /><span class="icon-Status statusSOB"></span>
                                                Sale of Business<br />
                                            </span>
                                            Status: {{ getStatusName(row['applicationStatus']) }}
                                        </div>
                                        <!-- for Regional Mngr -->
                                        <div v-if="userEappRole.codename == 'eApp-RegionalManager'">
                                            District: {{row['applicationDistrict']}}<br />
                                            Sales Rep: {{row['associatedSalesRep']}}<br />
                                            Status: {{ getStatusName(row['applicationStatus']) }}
                                        </div>
                                        <!-- for Licensing -->
                                        <div v-if="userEappRole.displayname == 'Licensing'" class="quickViewStatus quickViewLicensingStatus">
                                            <span class="icon-Status statusSecurity" :class="{ needsData: (row['securityReview'] == 0) }"></span> Principals Approved<br />
                                            <span class="icon-Status statusEFT" :class="{ needsData: (row['eFTReview'] == 0) }"></span> EFT Approved<br />
                                            <span class="bold">District:</span> {{row['applicationDistrict']}}
                                        </div>
                                        <!-- action(s) -->
                                        <div class="quickViewActions">
                                            <a :href="'/eapplications/eapplication?id='+row['businessGuid']">Review</a>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {{row['businessCity']}}
                            </td>
                            <td v-if="userEappRole.codename !== 'eAppDistrictRep'">
                                {{row['applicationDistrict']}}
                            </td>
                            <td>
                                <!-- Format date as: 4/27/2015 3:16:49 PM -->
                                {{appDateFormatter(row['createdOn'])}}
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="(row, index) in gridData" :key="index" class="detailRow">
                            <td>
                                {{row['agentNumber']}}
                                <!-- for Licensing -->
                                <span v-if="isCLMuser">{{row['businessName']}}</span>
                            </td>
                            <td>
                                {{row['batchNumber']}}
                                <!-- for Licensing -->
                                <span v-if="isCLMuser">{{row['updateWrapperID']}}</span>
                            </td>
                            <td>
                                <!-- this might change based on how we access the updates -->
                                <a :href="'/eapplications/updates/update?id='+row['updateGuid']">{{row['updateType']}}</a>
                            </td>
                            <td>
                                <!-- Format date as: 4/7/2015 (no time) -->
                                {{updateDateFormatter(row['createdOn'])}}
                            </td>
                            <td>
                                <span class="icon-check-circle" v-if="row['isPartOfARenewal']"></span>
                            </td>
                            <td v-if="isCLMuser">
                                <!-- for Licensing -->
                                <span v-if="row['principalCrimeConvictions']">
                                    Yes: {{row['principalCrimeConvictionsExplanation']}}
                                </span>
                                <span v-else>
                                    No
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div v-else class="InfoLabel alert-message cf">There are currently no applications to display for the selected status category.</div>

                <grid-paginate :totalPages="totalPageCount"
                               :currentPage="currentPage"
                               :itemsPerPage="pageSize"
                               @changePage="changePage" />

            </div>


            <hollow-dots-spinner :animation-duration="1000"
                                 :dot-size="15"
                                 :dots-num="3"
                                 color="#0FD8C9"
                                 v-if="isLoading" />

        </section>
    </div>
</template>
<script>
    import $ from 'jquery';
    import moment from 'moment';
    import Foundation from "@/foundation-sites/js/foundation.js";
    import GridPaginate from "../../Pagination/pagination.vue";
    import { HollowDotsSpinner } from 'epic-spinners';
    import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';
    export default {
        components: {
            SignInRegister,
            HollowDotsSpinner,
            GridPaginate
        },
        data: function () {
            return {
                isUserLogged: false,
                isEappUser: false,
                isChainSalesRep: false,
                isChainApplicant: false,
                userEappRole: '',
                dashTitle: 'Application',
                userId: 0,
                isRegularUser: false,
                isCLMuser: false,
                appType: 'regular',
                activeStatus: 'pending',
                gridData: [],
                headers: [],
                sortColumn: 'Application Date',
                sortOrder: 'asc',
                statusGrouping: 2,
                currentPage: 1,
                pageSize: 20,
                totalPageCount: 0,
                isLoading: false,
                searchQuery: '',
                showClearSearch: false,
                showQuickview: false,
                regularHeaders: ["Application", "Business Name", "District", "City", "Application Date"],
                salesRepHeaders: ["Application", "Business Name", "City", "Application Date"],
                updateHeaders: ["Agent #", "Update ID", "Updated Element(s)", "Date", "Renewal?", "Conviction?"],
                ma: '',
                masterInfo: {}
            }
        },
        mounted: function () {
            this.prepDisplay();
            $(document).foundation(); // recall to activate reveal modals
        },
        methods: {
            prepDisplay() {
                this.isUserLogged = this.isUserAuthed();
                // console.log('isUserLogged: ' + this.isUserLogged);
                if (this.isUserLogged) {
                    this.userEappRole = this.getEappRole();
                    // console.log(this.userEappRole);
                    if (this.userEappRole !== false) {
                        this.isEappUser = true;
                        this.headers = this.regularHeaders

                        //is application eApp-ChainApplicant
                        if (this.userEappRole.codename == 'eApp-ChainApplicant') {
                            this.isChainApplicant= true;
                        }
                        // is Sales Rep?
                        if (this.userEappRole.codename == 'eApp-ChainSalesRep' || this.userEappRole.codename == 'eApp-RegionalManager') {
                            this.isChainSalesRep = true;
                            this.headers = this.salesRepHeaders;
                            this.showQuickview = true;
                        }
                        // is not in a Chain role?
                        if (this.userEappRole.codename != 'eApp-ChainSalesRep' && this.userEappRole.codename != 'eApp-RegionalManager' && this.userEappRole.codename != 'eApp-Security' && this.userEappRole.codename != 'eApp-CentralLicensingManager' && this.userEappRole.codename != 'eApp-EFTReviewer') {
                            this.isRegularUser = true;
                        }

                        if (this.userEappRole.codename == 'eApp-CentralLicensingManager') {
                            this.showQuickview = true;
                        }

                        this.dashTitle = this.userEappRole.displayname;

                        this.getToken();
                    } else {
                        // no eApp Role found
                        this.isEappUser = false;
                        // console.log('no eApp Role found');
                    }
                }
            },
            getToken() {
                let uId = this.getLoggedInUserId();
                if (uId) {
                    // console.log('uid: ' + uId);
                    this.userId = uId;
                    this.getAPItoken()
                        .then((token) => {
                            this.token = token;
                            this.sortBy = "createdOn";
                            this.sortOrder = "asc"
                            this.tryGetFromQueryString().then(() => {
                                this.fetchData(token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder, this.statusGrouping, this.ma);
                            });
                        });
                }
            },
            async fetchData(token, _pageNumber, _pageSize, _businessName, _sortBy, _sortOrder, _statusGrouping, _ma) {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        pageNumber: _pageNumber,
                        pageSize: _pageSize,
                        businessName: _businessName,
                        sortBy: _sortBy,
                        sortOrder: _sortOrder,
                        statusGrouping: _statusGrouping, 
                        ma: _ma
                    })
                };
                let applicationsRequest = await (await fetch(this.GET_APPS_API_URL, appsRequestOptions)).json();

                this.gridData = applicationsRequest.results;
                this.totalPageCount = applicationsRequest.totalPageCount;
                this.masterInfo = applicationsRequest.masterAppSummary;
                this.isLoading = false;
            },
            tryGetFromQueryString() {
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('ma')) {
                    this.ma = urlParams.get('ma');
                }
                return Promise.resolve();
            },
            getAppsByType(getAppType) {
                // switch the type
                this.appType = getAppType;
                // prep table
                if (getAppType == 'regular') {
                    this.headers = this.regularHeaders;
                    this.sortColumn = 'Application Date';
                } else {
                    this.headers = this.updateHeaders;
                    this.sortColumn = 'Date';
                }
                this.activeStatus = 'pending';
                // reset the stage
                this.prepDisplay();
            },
            getAppsByStatus(getStatus) {
                // switch the type
                this.activeStatus = getStatus;
                switch (getStatus) {
                    case 'open':
                        this.statusGrouping = 1;
                        break;
                    case 'rejected':
                        this.statusGrouping = 3;
                        break;
                    case 'pending':
                    default:
                        this.statusGrouping = 2;
                        break;
                }

                // fetch the data
                this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder, this.statusGrouping, this.ma);
            },
            async changePage(pageNumber) {
                this.currentPage = pageNumber;
                await this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder, this.statusGrouping, this.ma);
            },
            loadUpdate(thisUpdate) {
                // gow to the Update review screen for this
                //todo gina
                // console.log('go to this update: ' + thisUpdate);
            },
            getStatusName(int) {
                return this.getEappStatus(int);
            },
            async sortListBy(key, order) {
                //if the same key is clicked again, toggle the asc/desc
                if (key === this.sortBy) {
                    if (order === 'asc') {
                        this.sortOrder = 'desc';
                    }
                    else {
                        this.sortOrder = 'asc';
                    }
                }
                //otherwise, sort the new column selected by asc order
                else {
                    this.sortOrder = 'asc';
                }
                this.sortBy = key;
                // console.log(this.sortBy);
                await this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder, this.statusGrouping, this.ma);
            },
            openQuickview(i) {
                let popup = new Foundation.Reveal($('#qv' + i));
                popup.open();
            },
            appDateFormatter(date) {
                // 4/27/2015 3:16:49 PM
                return moment(date).format('M/D/yyyy h:mm:ss A');
            },
            updateDateFormatter(date) {
                return moment(date).format('M/D/yyyy');
            },
            async clearSearch() {
                this.$refs.searchInput.value = '';
                this.searchQuery = '';
                await this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder, this.statusGrouping, this.ma);
                this.showClearSearch = false;
            },
            async handleSearch() {
                let value = this.$refs.searchInput.value;
                this.searchQuery = value;
                await this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder, this.statusGrouping, this.ma);
                this.showClearSearch = true;
            }
        },
        computed: {
            GET_APPS_API_URL() {
                let url = '';
                switch (this.userEappRole.codename) {
                    case "eApp-ChainSalesRep":
                    case "eApp-ChainManager":
                    case "eApp-RegionalManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/Location/GetApplicationListForChainSalesRep?userId=` + this.userId;
                        break;
                    case "eApp-CentralSalesManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/Location/GetApplicationListForChainCentralSales?userId=` + this.userId;
                        break;
                    case "eApp-EFTReviewer":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/Location/GetApplicationListForChainEft?userId=` + this.userId;
                        break;
                    case "eApp-Security":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/Location/GetApplicationListForChainSecurity?userId=` + this.userId;
                        break;
                    case "eApp-CentralLicensingManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/Location/GetApplicationListForChainLicensing?userId=` + this.userId;
                        break;
                    default:
                        break;
                }

                // console.log('url: ' + url);
                return url;
            }
        }
    }
</script>