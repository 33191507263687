<template>
    <div class="registerPage">

        <div v-if="noPrincipalsError" class="ErrorLabel alert-error principalRegFormError">
            The provided information doesn't match an existing principal. Please try again.
        </div>
        <div class="userRegFormWrap principalRegForm olcForm">
            <fieldset class="regForm regContactFields cf">

                <p class="reqNote"><span class="req">*</span> Required field</p>
                <h4 class="formH4">Principal Information</h4>
                <p>Provide a retailer agent number and search for the associated principals.</p>

                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell agentSearch">
                        <label for="txbxAgentNumber" class="agentNumberLabel">Retailer agent number <span class="req">*</span></label>

                        <input type="text" id="txbxAgentNumber" class="agentNumber" v-model="agentNumber" required />
                        <div v-if="agentNumberError" class="error">Please enter the agent number</div>

                        <button type="button" id="btnSearchPrincipals" class="button_inForm btn_SearchPrincipals button btn-secondary" @click="searchPrincipals()">Search Principals</button>
                    </div>
                    <div v-if="!disableFields" class="cell medium-6">
                        <label for="principalList">Select a principal from the list <span class="req">*</span></label>
                        <select id="principalList" v-model="dropdownPrincipal" @change="principalSelected()" :disabled="disableFields" required>
                            <option disabled :value="{}">Select</option>
                            <option :value="principal" v-for="principal in principals" :key="principal.principalId">{{ principal.fullName }}</option>
                        </select>
                        <div v-if="principalSelectError" class="error">Please select a Principal</div>
                    </div>
                    <div v-if="!disableFields" class="cell medium-6">
                        <label for="txbxSSN">Last 4 digits of principal's SSN <span class="req">*</span></label>
                        <input type="text" id="txbxSSN" max="4" class="agentSSN fiveCharWide" :disabled="disableFields" v-model="principalSSN" required />
                        <div v-if="principalSSNError" class="error">Please provide last 4 digits of SSN</div>
                    </div>
                </div>
                <div class="principal-info grid-x grid-margin-x grid-margin-y">
                    <div class="cell">
                        <span>Address:</span> {{ selectedPrincipal.principalAddress }}
                    </div>
                    <div class="cell medium-6">
                        <span>City:</span> {{ selectedPrincipal.principalCity }}
                    </div>
                    <div class="cell medium-3">
                        <span>State:</span> {{ selectedPrincipal.principalState }}
                    </div>
                    <div class="cell medium-3">
                        <span>Zip code:</span> {{ selectedPrincipal.principalZip }} {{ selectedPrincipal.principalZip4 }}
                    </div>
                    <div class="cell">
                        <span>Phone number:</span> {{ selectedPrincipal.principalPhone }}
                    </div>
                </div>
            </fieldset>
            <fieldset v-if="!isUserLogged" class="regForm regLogInFields cf">
                <h4 class="formH4">Log In Information</h4>
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell medium-6">
                        <label for="txbxEmail">Email address <span class="req">*</span></label>
                        <input v-model="userName" type="text" id="txbxEmail" pattern="email" required />
                        <div v-if="emailMissing || emailInvalid" class="error">Please enter a valid email address. Eg. Something@domain.com</div>
                        <!-- <div v-if="emailMissing" class="error">Please enter an email address</div> -->
                    </div>
                    <div class="cell medium-6">
                        <label for="txbxConfirmEmail">Confirm email address <span class="req">*</span></label>
                        <input v-model="usernameConfirm" type="text" id="txbxConfirmEmail" class="preventPaste" onpaste="return false;" pattern="email" data-equalto="txbxEmail" require />
                        <div v-if="emailConfirmMissing" class="error">Emails do not match</div>
                        <!-- <div v-if="emailConfirmMissing" class="error">Please re-enter email</div> -->
                    </div>
                    <div class="cell medium-6">
                        <label for="txbxPassword">Create a password <span class="req">*</span></label>
                        <input type="password" v-model="password" id="password" required>
                        <password v-model="password" :strength-meter-only="true" />
                        <div v-if="passwordMissing" class="error">Please enter valid password. It must be between 8 and 15 characters, and have at least one lower case letter, one upper case letter and one number.</div>
                        <!-- <div v-if="passwordMissing" class="error">Please enter a password</div> -->
                    </div>
                    <div class="cell medium-6">
                        <label for="txbxConfirmPassword">Confirm password <span class="req">*</span></label>
                        <input v-model="passwordConfirm" type="password" placeholder="Confirm Password" aria-describedby="passwordHint" data-equalto="password" required max="100" onpaste="return false;" class="preventPaste"/>
                        <div v-if="passwordConfirmMissing" class="error">Passwords do not match</div>
                        <!-- <div v-if="passwordConfirmMissing" class="error">Please re-enter password</div> -->
                    </div>
                    <p class="help-text" id="passwordHint">Your password must be at least eight characters long and include an uppercase letter AND a lowercase letter AND a number.</p>
                </div>
            </fieldset>
            <fieldset class="regForm regDisclaimers cf">
                <h4 class="formH4">Terms of Use</h4>
                <div id="disclaimerScroll" class="disclaimerScroll clearfix">
                    <div class="eAppTandC cf terms" v-html="termsContent"></div>
                </div>
                <div class="fullForm disclaimerBox formRow checkboxRow">
                    <input required id="agreeTerms" v-model="terms" name="agreeTerms" type="checkbox" class="AcceptedAgreement disclaimerInput checkbox">
                    <label for="agreeTerms">I have read and agree to the above Ohio Lottery Terms of Use. <span class="req">*</span></label>
                    <div v-if="!terms && hasClickedSubmit" class="error">You must accept terms.</div>
                </div>
            </fieldset>
            <div v-if="newUserError" class="ErrorLabel alert-error principalRegFormError">
                <span v-html="newUserErrorMessage" v-if="newUserErrorMessage !== ''"></span>
                <span v-else>There was an error processing your request. Please try again later or contact support.</span>
            </div>
            <fieldset class="regForm regButtons cf">
                <button type="button" id="btnRegister" @click="clickSubmit()" class="regSubmit button">Register</button>
            </fieldset>
        </div>

    </div>
</template>
<script>
    import $, { hasData } from 'jquery';
    import Password from 'vue-password-strength-meter';
    export default {
        components:{
            Password
        },
        data: function () {
            return {
                isLoading: false,
                userId: 0,
                isUserLogged: false,
                agentNumber: '',
                agentNumberError: false,
                principals: [],
                noPrincipalsError: false,
                principalSelectError: false,
                disableFields: true,
                principalSSN: '',
                principalSSNError: false,
                dropdownPrincipal: {},
                selectedPrincipal: {},
                userName: '',
                password: null,
                termsContent: '',
                termsContentKey: 'eAppTandC',
                terms: false,
                token: '',
                hasClickedSubmit: false,
                passwordConfirmMissing: false,
                passwordMissing: false, 
                emailConfirmMissing: false, 
                emailMissing: false,
                usernameConfirm: '',
                passwordConfirm: '',
                emailInvalid: false,
                newUserError: false,
                newUserErrorMessage: ''
            }
        },
        mounted: function () {
            this.isUserLogged = this.isUserAuthed(); 
            if (this.isUserLogged) {
                this.userId = this.getLoggedInUserId();
            }
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getContent();
                });
            $(document).foundation();
            this.isUserLogged = this.isUserAuthed();
            // this.termsContent = this.getContentByKey(this.termsContentKey, 'eapp_terms_content');

        },
        methods: {
            getContent() {
                let cookieContent = JSON.parse(localStorage.getItem('eapp_terms_content'));
                if (cookieContent !== undefined && cookieContent !== "" && cookieContent !== null) {
                    this.termsContent = cookieContent;
                } else {
                    this.fetchContent();
                }
            },
            fetchContent() {
                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+'/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey='+this.termsContentKey, {
                    headers: {
                        'Authorization': 'Bearer '+this.token
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // console.log('Response Call: /1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey='+this.termsContentKey);
                        // console.log(response);
                    }
                })
                .then(json => {
                    let element = json.data[0].centralContent;
                    localStorage.setItem('eapp_terms_content', JSON.stringify(element)); 
                    this.termsContent = element;
                    // this.termsContent = JSON.parse(element);
                    
                })
            },     
            async searchPrincipals() {
                // validate agent number format
                if (this.agentNumber == '') {
                    this.agentNumberError = true;
                }
                else {
                    this.agentNumberError = false;
                    // fetch list of principals
                    await this.fetchRetailerPrincipals();

                    // enable the select and SSN fields
                    this.disableFields = false;
                }
                
            },
            async fetchRetailerPrincipals() {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    }
                };
                this.principals = await (await fetch(this.GET_RETAILER_PRINCIPALS_API_URL, appsRequestOptions)).json();
                // if (response.statusCode === 200) {
                    
                // } else {
                //     console.log('Response Call: /1.0/Retailer/SearchPrincipals?agentNumber=' +this.agentNumber);
                //     console.log(response);
                // }
                if (this.principals.length <= 0) {
                    this.noPrincipalsError = true;
                } else {
                    this.noPrincipalsError = false;
                }
                this.isLoading = false;
            },

            principalSelected() {
                // populate the Principal Details Info
                this.selectedPrincipal = this.dropdownPrincipal;
            },
            ssnValidation(ssnVal) {
                // Validate ssn
                const ssnRegex = /^\d{4}?$/;
                if (ssnRegex.test(ssnVal)) {
                    return true;
                } else {
                    return false;
                }
            },
            checkMatchingPasswords() {
                if (this.password != this.passwordConfirm) {
                    this.passwordConfirmMissing = true;
                }
            },
            checkMatchingEmails() {
                if (this.userName != this.usernameConfirm) {
                    this.emailConfirmMissing = true;
                }
            },
            clickSubmit() {
                let valid = true;
                if (!this.isUserLogged) {
                    this.hasClickedSubmit = true;
                    if (this.userName) {
                        if (this.emailValidation(this.userName)) {
                            this.emailMissing = false;
                            this.emailInvalid = false;
                        }
                        else {
                            this.emailInvalid = true;
                            valid = false;
                        }

                        if (this.userName != this.usernameConfirm) {
                            this.emailConfirmMissing = true;
                            valid = false;
                        }
                        else {
                            this.emailConfirmMissing = false;
                        }
                    }
                    else {
                        this.emailMissing = true;
                        valid = false;
                    }

                    if (this.password) {
                        if (this.passwordValidation(this.password)) {
                            this.passwordMissing = false;
                        }
                        else {
                            this.passwordMissing = true;
                            valid = false;
                        }

                        if (this.password != this.passwordConfirm) {
                            this.passwordConfirmMissing = true;
                            valid = false;
                        }
                        else {
                            this.passwordConfirmMissing = false;
                        }
                    }
                    else {
                        this.passwordMissing = true;
                        valid = false;
                    }
                    if (!this.terms) {
                        valid = false;
                    }
                }
                
                if (valid) {
                    this.submitForm();
                }

            },
            submitForm() {
                // validate SSN
                if (this.agentNumber) {
                    this.agentNumberError = false;
                    let isValidSSN = this.ssnValidation(this.principalSSN);
                    if (isValidSSN) {
                        this.principalSSNError = false;
                    } else {
                        this.principalSSNError = true;
                        this.selectedPrincipal = {}; // revert any previous result
                    }

                    // if new && email is already linked: "This email is already linked to another principal.""
                    let response = {};
                    if (!this.principalSSNError && this.terms) {
                        if (this.isUserLogged) {
                            this.RegisterPrincipal();
                        }
                        else {
                            this.NewUserRegisterPrincipal();
                        }
                    }
                }
                else {
                    this.agentNumberError = true;
                }
                
                

            },
            async RegisterPrincipal() {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        agentNumber: this.agentNumber,
                        name: this.selectedPrincipal.fullName,
                        sSN4: this.principalSSN,
                    })
                };
                let response = await (await fetch(this.POST_REGISTER_PRINCPAL_RETAILER_API_URL, appsRequestOptions));
                // console.log(response);
                if (response.status === 200) {
                    window.location.href = window.location.origin + '/eApplications/updates';
                } else if (response.status === 400) {
                    if (response.value) {
                        this.newUserErrorMessage = response.value;
                    }
                    this.newUserError = true;
                } else {
                    // console.log('Response Call: /1.0/Retailer/RegisterPrincipal/' + this.userId);
                    this.newUserError = true;
                }
                this.isLoading = false;
            },
            async NewUserRegisterPrincipal() {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        agentNumber: this.agentNumber,
                        name: this.selectedPrincipal.fullName,
                        sSN4: this.principalSSN,
                        email: this.userName,
                        password: this.password
                    })
                };

                let response = await (await fetch(this.POST_NEW_USER_REGISTER_PRINCPAL_RETAILER_API_URL, appsRequestOptions).catch(err => {this.newUserError = true;}));

                // console.log('response from new user');
                // console.log(response);
                if (response !== undefined && response !== "undefined") {
                    // response = JSON.parse(response);
                    if (response.status == 200) {
                        window.location.href = window.location.origin + '/eApplications/updates';
                    } else if (response.status == 500) {
                        // console.log('Response Call: /1.0/Retailer/NewUserRegisterPrincipal');
                        // console.log(response);
                        this.newUserError = true;
                    } else {
                        // console.log('Response Call: /1.0/Retailer/NewUserRegisterPrincipal');
                        // console.log(response);
                        this.newUserErrorMessage = response.value;
                        this.newUserError = true;
                    }
                } else {
                        
                    // assume 500 error
                    this.newUserError = true;

                }

                this.isLoading = false;
            },
        },
        computed: {
            GET_RETAILER_PRINCIPALS_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Retailer/SearchPrincipals?agentNumber=` +this.agentNumber;
            },
            POST_REGISTER_PRINCPAL_RETAILER_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Retailer/RegisterPrincipal/` + this.userId;
            },
            POST_NEW_USER_REGISTER_PRINCPAL_RETAILER_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Retailer/NewUserRegisterPrincipal`;
            }

        }
    };
</script>