<template>
    <section class="content contentRow">
        <div class="mlrUserOrderDetail">
            <h3>{{ product.orderItems[0].name }}</h3>
            <span>Purchased On: {{ product.orderDate }}</span>
            <p>Order Number: {{ product.orderID }}</p>
            <img :src="cleanseImagePath(product.orderItems[0].imagePath)" alt="Gift Card Image" class="img-responsive">
            <div class="redemptionDetail" v-if="product.fulfillmentInfo">
                <p class="redDetailsTitle">Redemption Code:</p>
                <p class="redDetailsVal">{{ product.fulfillmentInfo.digitalFulfillmentInfo.giftCardCode }}</p>
            </div>
        </div>
        <div class="orderDetailBody">
            <p>{{ product.orderItems[0].name }}</p>
            <span v-html="product.orderItems[0].orderItemSKUDescription"></span>
        </div>
        <div class="orderDetailBtns">
            <button class="button btn-purple btn-secondary" @click="print">Print Order Details</button>
            <button class="button btn-purple btn-secondary" data-open="redeemOrderModal" @click="openRedeem(product)">Mark Redeemed</button>
        </div>

        <div id="redeemOrderModal" class="reveal reveal-modal data-modalOptions" data-reveal>
            <span v-if="!error">
                <h3>Did you redeem this Digital Purchase?</h3>
                <p>If you have redeemed it, tap YES to remove it from your Active list.</p>
                <div class="orderModalOptions">
                    <a class="button btn-purple btn-secondary" data-close @click="closeRedeem">NO, KEEP ACTIVE</a>
                    <button type="button" class="button btn-primary redeemOrder" data-close @click="redeemDigitalItem(true)">YES, I REDEEMED IT</button>
                </div>
            </span>
            <span v-if="error">
                <h3>Error</h3>
                <p>We're worry there was an error with your transaction. Please try again later.</p>
            </span>
        </div>
    </section>
</template>

<script>
    // import $ from 'jquery';
    import moment from "moment";

    export default {
        data: function () {
            return {
                mobileCashing: false,
                token: '',
                product: '',
                description: ''
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    this.getOrder();
                });
            },
            getOrder() {
                let apiToken = this.token,
                    sku = this.getURLQuery('orderId'),
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserOrderDetail?userId=' + this.user.user.userId + '&orderId=' + sku;

                return fetch(url, {
                        method: 'GET',
                        // body: JSON.stringify(datCreds),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let orders = json.data;
                    // console.log(orders)

                    orders.orderDate = moment(new Date(orders.orderDate)).format('MM/DD/YYYY');

                    var domparser = new DOMParser();

                    let description = domparser.parseFromString(orders.orderItems[0].orderItemSKUDescription, 'text/html');
                    
                    this.description = description.body;

                    // console.log(this.description.body)

                    this.product = orders;
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
            print() {
                window.print();
            },
            openRedeem(product) {
                this.error = false;
                this.itemChosen = product;
                // console.log(this.itemChosen);
                // let popup = new Foundation.Reveal($('#redeemOrderModal'));
                // popup.open();
           },
           redeemDigitalItem(d) {
                let item = this.itemChosen,
                    apiToken = this.token;

                let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/RedeemDigitalItem?userId=' + this.user.user.userId + '&productId=' + item.orderItems[0].skuid + '&orderId=' + item.orderItems[0].id + '&redeemed=' + d;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    if (json.data === true) {
                        // this.getOrders();
                        this.closeRedeem();
                    } else {
                        this.error = true;
                    }
                })
           },
           closeRedeem() {
                this.enter = true;
                this.confirm = false;
                this.error = false;
                // $('#redeemOrderModal').hide();
                // $('.reveal-overlay').hide();
            }
		}
    };
</script>