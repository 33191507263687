<template>
    <span>
        <div class="drawHisLI col4 link" @click="getWinners">
            <span v-if="!view">View Winners</span>
            <span v-if="view">Hide Winners</span>
        </div>
        <ul v-if="view === true && !additionalCodeName.length" class="drawHisLI col5">
            <li class="showingDetails drawHistDetail listRow cf">
                <div class="drawHisLI listHead">
                    Winners
                </div>
                <div class="drawHisLI listHead entryCount">
                    Location
                </div>
            </li>
            <li v-for="winner in data" :key="winner.itemID" class="showingDetails listRow cf">
                    {{ winner.fullName }}
                <span class="entryCount">
                    {{ winner.location }}
                </span>
            </li>
        </ul>
        <div v-if="view === true && additionalCodeName.length" class="customDrawingWinnersOnCompleted">
            {{ displayCustomWinners(additionalCodeName) }}
            <div v-html="additionalHTML"></div>
        </div>
    </span>
</template>

<script>
    // import $ from 'jquery';
    // import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        props: [
            'id',
            'tokenKey',
            'url',
            'winners',
            'drawingCodeName'
        ],
        components:{
        },
        data: function () {
            return {
                data: '',
                view: false,
                additionalCodeName: '',
                additionalHTML: ''
            }
        },
        mounted: function () {
        },
        methods: {
            getWinners() {
                this.view = !this.view;
                
                if (this.url === false) {
                    this.additionalCodeName = this.drawingCodeName;
                    this.data = this.winners;
                    // console.log("NEVERMIND - WHAT IS THE DATA HERE?");
                    // console.log(this.data);
                    // console.log("DOES THIS HAVE A drawingCodeName");
                    // console.log(this.additionalCodeName);
                } else {
                    if (!this.data.length) {
                        let url = process.env.VUE_APP_APIURL + this.url + this.id;

                        fetch(url, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json-patch+json',
                                'Authorization': "Bearer " + this.tokenKey
                            },
                        })
                        .then((response) => response.json())
                        .then((json) => {
                            this.data = json.data;
                        })
                    }
                }
            },
            displayCustomWinners(codename) {
                // alert(codename);
                //handle the custom display based on promotion drawings codename(s)

                //Keno Bracket Challenge - March 2025
                var kenoDrawingCodeNames = ['KenoRound1_2025', 'KenoRound2_2025', 'KenoRound3_2025', 'KenoRound4_2025', 'KenoRound5_2025'];

                if (kenoDrawingCodeNames.includes(codename)) {
                    this.additionalHTML = '<a target="_blank" href="/mylotto-rewards/second-chance-promotions/keno-bracket-challenge#tab4">KENO Bracket Challenge Winners</a>';
                }
            }
        },
        computed: {
        },
        watch: {
        }
    };
</script>

<style lang="scss" scoped>
    h2 {
        float: left;
    }
    .col5 {
        display: block !important;
    }
    .drawHisViewAll {
        display: inline;
        float: right;
        margin-top: -30px;
        margin-bottom: 10px;
        font-weight: 300;

        @media screen and (min-width: 641px) {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
</style>