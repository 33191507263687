<template>
    <div class="scratchGame_detail cf">
        <h1>{{ gameData.gameName }}</h1>
        <div class="gameNumber">Game Number: <span class="number">#{{ gameData.gameNumber }}</span></div>
        <div class="gameIntro" v-html="gameData.headingIntro"></div>

        <div class="about clear module_callOut">
            <h3 class="mobileToggleTrigger" v-html="description"></h3>
            <div class="mobileToggleContent">
                <div class="about_content" v-html="about"></div>
                <!-- add Rules PDF button here -->
                <p class="odds">Overall odds of winning: {{ gameData.oddsOfWinning }}</p>
                <div class="playbook_spread"></div>
                <a class="button btn_purple scratchoffs_rules_btn" target="_blank" :href="gameData.gameRulesPDFURL" v-if="OLCButton">
                    <span class="icon-doc"></span> OLC Operating Procedure
                </a>
            </div>
        </div>

        <div class="tbl_PrizesRemaining">

            <div class="grid-x grid-margin-x wrapper-list">
                <div class="columns medium-12 large-12 cell list-res" v-for="item in fetchResult" :value="item.gameId"
                    :key="item.gameId">
                    <div class="grid-x">
                        <div class="columns small-6 medium-6 large-6 cell"><span class="subTitle">Prizes</span></div>
                        <div class="columns small-6 medium-6 large-6 cell"><span class="subTitle">Remaining</span></div>
                    </div>
                    <div class="grid-x" v-for="list in item.prizeRemainingValues" :key="list.prizeValue">
                        <div class="columns small-6 medium-6 large-6 cell">
                            <!-- <span v-if="(list.prizeValue != 0 && list.prizeValue < 1000)">${{ list.prizeValue }}</span>
                            <span v-if="(list.prizeValue != 0 && list.prizeValue > 999)">${{ list.prizeValue.toLocaleString() }}</span>
                            <span v-else-if="(list.prizeValue == 0)">{{ list.description }}</span> -->
                            <span>{{ list.description }}</span>
                        </div>
                        <div class="columns small-6 medium-6 large-6 cell" v-if="list.prizesLeft < 1000"><span>{{ list.prizesLeft }}</span></div>
                        <div class="columns small-6 medium-6 large-6 cell" v-if="list.prizesLeft > 999"><span>{{ list.prizesLeft.toLocaleString() }}</span></div>
                    </div>
                </div>
            </div>

        </div>

        <!-- <div class="igTicketImg" :src="gameData.gameGraphicURL"></div> -->
        <div class="igTicketImg" :style="bgObject"></div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: function () {
        return {
            fetchResult: {},
            gameData: {},
            gameId: document.getElementById('gameId').value,
            name: document.getElementById('name').value,
            price: document.getElementById('price').value,
            number: document.getElementById('number').value,
            heading: document.getElementById('heading').value,
            description: document.getElementById('description').value,
            about: document.getElementById('about').value,
            logo: document.getElementById('logo').value,
            mLogo: document.getElementById('mLogo').value,
            graphic: '',
            graphicThumb: document.getElementById('graphicThumb').value,
            onSale: document.getElementById('onSale').value,
            closing: document.getElementById('closing').value,
            redeem: document.getElementById('redeem').value,
            odds: document.getElementById('odds').value,
            tpdStatus: document.getElementById('tpdStatus').value,
            tpdDate: document.getElementById('tpdDate').value,
            jackpot: document.getElementById('jackpot').value,
            payout: document.getElementById('payout').value,
            breakdown: document.getElementById('breakdown').value,
            OLCButton: false,
            loading: true,
            bgObject: {}
        }
    },
    mounted: function () {
        this.getToken();
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.findGame(token).then(() => this.fetchPrizeData(token));
                    this.checkScratchoffs({ token: token })
                        .then(() => {
                            setTimeout(() => {
                                let games = this.getScratchoffs();
                                this.games = games();
                                // console.log(this.games);
                                // find the game by gameId
                                // this.findGame();
                                // this.findGameGraphic();
                                this.loading = false;
                            }, 1000);
                        })
                });
        },
        findGame(token) {
            var apiToken = token;
            var urlProtocol = this.getProtocol();
            return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/ScratchOffs/ScratchOffGame/GetGameInformation?gameCode=" + this.number + "&getAllGameInfo=true", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    this.gameData = json.data;
                    this.gameData.gameRulesPDFURL !== '' ? this.OLCButton = true : this.OLCButton = false;
                    this.setGameGraphic(this.gameData.gameGraphicURL);
                })
                .catch(error => {
                    return this.errorMessage = error;
                });
        },
        findGame_old() {
            // console.log('this gameNumber: '+this.number);
            this.games.forEach(gameGroup => {
                gameGroup.forEach(gameInfo => {
                    let thisGameNumber = gameInfo.gameNumber.toString();
                    // console.log(thisGameNumber, this.number);
                    if (thisGameNumber == this.number.toString()) {
                        // console.log(thisGameNumber);
                        //this.gameData = gameInfo;
                        // console.log(this.gameData);
                    }
                });
            });
        },
        findGameGraphic() {
            // console.log('this gameID: '+this.gameId);
            this.games.forEach(gameGroup => {
                gameGroup.forEach(gameInfo => {
                    let thisGameID = gameInfo.gameID;
                    if (thisGameID == this.gameId) {
                        // console.log(gameInfo);
                        // console.log('graphic: '+gameInfo.gameGraphicURL);
                        this.graphic = gameInfo.gameGraphicURL;
                    }
                });
            });
        },
        fetchPrizeData(token) {
            var apiToken = token;
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/ScratchOffs/ScratchOffGame/GetPrizeRemainingListByGameID/" + this.gameData.gameID, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    let results = json.data;
                    results.forEach((item) => {
                        item.prizeRemainingValues.sort(function(a,b) { return b.prizeValue - a.prizeValue });
                        // console.log(item.prizeRemainingValues);
                    });
                    this.fetchResult = results;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
        },
        setGameGraphic(url) {
            // console.log(url);
            this.bgObject.backgroundImage = `url("${url}")`;
        },
        ...mapActions({
            checkScratchoffs: 'getNumbers/getOLCScratchGames'
        }),
        ...mapGetters({
            getScratchoffs: 'getNumbers/getScratchNumbers'
        })
    }
};

</script>
<style lang="scss" scoped>
h1,
.gameNumber {
    display: inline-block;
}

.gameNumber {
    span {
        font-weight: 700;
    }
}
</style>