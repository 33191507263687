<template>
    <section class="cf module_drawingList">
        <span v-if="debug === true">
            <hr>
            <p>
                <span>ID: </span>{{ userId }} ||
                <span>Return Responses: </span>{{ resultsReturned }}
            </p>
        </span>
        <h2>{{ title }}</h2>
        <button v-if="view === false && history" class="drawHisViewAll link" @click="$emit('history', 2)">View History</button>
        <button v-if="view === true && history" class="drawHisViewAll link" @click="$emit('history', 2)">View Recent</button>
        <div class="cf drawingList drawingListResponsive RecentDrawingEntryList">
            <table class="RecentDrawingEntryList cf">
                <tbody>
                    <tr>
                        <th class="listHead col1">Drawing Name</th>
                        <th class="listHead col2">Closing Date</th>
                        <th class="listHead col3">Entries</th>
                        <th class="listHead col4">Winners</th>
                    </tr>
                </tbody>
            </table>

            <!-- Recent View -->
            <ul v-if="view == false">
                <li v-for="entry in data.slice(0, resultsReturned)" :key="entry.name" class="stRow drawEntryHistoryMore cf">
                    <div class="drawHisLI col1">{{ entry.drawingName }}</div>
                    <div class="drawHisLI col2">
                        <span class="drawingDate"><span class="drawingLabel">Closing Date: </span>{{ entry.drawingClosingDate }}</span>
                    </div>
                    <div class="drawHisLI col3">
                        <span class="entryCount"><span class="drawingLabel">Entries: </span>{{ entry.entryCount }}</span>
                    </div>
                    <WinnersList :id="entry.plainDrawingID" :tokenKey="tokenKey" url='/1.0/PlainDraw/PlainDraw/Get-All-Winners-By-DrawingId/'></WinnersList>
                    <!-- <div class="drawHisLI col4" @click="getWinners(entry.plainDrawingID)">View Winners</div>
                    <ul class="drawHisLI col5">

                    </ul> -->
                </li>
            </ul>

            <!-- History View -->
            <ul v-if="view === true">
                <li v-for="entry in data" :key="entry.name" class="stRow drawEntryHistoryMore cf">
                    <div class="drawHisLI col1">{{ entry.drawingName }}</div>
                    <div class="drawHisLI col2">
                        <span class="drawingDate"><span class="drawingLabel">Closing Date: </span>{{ entry.drawingClosingDate }}</span>
                    </div>
                    <div class="drawHisLI col3">
                        <span class="entryCount"><span class="drawingLabel">Entries: </span>{{ entry.entryCount }}</span>
                    </div>
                    <WinnersList :id="entry.plainDrawingID" :tokenKey="tokenKey" url='/1.0/PlainDraw/PlainDraw/Get-All-Winners-By-DrawingId/'></WinnersList>
                    <!-- <div class="drawHisLI link col4" @click="getWinners(entry.plainDrawingID)">View Winners</div>
                    <ul class="drawHisLI col5"> -->

                    <!-- </ul> -->
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    // import $ from 'jquery';
    // import Foundation from "@/foundation-sites/js/foundation.js";
    import WinnersList from './DrawListWinners.vue';

    export default {
        props: [
            'debug',
            'tokenKey',
            'title',
            'userId',
            'resultsReturned',
            'data',
            'view'
        ],
        components:{
            WinnersList
        },
        data: function () {
            return {
                entries: this.data,
                history: false
            }
        },
        mounted: function () {
            this.data.length > this.resultsReturned ? this.history = true : false;
            if (this.debug === true) {
                // console.log("= Completed Drawings =");
                // console.log(this.data);
            }
        },
        methods: {
            getWinners(id) {
                let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/Get-All-Winners-By-DrawingId/' + id;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.tokenKey
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json.data);
                })
            }
        },
        computed: {
        },
        watch: {
        }
    };
</script>

<style lang="scss" scoped>
    h2 {
        float: left;
    }
    .drawHisViewAll {
        display: inline;
        float: right;
        margin-top: -30px;
        margin-bottom: 10px;
        font-weight: 300;
letter-spacing: 0;
            text-transform: none;

        @media screen and (min-width: 641px) {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
</style>