<template>
  <div class="marchScratchnessWrapper">
    <!-- <div class="socialCustomSignIn" v-if="!isUserLogged">
      <h3>SIGN IN</h3>
      <div class="fields">
        <input type="text" name="customUsername" placeholder="Email Address">
        <input type="password" name="customPassword" placeholder="Password">
      </div>
      <div class="actions">
        <a @click="customSignIn" class="button customSignInBtn">Sign In</a>
        <a href="#" class="customForgotPassword">I forgot my password</a>
      </div>
      <p>Don't have a MyLotto Rewards<sup>&reg;</sup> account? <a href="">Register Now.</a></p>
      <div class="error" v-if="loginError">{{ loginErrorMsg }}</div>
    </div> -->
    <SignInRegister v-if="!isUserLogged" class="mmSignIn"></SignInRegister>
    <div class="matching-game" v-else>
      <div class="message-center">
        <p>{{ messageCenterTxt(matches) }}</p>
      </div>
      <div class="marchMatchnessAnimate" v-if="isAnimating">
        <div v-for="animateCard in animatedCards" class="card msCard animatingCards" v-bind:style="{ 'background-image': 'url(' + animateCard.background +')'}"></div>
      </div>
      <div class="grid" v-if="!firstMatch && !isAnimating && !noMatchFound">
        <div v-for="(card, index) in shuffledCards" :key="index" class="card msCard"
          :class="{ 'flipped': card.flipped || card.matched, 'disabled': card.isDisabled }" @click="flipCard(index)">
          <div class="card-front">
            <img :src="card.img" alt="Image">
          </div>
          <div class="card-back">
            <!-- <p>?</p> -->
          </div>
        </div>
      </div>
      <div class="controls" v-if="firstMatch">
        <p>You are now entered for a chance to win one (1) book of Triple Golden Cherries Scratch-Off tickets - a $200 value!</p>
        <img src="/getattachment/e97ddcfd-3420-4642-81e7-00dc5f996d9c/triple-golden-cherries-ticket.png" alt="Triple Golden Cherries Ticket Image" width="401">
        <p>You can only be entered once. But you can keep playing just for fun.</p>
        <div class="controls-actions">
          <a @click="keepPlaying()" class="button">Keep playing for fun</a>
          <a @click="customLogout()" class="button btnLogout">Log out</a>
        </div>
      </div>
      <div class="controls" v-if="noMatchFound">
        <p>Don't worry, you're still entered for a chance to win one (1) book of Triple Golden Cherries Scratch-Off tickets (a $200 value)!</p>
        <img src="/getattachment/e97ddcfd-3420-4642-81e7-00dc5f996d9c/triple-golden-cherries-ticket.png" alt="Triple Golden Cherries Ticket Image" width="401">
        <p>You can only be entered once. But you can keep playing just for fun.</p>
        <div class="controls-actions">
          <a @click="keepPlaying()" class="button">Keep playing for fun</a>
          <a @click="customLogout()" class="button btnLogout">Log out</a>
        </div>
      </div>
      <div class="controls" v-if="gameEndAllMatches">
        <p>Want to play again for fun?</p>
        <div class="controls-actions">
          <a @click="keepPlaying()" class="button">Keep playing for fun</a>
          <a @click="customLogout()" class="button btnLogout">Log out</a>
        </div>
      </div>
    </div>
    <div class="marchScratchnessFooter">
      <div class="leftside">
        <h3>$600 in total prizes!</h3>
        <p>Three (3) winners will win one (1) book of Triple Golden Cherries Scratch-Off tickets (valued at $200 each). Winners announced April&nbsp;7.</p>
        <p><a href="/march-matchness/march-matchness-promotion-rules">See complete game rules</a></p>
      </div>
      <div class="rightside">
        <img src="/getattachment/f770ef63-3819-4536-9a9d-5a08437aae8f/march-scratchness-tickets_v2.png" alt="Scratch-Offs Ticket Fan">
      </div>
    </div>
  </div>
</template>
<script>
import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';

export default {
  name: 'MatchingGame',
  components: {
    SignInRegister
  },
  data() {
    return {
      images: [
        { id: 1, img: '/getattachment/1ba53b12-2085-4871-a9bf-28dbe87f5386/card-black-heart.png', isDisabled: false },
        { id: 2, img: '/getattachment/6c4c0ca0-b9ae-4456-b545-fe354322c1aa/card-diamond.png', isDisabled: false },
        { id: 3, img: '/getattachment/c987ac12-1bab-42c2-9614-890a671c42b2/card-gold-cherry.png', isDisabled: false },
        { id: 4, img: '/getattachment/a30861ea-4db7-4529-b8cf-5b71ece861b7/gold-heart_2x_v2.png', isDisabled: false },
        { id: 5, img: '/getattachment/de3c9f65-ccdd-4e7a-b96c-9dcd8d3a88e2/card-lucky-spot.png', isDisabled: false },
        { id: 6, img: '/getattachment/eeea3418-5cf2-4c2c-964e-3ec075d43b68/card-green-dollar.png', isDisabled: false }
      ],
      cards: [],
      flippedIndices: [],
      matches: 0,
      isUserLogged: false,
      turn: 0,
      firstMatch: false,
      noMatchFound: false,
      loginErrorMsg: '',
      loginError: false,
      drawingId: 2833, //2570 is for DEV-UAT, needs to be 2833 for production
      id: '',
      token: '',
      isAnimating: true,
      animatedCards: [
        {
          background: '/getattachment/1ba53b12-2085-4871-a9bf-28dbe87f5386/card-black-heart.png'
        },
        {
          background: '/getattachment/1ba53b12-2085-4871-a9bf-28dbe87f5386/card-black-heart.png'
        },
        {
          background: '/getattachment/6c4c0ca0-b9ae-4456-b545-fe354322c1aa/card-diamond.png'
        },
        {
          background: '/getattachment/6c4c0ca0-b9ae-4456-b545-fe354322c1aa/card-diamond.png'
        },
        {
          background: '/getattachment/eeea3418-5cf2-4c2c-964e-3ec075d43b68/card-green-dollar.png'
        },
        {
          background: '/getattachment/eeea3418-5cf2-4c2c-964e-3ec075d43b68/card-green-dollar.png'
        },
        {
          background: '/getattachment/c987ac12-1bab-42c2-9614-890a671c42b2/card-gold-cherry.png'
        },
        {
          background: '/getattachment/c987ac12-1bab-42c2-9614-890a671c42b2/card-gold-cherry.png'
        },
        {
          background: '/getattachment/de3c9f65-ccdd-4e7a-b96c-9dcd8d3a88e2/card-lucky-spot.png'
        },
        {
          background: '/getattachment/de3c9f65-ccdd-4e7a-b96c-9dcd8d3a88e2/card-lucky-spot.png'
        },
        {
          background: '/getattachment/a30861ea-4db7-4529-b8cf-5b71ece861b7/gold-heart_2x_v2.png'
        },
        {
          background: '/getattachment/a30861ea-4db7-4529-b8cf-5b71ece861b7/gold-heart_2x_v2.png'
        }
      ],
      gameEndAllMatches: false
    };
  },
  computed: {
    shuffledCards() {
      return this.cards.sort(() => Math.random() - 0.5);
    }
  },
  methods: {
    getToken() {
      this.getAPItoken()
        .then((token) => {
        this.token = token;
      }).then(() => {
        this.id = this.getLoggedInUserId();
      }).then(() => {
        this.checkIfUserEntered();
      })
    },
    initializeGame() {
      this.marchMatchnessAnimation();
      this.cards = this.images.concat(this.images).map(card => ({
        ...card,
        flipped: false,
        matched: false
      }));
      this.matches = 0;
      this.flippedIndices = [];
      this.turn = 0;
      this.gameEndAllMatches = false;
      this.noMatchFound = false;
    },
    flipCard(index) {
      if (this.flippedIndices.length < 2 && !this.cards[index].flipped) {
        this.cards[index].flipped = true;
        this.flippedIndices.push(index);
        if (this.flippedIndices.length === 2) {
          this.checkMatch();
        }
      }
    },
    checkMatch() {
      //if we are checking match - then increment turn count
      this.turn = this.turn + 1;
      console.log("Turn");
      console.log(this.turn);
      const [firstIndex, secondIndex] = this.flippedIndices;
      if (this.cards[firstIndex].id === this.cards[secondIndex].id) {
        this.cards[firstIndex].matched = true;
        this.cards[secondIndex].matched = true;
        this.matches += 1;
        //check for first match here
        if (this.matches > 0) {
          console.log(this.flippedIndices);
        }

        if (this.matches === 1) {
          setTimeout(() => {
            this.firstMatch = true;
          }, 1000)
        }
        if (this.matches >= 2) {
          setTimeout(() => {
            this.firstMatch = false;
          }, 1000)
        }

        if (this.matches === this.images.length) {
          setTimeout(() => {
            // alert('You found all matches! Play again?');
            // this.resetGame();
            this.gameEndAllMatches = true;
          }, 500);
        }
      } else {
        // here we need to disable the remaining cards to highlight the two that were flipped, maybe up the timeout duration below as well?
        this.cards.forEach((card) => {
          if (card.flipped !== true) {
            card.isDisabled = true;
          }
        });
        setTimeout(() => {
          this.cards[firstIndex].flipped = false;
          this.cards[secondIndex].flipped = false;
          // reenable the "disabled" cards
          this.cards.forEach((card) => {
            if (card.isDisabled) {
              card.isDisabled = false;
            }
          });
        }, 1000); //default 1000, try 3000, 10000 for checking indexes
      }
      this.flippedIndices = [];

      if (this.turn === 3) {
          console.log("Turn 3 Stats");
          console.log("Matches: " + this.matches);
        }
      //test it this way
      if (this.turn === 3 && this.matches === 0) {
        setTimeout(() => {
          this.noMatchFound = true;
        }, 1000);
      }
    },
    resetGame() {
      this.initializeGame();
    },
    customSignIn() {
      let username = document.querySelector("input[name=customUsername]").value;
      let password = document.querySelector("input[name=customPassword]").value;

      let protocol = this.getProtocol();

      let loginCreds = {
        "userName": username,
        "password": password,
        "refreshToken": "string",
        "newTermsAndConditionsAccepted": true
      }

      return fetch(protocol+process.env.VUE_APP_APIURL+'/1.0/Authentication/Login', {
        method: 'POST',
        body: JSON.stringify(loginCreds),
        headers: {
          'Content-Type': 'application/json-patch+json',
        }
      })
      .then(response => {
        console.log("Response");
        console.log(response);
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Data API access is temporarily down. Please try again later.');
        }
      })
      .then(response => {
        var tokenObj = {};
        tokenObj = response.data;
        console.log("Token Object");
        console.log(tokenObj);

        if (tokenObj.isAuthenticated) {
          //got a success
          this.loginError = false;
          //set the token
          this.$cookies.set('apiToken', tokenObj, '10d', null, null, true, 'Strict');
          setTimeout(() => {
            window.location.reload();
          }, 2000)

        } else {
          this.loginErrorMsg = tokenObj.message;
          this.loginError = true;
        }
      })
    },
    messageCenterTxt(numOfMatches) {
      let msgCenterTxt;
      switch(numOfMatches) {
        case 0:
          if (this.turn === 0) {
            msgCenterTxt = 'Flip 2 cards to try to find two matching icons from one of our new Scratch-Off games. You get 3 chances.';
          } else if (this.turn === 1) {
            msgCenterTxt = 'No match this time. You have 2 more chances.';
            setTimeout(() => {
              msgCenterTxt = 'Flip 2 more.'
            }, 3000);
          } else if (this.turn === 2) {
            msgCenterTxt = 'So close! You still have another try.';
            setTimeout(() => {
              msgCenterTxt = 'Flip 2 more.';
            }, 3000);
          } else if (this.turn === 3) {
            msgCenterTxt = 'Nice try.';
          } else {
            msgCenterTxt = 'Keep matching for fun.';
            setTimeout(() => {
              msgCenterTxt = 'Flip 2 more.';
            }, 3000);
          }
        break;
        case 1:
          msgCenterTxt = 'YOU MADE A MATCH!';
          setTimeout(() => {
              msgCenterTxt = 'Flip 2 more.';
            }, 3000);
        break;
        case 2:
          msgCenterTxt = 'Keep matching for fun.';
          setTimeout(() => {
              msgCenterTxt = 'Flip 2 more.';
            }, 3000);
        break;
        case 3:
          msgCenterTxt = 'Keep matching for fun.';
          setTimeout(() => {
              msgCenterTxt = 'Flip 2 more.';
            }, 3000);
        break;
        case 4:
          msgCenterTxt = 'Keep matching for fun.';
          setTimeout(() => {
              msgCenterTxt = 'Flip 2 more.';
            }, 3000);
        break;
        case 5:
          msgCenterTxt = 'Keep matching for fun.';
          setTimeout(() => {
              msgCenterTxt = 'Flip 2 more.';
            }, 3000);
        break;
        case 6:
          msgCenterTxt = "You're a matching machine!";
        break;
      }
      return msgCenterTxt;
    },
    keepPlaying() {
      this.firstMatch = false;
      this.noMatchFound = false;
      if (this.gameEndAllMatches) {
        this.resetGame();
      }
    },
    customLogout() {
      let logoutBtn = document.querySelector('.sign-out-button');

      if (logoutBtn !== null) {
        logoutBtn.click();
      } else {
        console.log("Something went wrong logging out");
      }

    },
    enterUserIntoDrawing() {
            let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/AddDrawingEntries?entries=1&drawingId=' + this.drawingId +'&userID=' + this.id;

            fetch(url, {
              method: 'POST',
              headers: {
                // 'Content-Type': 'application/json-patch+json',
                'Authorization': "Bearer " + this.token
              }
            })
              .catch(error => {
                console.log(error);
              })
          },
          checkIfUserEntered() {
            let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/GetActiveDrawingEntriesByUserId/' + this.id;

            fetch(url, {
              method: 'GET',
              headers: {
                // 'Content-Type': 'application/json-patch+json',
                'Authorization': "Bearer " + this.token
              }
            })
              .then((response) => response.json())
              .then((json) => {
                if (json.data.length) {
                  this.processEntries(json.data);
                } else {
                  this.processEntries(json.data);
                }
              })
              .catch(error => {
                console.log(error);
              })
          },
          processEntries(arr) {
            let allowEntry = true;
            arr.forEach(element => {
              if (element.entryCount > 0 && element.plainDrawingID === this.drawingId) {
                console.log('Already entered');
                allowEntry = false;
              }
            });
            if (allowEntry) {
              this.enterUserIntoDrawing();
            }
          },
          displayResults(matches) {
            let numOfMatches = matches;

            if (numOfMatches === 1) {
              setTimeout(() => {
                this.firstMatch = true;
              }, 5000)
            }
            if (numOfMatches >= 2) {
              setTimeout(() => {
                this.firstMatch = false;
              }, 5000)
            }
            if(numOfMatches === this.images.length) {
              setTimeout(() => {
                alert("Game Over");
              }, 5000)
            }
          },
          marchMatchnessAnimation() {
            this.isAnimating = true;
            setTimeout(() => {
              document.querySelectorAll('.marchMatchnessAnimate .card').forEach((card) => {
                card.style.backgroundImage = "url('/getattachment/ac23c1d4-d20f-4f5d-91cd-7af04adfc8d8/tile-back.png')";
                //perform the animation function
                let animationInterval = setInterval(() => {
                  this.animateShuffle();
                }, 2000);

                //comment out to debug animation
                setTimeout(() => {
                  clearInterval(animationInterval);
                  this.isAnimating = false;
                }, 6000);
              });
            }, 1000);
          },
          getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1) + min);
          },
          animateShuffle() {
            var animationContainer = document.querySelector('.marchMatchnessAnimate');
            var allCards = document.querySelectorAll('.animatingCards');

            if (animationContainer === null) {
              // alert("The animation container is null for some reason - figure out why");
            } else {
              animationContainer.classList.add('shuffle');
              allCards.forEach((card) => {
              let random = this.getRandomIntInclusive(1, 12);
              if (!card.classList.contains('shift')) {
                card.classList.add('shift');
                card.classList.add(`shift-${random}`);
                // card.style.position = 'relative';
                // card.style.zIndex = `${random}`;
              } else {
                // card.style.position = 'relative';
                // card.style.zIndex = `${random}`;
              }
            });
            setTimeout(() => {
              animationContainer.classList.remove('shuffle');
            }, 1000);
            }

            // animationContainer.classList.add('shuffle');
            // allCards.forEach((card) => {
            //   let random = this.getRandomIntInclusive(1, 12);
            //   if (!card.classList.contains('shift')) {
            //     card.classList.add('shift');
            //     card.classList.add(`shift-${random}`);
            //     card.style.position = 'relative';
            //     card.style.zIndex = `${random}`;
            //   } else {
            //     card.style.position = 'relative';
            //     card.style.zIndex = `${random}`;
            //   }
            // });
            // setTimeout(() => {
            //   animationContainer.classList.remove('shuffle');
            // }, 1000);
          }
  },
  created() {
    this.initializeGame();
  },
  mounted() {
    this.isUserLogged = this.isUserAuthed();
    console.log("User is logged in : " + this.isUserLogged);
    this.getToken();

    let gblLoginBlock  = document.querySelector('#globalLoginBlocker');

    if (gblLoginBlock !== null && !gblLoginBlock.classList.contains('mmGlobalLogin')) {
      gblLoginBlock.classList.add('mmGlobalLogin');
    }
  }
};
</script>
<style lang="scss" scoped>
.matching-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  max-width: 320px;
  margin: 0 auto;

  @media only screen and (min-width: 480px) {
    width: 480px;
    max-width: 480px;
  }
  @media only screen and (min-width: 641px) {
    width: 634px;
    max-width: 634px;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 320px;
  gap: 10px;
  margin-top: 3rem;

  // @media only screen and (min-width: 641px) {
  //   margin-left: 40px;
  // }
  @media only screen and (min-width: 480px) {
    max-width: 480px;
  }
  @media only screen and (min-width: 641px) {
    max-width: 634px;
  }
}

.card {
  width: 80px;
  height: 80px;
  perspective: 1000px;
  cursor: pointer;
  flex-grow: unset;
  margin: 0 auto;
  transition: 500ms;

  @media only screen and (min-width: 480px) {
    width: 120px;
    height: 120px;
  }
  @media only screen and (min-width: 641px) {
    width: 140px;
    height: 140px;
  }
}

.card.msCard {
  background: transparent;
  border: 1px solid transparent;
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transition: 500ms;
}

.card-front {
  transform: rotateY(180deg);
}

.card-back {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-size: 24px;
}

.card.flipped .card-front {
  transform: rotateY(0deg);
}

.card.flipped .card-back {
  transform: rotateY(-180deg);
}

// .controls {
//   margin-top: 20px;
// }

/* Custom Sign In */
.socialCustomSignIn {
  border: 6px solid #FFA84E;
  border-radius: 16px;
  width: 100%;
  max-width: 758px;
  margin: 0 auto;
  padding: 2rem 3rem;

  @media only screen and (min-width: 641px) {
    padding: 4rem 6rem;
  }

  h3 {
    color: #fff;
    font-weight: 700;

    @media only screen and (min-width: 641px) {
      font-size: 3.7rem;
    }
  }

  .fields {
    input {
      border-radius: 6px;
      font-size: 1.2rem;
      font-weight: 500;
      text-indent: 2.2rem;
      margin-bottom: 1.6rem;

      @media only screen and (min-width: 641px) {
        font-size: 2.4rem;
        margin-bottom: 3rem;
        padding-top: 24px;
        padding-bottom: 26px;
      }
    }
  }

  .actions {
    border-bottom: 1px solid #fff;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 641px) {
      flex-direction: row;
      align-items: center;
    }

    a.button {
      background-color: #C12637;
      border: 4px solid #fff;
      font-size: 1.4rem;
      text-transform: capitalize;
      font-weight: 700;
      border-radius: 25px;
      width: 125px;
      height: 25px;
      letter-spacing: normal;
      margin-right: 20px;

      @media only screen and (min-width: 641px) {
        font-size: 2.1rem;
        width: 250px;
        height: 58px;
        margin-bottom: 0;
      }
    }

    a.customForgotPassword {
      color: #fff;
      font-size: 1.4rem;
      font-weight: 500;

      @media only screen and (min-width: 641px) {
        font-size: 2.1rem;
      }
    }
  }

  p {
    font-weight: 500;
    font-size: 1.4rem;
    color: #fff;

    @media only screen and (min-width: 641px) {
      font-size: 2.1rem;
    }

    a {
      color: #fff;
    }
  }
}

#userLoginWrap .userLoginIntro {
  display: none;
}

.message-center {
  width: 100%;
  max-width: 739px;
  margin: 0 auto;
}
.message-center p {
  font-family: 'Quicksand', Roboto, sans-serif;
  font-size: 1.8rem;
  color: #fff;
  text-align: center;
  font-weight: 600;
  // margin-bottom: 3rem;

  @media only screen and (min-width: 641px) {
    font-size: 2.6rem;
  }
}

.marchScratchnessFooter {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;

  @media only screen and (min-width: 641px) {
    flex-direction: row;
  }

  h3 {
    color: #fff;
    font-size: 2.2rem;
    font-weight: 700;
    text-shadow: 4px 2px 4px rgba(0,0,0,0.5);

    @media only screen and (min-width: 641px) {
      font-size: 4.2rem;
    }
  }

  p {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 400;

    @media only screen and (min-width: 641px) {
      font-size: 2.1rem;
    }

    a {
      color: #fff;
    }
  }

  .leftside {
    text-align: center;

    @media only screen and (min-width: 641px) {
      text-align: left;
      width: 40%;
    }
  }

  .rightside {
    
    @media only screen and (min-width: 641px) {
      width: 60%;
    }
  }
}
.controls img {
  margin: 0 auto 20px;
  display: block;
}
.controls p {
  font-family: 'Quicksand', Roboto, sans-serif;
  color: #fff;
  max-width: 739px;
  margin: 0 auto 20px;
  text-align: center;
  font-weight: 500;
  font-size: 1.6rem;

  @media only screen and (min-width: 641px) {
    font-size: 2.1rem;
  }
}
.controls a.button {
  background-color: #C12637;
  border: 4px solid #fff;
  font-size: 1.4rem;
  text-transform: capitalize;
  font-weight: 700;
  border-radius: 25px;
  letter-spacing: normal;
}
.controls a.button.btnLogout {
  background-color: transparent;
}
.controls-actions {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 641px) {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }
}

.marchMatchnessAnimate {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10px;
  width: 148px;
  max-width: 148px;
  min-height: 460px;
  margin: 0 auto;
  transition: grid-template-columns 250ms ease;

  @media only screen and (min-width: 480px) {
    width: 360px; //was 480
    max-width: 360px;
  }

  @media only screen and (min-width: 641px) {
    width: 500px; //was 634
    max-width: 500px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.marchMatchnessAnimate .card {
  width: 80px;
  max-width: 80px;
  height: auto;
  aspect-ratio: 1/1;
  // transform: translate(-20px);
  transition: 250ms ease;
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (min-width: 480px) {
    width: 120px;
    max-width: 120px;
  }

  @media only screen and (min-width: 641px) {
    width: 140px;
    max-width: 140px;
  }
}

.marchMatchnessAnimate.shuffle {
  place-items: center;
  grid-template-columns: 1fr;
}

.marchMatchnessAnimate.shuffle .card {
  grid-area: 1/1;
  transition: 250ms ease;
}

.marchMatchnessAnimate.shuffle .card.shift-1 {
  transform: rotate(10deg);

  &.shift-back {
    transform: rotate(-20deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-2 {
  transform: rotate(12deg);

  &.shift-back {
    transform: rotate(-24deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-3 {
  transform: rotate(24deg);

  &.shift-back {
    transform: rotate(-48deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-4 {
  transform: rotate(36deg);
  &.shift-back {
    transform: rotate(-72deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-5 {
  transform: rotate(48deg);
  
  &.shift-back {
    transform: rotate(-96deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-6 {
  transform: rotate(52deg);
  
  &.shift-back {
    transform: rotate(-104deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-7 {
  transform: rotate(60deg);
  
  &.shift-back {
    transform: rotate(-120deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-8 {
  transform: rotate(72deg);
  
  &.shift-back {
    transform: rotate(-144deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-9 {
  transform: rotate(84deg);
  
  &.shift-back {
    transform: rotate(-168deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-10 {
  transform: rotate(96deg);
  
  &.shift-back {
    transform: rotate(-202deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-11 {
  transform: rotate(108deg);
  
  &.shift-back {
    transform: rotate(-216deg);
  }
}

.marchMatchnessAnimate.shuffle .card.shift-12 {
  transform: rotate(120deg);
  
  &.shift-back {
    transform: rotate(-240deg);
  }
}

</style>