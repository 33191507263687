<template>
    <div class="lottCashOut module_callOut" v-if="stock === false" v-html="message">
    </div>
</template>

<script>
    export default {
        components:{
        },
        data: function () {
            return {
                token: '',
                module: '',
                message: '',
                stock: true
            }
        },
        mounted: function () {
            this.isUserLogged = this.isUserAuthed();            
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    // this.fetchData(token);
                    this.token = token;
                    this.selectSection();
                });
            },
            selectSection() {
                let url = window.location.pathname,
                    _this = this;
                
                if (url.indexOf('coupons-and-lottery-cash') > 1) {
                    this.module = 'lcnc';
                    // this.fetchLCnC();
                }

                if (url.indexOf('catalog') > 1) {
                    this.module = 'catalog';
                    // this.fetchPhysical();
                }

                if (url.indexOf('digital-catalog') > 1) {
                    this.module = 'digital';
                    // this.fetchDigital();
                }

                setTimeout(function() {
                    _this.setStock();
                }, 2500);
            },
            setStock() {
                let stock = document.getElementsByClassName('productItem');

                // console.log(stock);
                // console.log(stock.length);

                stock.length < 1 ? this.stock = false : false;
                this.stock === false ? this.getStatic() : false;
            },
            getStatic() {
                var apiToken = this.token,
                    url = process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=stock-' + this.module;

                return fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let data = json;
                    
                    this.message = data.data[0].centralContent;
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .lottoCashOut {
        margin-bottom: 20px;
        border-radius: 10px;
        background-color: #F1EfEE;
        padding: 20px;
        // Tablet-V and UP
        margin-right: 260px;
    }
</style>