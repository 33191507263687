<template>
    <div>
        <SignInRegister v-if="!isUserLogged"></SignInRegister>
        <TicketEntryForm v-if="isUserLogged" contentKey="cash-explosion-entry-text" requireDigits="3" placeholderProp="" headingProp=""
            submitTextProp="">
        </TicketEntryForm>
        <!-- <CashExplosionMLRContent v-if="isUserLogged && tEntry === 'false'"></CashExplosionMLRContent> -->
    </div>
</template>

<script>
import SignInRegister from '../../../../ViewComponents/SignInRegister/SignInRegister.vue'
import TicketEntryForm from '../../../TicketEntry/TicketEntry.vue';
import CashExplosionMLRContent from './CashExplosionMLRContent.vue';
export default {
    components: {
        TicketEntryForm,
        CashExplosionMLRContent,
        SignInRegister
    },
    data: function () {
        return {
            isUserLogged: false,
            tEntry: false
        }
    },
    mounted: function () {
        this.isUserLogged = this.isUserAuthed();
        this.manualTicketEntry();
    },
    methods: {
        manualTicketEntry() {
            this.fetchContentFromAPI('manual-ticket-entry', 'manual-ticket-entry')
            .then(json => {
                this.tEntry = json.enabled;
                // console.log(this.tEntry)
                // this.fetchContentFromAPI('cash-explosion-body', 'cash-explosion-entry')
                // .then(json => {
                //     this.body = json;
                // });
            });
        }
    }
}
</script>