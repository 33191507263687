<template>
    <div class="check_your_numbers_panel" :class="gameName">
        <h3>Am I A Winner?</h3>
        <div class="panel_content">


            <div class="grid-x amIaWinner_form">
                <div class="cell medium-3">
                    <datepicker :disabledDates="disabledDates" placeholder="Select Date" :name="gameName + '_inputDate'"
                        :format="customFormatter"></datepicker>

                    <div class="pick_am_pm" v-if="showAmPm">
                        <div class="am">
                            <label for="midday">
                                <input id="midday" :name="gameName + '_pick_am_pm'" @change="pickAmPm($event)" type="radio"
                                    value="Midday" />
                                Midday
                            </label>
                        </div>
                        <div class="pm">
                            <label for="evening">
                                <input id="evening" :name="gameName + '_pick_am_pm'" @change="pickAmPm($event)" type="radio"
                                    value="Evening">
                                Evening
                            </label>
                        </div>
                    </div>
                </div>

                <div class="cell medium-7 enter-numbers grid-x">
                    <span class="enter-numbers-label small-12 large-5">Enter Your Numbers:</span>
                    <div class="numbers small-12 large-7">
                        <div v-for="(number, idx) in numbers" v-bind:key="idx" class="number_input_wrapper">
                            <input type="text" pattern="\d*" :name="gameName + '_number'" v-model="number.value"
                                maxlength="2" class="number_input" :id="'eYN-' + idx">
                        </div>
                    </div>
                </div>

                <div class="cell medium-2 btn-go">
                    <button class="button" @click="go">Go</button>
                </div>
            </div>

            <span class="error-message" v-if="showErrorMessage"> {{ messageErrorTxt }} </span>


            <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />

            <div class="grid-x solid-top-border amIaWinner_form align-bottom amIaWinner_winningNumber"
                v-if="showMatchNumbers">

                <div class="cell medium-3"></div>
                <div class="cell medium-7 winning-numbers grid-x">
                    <span class="winning-numbers-label small-12 large-4">Winning Numbers:</span>
                    <div class="numbers small-12 large-8">
                        <div v-for="(wNumber, index) in winningNumbers" v-bind:key="index"
                            class="number_input_wrapper winningNumbersInputs" :class="[`winningNumbersInputs-${index}`]">
                            <input type="text" name="number" :value="wNumber" :data-value="wNumber"
                                :class="[`numberPos-${index}`, `input-${index}`]" class="number_input" readonly />
                        </div>
                    </div>
                </div>
                <div class="cell medium-2">
                    <div class="special-number text-center" v-if="gameName == 'megaMillions' && megaSpecialValue != '-1'">
                        <span class="extraLabel">Megaplier: </span> <span class="extraVal">{{ megaSpecialValue }}</span>
                    </div>

                    <div class="special-number text-center" v-if="gameName == 'powerBall'">
                        <span class="extraLabel">PowerPlay: </span> <span class="extraVal">{{ powerSpecialValue }}</span>
                    </div>

                    <div class="special-number text-center classicKicker" v-if="gameName == 'classicLotto'">
                        <span class="extraLabel">KICKER: </span> <span class="extraVal">{{ classicSpecialValue }}</span>
                    </div>
                </div>

            </div>

            <div class="winMsg solid-top-border amIaWinner_form" v-if="winnerMessage">
                <h2>Congratulations!</h2>

                <!-- <p v-if="prizeName.length"> {{ prizeName }}</p> -->

                <!-- <p>Winner Prize: {{ winnerPrize }}</p> -->

                <p>It looks like you may have a winner. Please verify that the drawing date matches the drawing date on your
                    ticket.
                    To find out how to claim your prize, <a href="/claiming/how-to-claim" target="_blank">click here</a>.
                </p>
            </div>


            <div class="winMsg solid-top-border amIaWinner_form" v-if="notWinnerMessage">
                <h2>Better Luck Next Time!</h2>
                <p>We're sorry, but your numbers did not win any prizes for this game. </p>
            </div>

        </div>
    </div>
</template>

<script>
import { HollowDotsSpinner } from 'epic-spinners'
import Datepicker from "vue-moment-datepicker";
import moment from 'moment';

export default {
    components: {
        Datepicker,
        HollowDotsSpinner
    },
    props: ['gameName', 'inputsQuantity'],
    data: function () {
        return {
            showMatchNumbers: false,
            showErrorMessage: false,
            winnerMessage: false,
            notWinnerMessage: false,
            showAmPm: false,
            isLoading: false,
            modifier: 0,
            messageErrorTxt: '',
            game: this.gameName,
            megaSpecialValue: '',
            powerSpecialValue: '',
            classicSpecialValue: '',
            disabledDates: {
                from: new Date()
            },
            amountInputs: this.inputsQuantity,
            numbers: [],
            winningNumbers: [],
            winnerPrize: '',
            prizeName: '',
            numbFixCompare: null
        }
    },
    mounted: function () {

        if (this.game.indexOf('pick') >= 0) {
            this.showAmPm = true;
        }

        let number = []

        let amountInputs = this.amountInputs;

        for (var i = 0; i < amountInputs; i++) {
            number.push({
                "value": undefined,
                "index": i
            })
        }

        this.numbers = number;
    },
    methods: {
        pickAmPm(event) {
            let data = event.target.value;

            // let inputCalendar = document.querySelector("input[name="+ this.game +"_inputDate]");

            // if(inputCalendar.value.length !== 0  ){
            if (data == 'Midday') {
                this.modifier = 1
            } else if (data == 'Evening') {
                this.modifier = 2
            }
            // }
        },
        customFormatter(date) {
            return moment(date).format('yyyy-MM-DD');
        },
        go() {

            let gameSelected = '';
            gameSelected = this.game;

            // console.log(gameSelected, '---------');
            this.isLoading = true;


            let inputPickAmPm = document.querySelectorAll("input[name=" + gameSelected + "_pick_am_pm]");



            if (this.game.indexOf('pick') >= 0) {
                if (document.querySelector("input[name=" + gameSelected + "_inputDate]").value.length === 0
                    || !(inputPickAmPm[0].checked || inputPickAmPm[1].checked)
                    || document.querySelector("input[name=" + gameSelected + "_number]").value.length === 0) {
                    this.isLoading = false;
                    this.showMatchNumbers = false;
                    this.showErrorMessage = true;
                    this.winnerMessage = false;
                    this.notWinnerMessage = false;
                    this.messageErrorTxt = "Invalid Inputs. Please try again"
                } else {
                    this.showErrorMessage = false;
                    this.getToken();
                }

            } else {
                if (document.querySelector("input[name=" + gameSelected + "_inputDate]").value.length === 0
                    || document.querySelector("input[name=" + gameSelected + "_number]").value.length === 0) {
                    this.isLoading = false;
                    this.showMatchNumbers = false;
                    this.showErrorMessage = true;
                    this.messageErrorTxt = "Invalid Inputs. Please try again"
                } else {
                    this.showErrorMessage = false;
                    this.getToken();
                }
            }



        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

        },
        fetchData(token) {
            let gameSelected = '';
            gameSelected = this.game;

            const apiToken = token;
            const urlProtocol = this.getProtocol();
            let gameNameApi = this.game;

            let selectDate = document.querySelector("input[name=" + gameSelected + "_inputDate]").value;

            const bodyApi = {
                "date": selectDate,
                "numbers": this.numbers,
                "modifier": this.modifier
            }

            // {
            //     "date": "2023-07-05T07:32:40.547Z",
            //     "period": 2,
            //     "numbers": [
            //         {
            //             "value": "2",
            //             "index": 0
            //         },
            //         {
            //             "value": "3",
            //             "index": 1
            //         },
            //         {
            //             "value": "3",
            //             "index": 2
            //         }
            //     ],
            //     "modifier": 2
            // }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                },
                body: JSON.stringify(bodyApi)
            };

            if (this.game == 'megaMillions' ||
                this.game == 'powerBall' ||
                this.game == 'luckyForLife') {
                const valN = JSON.stringify(bodyApi.numbers[5].value);
                this.numbFixCompare = valN
            }

            fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + gameNameApi + "/CheckYourNumbers", requestOptions)
                .then(response => response.json())
                .then(data => {
                    let resultsArray = data.data;

                    if (resultsArray === null) {
                        this.showErrorMessage = true;
                        this.showMatchNumbers = false;
                        this.isLoading = false;
                        this.messageErrorTxt = "There are no results for this date. Please select another."
                        return false
                    } else {
                        this.showErrorMessage = false;
                        this.isLoading = false;
                        this.winningNumbers = resultsArray.winningNumbers;
                    }

                    this.showMatchNumbers = true;



                    let userNumbers = []
                    let resultOfMatch = []

                    this.numbers.forEach(function (item) {
                        userNumbers.push(parseInt(item.value))
                    });

                    if (this.game.indexOf('pick') >= 0) {

                        const matchNumbers = function (a1, a2) {
                            let result = [], longerLength = a1.length >= a2.length ? a1.length : a2.length;
                            for (var i = 0; i < longerLength; i++) {
                                if (a1[i] === a2[i]) {
                                    result.push(i);
                                }
                            }
                            return result;
                        };

                        resultOfMatch = matchNumbers(userNumbers, resultsArray.winningNumbers)


                    } else {

                        let rmLastIndex = [];

                        if (this.game == 'megaMillions' ||
                            this.game == 'powerBall') {
                            rmLastIndex = resultsArray.winningNumbers.slice(0, -1);
                        }
                        else {
                            rmLastIndex = resultsArray.winningNumbers
                        }

                        const findOne = (apiArr, arr) => {
                            return arr.filter(v => apiArr.includes(v));
                        };

                        const removeDuplicates = (arr) => {
                            return arr.filter((item,
                                index) => arr.indexOf(item) === index);
                        };



                        if (this.game == 'megaMillions' ||
                            this.game == 'powerBall' ||
                            this.game == 'luckyForLife') {


                            let removeSBuserInput = [];
                            let removeSBapi = [];
                            let userInputSB = [];
                            let apiSB = [];

                            if (this.game == 'megaMillions' ||
                                this.game == 'powerBall') {

                                removeSBuserInput = userNumbers.slice(0, -1);
                                removeSBapi = rmLastIndex.slice(0, -1);
                                userInputSB = userNumbers.pop();
                                apiSB = rmLastIndex.pop();
                            } else if (this.game == 'luckyForLife') {
                                removeSBuserInput = userNumbers.slice(0, -1);
                                removeSBapi = rmLastIndex.slice(0, -1);
                                userInputSB = userNumbers.at(-1);
                                apiSB = rmLastIndex.at(-1);


                            } else {
                                removeSBuserInput = userNumbers;
                                removeSBapi = rmLastIndex;
                                userInputSB = userNumbers;
                                apiSB = rmLastIndex;
                            }

                            let getMatched = findOne(removeSBuserInput, removeSBapi)

                            resultOfMatch = removeDuplicates(getMatched);

                            if (userInputSB == apiSB) {
                                resultOfMatch.push(apiSB)
                            }


                        } else {

                            let getMatchedValues = findOne(rmLastIndex, userNumbers);

                            resultOfMatch = removeDuplicates(getMatchedValues);

                        }

                        // handle KICKER for Classic Lotto
                        if (this.game == 'classicLotto') {
                            ///1.0/Games/DrawGames/Kicker/SearchByDate?sinceDate=yyyy-MM-dd
                            // console.log(selectDate);
                            fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/Kicker/SearchByDate?sinceDate=" + selectDate, {
                                headers: {
                                    'Authorization': 'Bearer ' + apiToken,
                                },
                            })
                            .then(response => response.json())
                            .then(data => {
                                // console.log(data);

                                if (data.statusCode == 200) {
                                    let kickerNumbers = '';
                                    data.data.draws[0].numbers.forEach(element => {
                                        kickerNumbers = kickerNumbers + element.value;
                                    });
                                    this.classicSpecialValue = kickerNumbers;
                                } else {
                                    this.classicSpecialValue = 'n/a';
                                }
                            })
                        }


                    }




                    fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + gameNameApi + "/GetGameInformation", {
                        headers: {
                            'Authorization': 'Bearer ' + apiToken,
                        },
                    })
                        .then(response => response.json())
                        .then(data => {
                            let res = data;
                            let prizes = {};

                            //console.log(res)

                            prizes = res.data.prizeStructureJSON;

                            let toJson = {};
                            let gameRules = {};

                            if (prizes.length !== 0) {
                                toJson = eval('(' + prizes + ')');
                                if (this.game == 'powerBall') {
                                    gameRules = toJson.Matches
                                } else {
                                    gameRules = toJson.Rules
                                }
                            }

                            let getAllInputs = document.querySelectorAll('.' + this.game + ' .winningNumbersInputs input');

                            Array.from(getAllInputs).forEach(function (el) {
                                if (el.classList.contains('winner-number')) {
                                    el.classList.remove('winner-number')
                                    el.nextSibling.remove()
                                }
                            })

                            // console.log(resultOfMatch)

                            for (var i = 0; i < resultOfMatch.length; i++) {
                                let correctResults;

                                if (this.game.indexOf('pick') >= 0) {
                                    correctResults = document.getElementsByClassName('input-' + resultOfMatch[i])
                                }
                                else {
                                    correctResults = document.querySelectorAll('[data-value="' + resultOfMatch[i] + '"]');
                                }

                                for (var j = 0; j < correctResults.length; j++) {
                                    correctResults[j].classList.add("winner-number");
                                }
                            }

                            // console.log(this.game);

                            // these games below have simple matching logic
                            if (this.game == 'megaMillions' ||
                                this.game == 'powerBall' ||
                                this.game == 'luckyForLife' ||
                                this.game == 'classicLotto' ||
                                this.game == 'rollingCashFive') {
                                let context = document.querySelector('.' + this.game + ' .winningNumbersInputs-6');
                                let specialBallInput = document.querySelector('.' + this.game + ' .winningNumbersInputs-5 input');
                                let specialValue = '';

                                if (context !== null) {
                                    specialValue = context.querySelector('input').value;

                                    if (this.game !== 'megaMillions') {
                                        this.powerSpecialValue = specialValue;
                                    } else {
                                        this.megaSpecialValue = specialValue
                                    }


                                }

                                if (this.game == 'megaMillions' ||
                                    this.game == 'powerBall' ||
                                    this.game == 'luckyForLife') {


                                    specialBallInput.classList.remove('numberPos-5')
                                    specialBallInput.classList.add('special-ball')


                                    let prizeJsonMatch = {
                                        WB: 0,
                                        CB: 0
                                    };

                                    if (document.querySelector('.' + this.game + ' .winningNumbersInputs-6')) {
                                        document.querySelector('.' + this.game + ' .winningNumbersInputs-6').remove();
                                    }

                                    // gather the winning numbers and check for marked winners
                                    let classSelector = "." + this.game + " .amIaWinner_form .winning-numbers [class*='numberPos']";
                                    //console.log("classSelector: "+classSelector);
                                    const classPos = document.querySelectorAll(classSelector);
                                    // console.log("classPos: "+classPos.length);
                                    const inputFilter = (classPos) => classPos.classList.contains('winner-number');
                                    const winnerWB = [...classPos].filter(inputFilter);

                                    //console.log(winnerWB)
                                    //console.log("winnerWB count: "+winnerWB.length);
                                    for (let n = 0; n < winnerWB.length; n++) {
                                        //console.log(winnerWB[n])
                                        winnerWB[n].insertAdjacentHTML("afterend", "<span class='winner-number'></span>");
                                    }
                                    prizeJsonMatch.WB = winnerWB.length;

                                    let gameNameTab = this.game;
                                    // console.log(document.querySelector('.' + this.game + ' .special-ball').classList)
                                    let found = false;
                                    Array.from(document.querySelector('.' + this.game + ' .special-ball').classList).forEach(function (el) {
                                        //console.log(gameNameTab)
                                        // console.log(el);
                                        if (el === 'winner-number') {
                                            // console.log('CB Found!')
                                            prizeJsonMatch.CB = 1
                                            document.querySelector('.' + gameNameTab + ' .special-ball').insertAdjacentHTML("afterend", "<span class='winner-number'></span>");
                                            found = true;
                                        } else if (found === false) {
                                            // console.log('No CB!')
                                            prizeJsonMatch.CB = 0
                                        } 
                                    })

                                    let fixString = this.numbFixCompare.replace(/['"]+/g, '');
                                    let valNumb = parseInt(fixString, 10);
                                    if ((valNumb) != this.winningNumbers[5]) {
                                        // document.querySelector('.amIaWinner_winningNumber .number_input_wrapper:last-child span.winner-number').style.display = 'none';
                                        // document.querySelector('.amIaWinner_winningNumber .winning-numbers .numbers .number_input_wrapper:last-child span.winner-number').style.display = 'none';

                                    } else {
                                        // document.querySelector('.amIaWinner_winningNumber .number_input_wrapper:last-child span.winner-number').style.display = 'block';
                                        document.querySelector('.amIaWinner_winningNumber .winning-numbers .numbers .number_input_wrapper:last-child span.winner-number').style.display = 'block';
                                    }



                                    // console.log(prizeJsonMatch)

                                    var dataRules = gameRules,
                                        search = prizeJsonMatch;
                                        // console.log(dataRules)
                                        // console.log(search)
                                    var result = dataRules.filter(function (o) {
                                            return Object.keys(search).every(function (k) {
                                                // console.log(o)
                                                // console.log(o[k])
                                                // console.log(search[k])
                                                // console.log(o[k] === search[k])
                                                return o[k] === search[k];
                                            });
                                        });
                                } else if (
                                    this.game == 'classicLotto' ||
                                    this.game == 'rollingCashFive') {

                                    //console.log('classic or rolling five' )


                                    let prizeJsonMatch = {
                                        WB: 0
                                    };

                                    // gather the winning numbers and check for marked winners
                                    let classSelector = "." + this.game + " .amIaWinner_form .winning-numbers [class*='numberPos']";
                                    //console.log("classSelector: "+classSelector);
                                    const classPos = document.querySelectorAll(classSelector);
                                    //console.log("classPos: "+classPos.length);
                                    const inputFilter = (classPos) => classPos.classList.contains('winner-number');
                                    const winnerWB = [...classPos].filter(inputFilter);

                                    //console.log(winnerWB)
                                    //console.log("winnerWB count: "+winnerWB.length);
                                    for (let n = 0; n < winnerWB.length; n++) {
                                        // console.log(winnerWB[n])
                                        winnerWB[n].insertAdjacentHTML("afterend", "<span class='winner-number'></span>");
                                    }
                                    prizeJsonMatch.WB = winnerWB.length;


                                    //console.log(prizeJsonMatch)

                                    var dataRules = gameRules,
                                        search = prizeJsonMatch,
                                        result = dataRules.filter(function (o) {
                                            return Object.keys(search).every(function (k) {
                                                return o[k] === search[k];
                                            });
                                        });

                                }

                            }



                            if (this.game.indexOf('pick') >= 0) {
                                // console.log(this.game)
                                const classPos = document.querySelectorAll("." + this.game + " [class*='numberPos']");
                                const inputFilter = (classPos) => classPos.classList.contains('winner-number');
                                const winnerPicks = [...classPos].filter(inputFilter);



                                for (let m = 0; m < winnerPicks.length; m++) {
                                    winnerPicks[m].insertAdjacentHTML("afterend", "<span class='winner-number'></span>");
                                }

                                let arrOfPicks = [];
                                let arrOfRulesPosition = [];

                                let filterByMatchPosition = gameRules.filter(function (v) { return v.MatchPositions == true });

                                for (let i = 0; i < winnerPicks.length; i++) {
                                    let getWinPos = parseInt(winnerPicks[i].classList[1].replace(/[^0-9]/g, ''));
                                    // console.log(winnerPicks[i].classList)

                                    arrOfPicks.push(getWinPos)
                                }

                                //console.log(arrOfPicks)

                                for (let k = 0; k < filterByMatchPosition.length; k++) {
                                    let getRulesPositions = filterByMatchPosition[k].Positions;
                                    arrOfRulesPosition.push(getRulesPositions)

                                }


                                let set2 = new Set(arrOfPicks),
                                    resultPickGames = filterByMatchPosition.filter(({ Positions }) =>
                                        Positions.length === set2.size && Positions.every(Set.prototype.has, set2));

                                //console.log(set2)
                                //console.log(resultPickGames)
                                //console.log(filterByMatchPosition)

                                if (this.game.indexOf('pick5') >= 0) {
                                    // console.log('Pick 5');
                                    if (resultOfMatch.length > 1) {
                                        let draw = resultsArray.winningNumbers,
                                            result = {
                                                prize: {
                                                    Prize: ''
                                                },
                                                prizeName: ''
                                            };
                                        // console.log(draw)
                                        let num1 = draw[0].value,
                                            num2 = draw[1].value,
                                            num3 = draw[2].value,
                                            num4 = draw[3].value,
                                            num5 = draw[4].value;

                                        let uNum1 = this.numbers[0].value,
                                            uNum2 = this.numbers[1].value,
                                            uNum3 = this.numbers[2].value,
                                            uNum4 = this.numbers[3].value,
                                            uNum5 = this.numbers[4].value;

                                        if (num1 == uNum1 && num2 == uNum2 && num3 == uNum3 && num4 == uNum4) {
                                            result.prizeName = 'Front Four';
                                            result.prize.Prize = '$5,000';
                                        } else if (num2 == uNum2 && num3 == uNum3 && num4 == uNum4 && num5 == uNum5) {
                                            result.prizeName = 'Back Four';
                                            result.prize.Prize = '$5,000';
                                            draw.numbers[4].match = true;
                                        } else if (num1 == uNum1 && num2 == uNum2 && num3 == uNum3) {
                                            result.prizeName = 'Front Three';
                                            result.prize.Prize = '$500';
                                            draw.numbers[3].match = false;
                                            draw.numbers[4].match = false;
                                        } else if (num3 == uNum3 && num4 == uNum4 && num5 == uNum5) {
                                            result.prizeName = 'Back Three';
                                            result.prize.Prize = '$500';
                                            draw.numbers[0].match = false;
                                            draw.numbers[1].match = false;
                                            draw.numbers[2].match = true;
                                            draw.numbers[3].match = true;
                                            draw.numbers[4].match = true;
                                        } else if (num1 == uNum1 && num2 == uNum2) {
                                            result.prizeName = 'Front Pair';
                                            result.prize.Prize = '$50';
                                            draw.numbers[2].match = false;
                                            draw.numbers[3].match = false;
                                            draw.numbers[4].match = false;
                                        } else if (num4 == uNum4 && num5 == uNum5) {
                                            result.prizeName = 'Back Pair';
                                            result.prize.Prize = '$50';
                                            draw.numbers[0].match = false;
                                            draw.numbers[1].match = false;
                                            draw.numbers[2].match = false;
                                            draw.numbers[3].match = true;
                                            draw.numbers[4].match = true;
                                        }

                                        this.winnerMessage = true;
                                        this.winnerPrize = result.prize.Prize;
                                        this.prizeName = result.prizeName;
                                        this.notWinnerMessage = false;

                                        return;
                                    }
                                }

                                if (resultPickGames && resultPickGames.length > 0) {
                                    this.winnerMessage = true;
                                    this.winnerPrize = resultPickGames[0].Prize
                                    this.prizeName = resultPickGames[0].Name
                                    this.notWinnerMessage = false;

                                    return
                                } else {
                                    this.winnerMessage = false;
                                    this.notWinnerMessage = true;

                                    return
                                }


                            }

                            // console.log(result);

                            if (result && result.length > 0) {
                                this.winnerMessage = true;
                                this.winnerPrize = result[0].Prize
                                this.notWinnerMessage = false;
                            } else {
                                this.winnerMessage = false;
                                this.notWinnerMessage = true;
                            }


                    })

                });
        }
    },
    computed: {
    },
    watch: {
        game: function () {
            if (this.game.indexOf('pick') >= 0) {
                this.showAmPm = true;
            }
        }
    }

};

</script>

<style scoped></style>