<template>
    <div class="plain-drawing-preview-area">
        <div v-if="drawingId === '' || drawingId === null">
            Please check the URL for a missing Drawing Id.
        </div>
        <div class="drawingDetail" v-else>
            <div>Drawing preview for drawing - {{ drawingId }}.</div>
            <h1>{{ previewedDrawing.drawingName }}</h1>
            <section class="drawingEntry">
                <section class="productImg cf">
                    <a href="#" class="productDetailImg">
                        <img :src="previewedDrawing.drawingDetailImage" alt="Drawing Detail Image">
                    </a>
                </section>
                <section class="productOrderForm cf">
                    <div class="CartItemSelectorContainer">
                        <div class="TotalPriceContainer">
                            <span class="TotalPrice">{{ previewedDrawing.price }} Points</span>
                            <span class="entryPeriod">
                                Entry Period:
                                <strong>{{ previewedDrawing.drawingStartDate }} - {{ previewedDrawing.drawingClosingDate }}</strong>
                            </span>
                        </div>
                        <hr>
                        <div class="AddToCartContainer add-to-card-container control-group-inline">
                            <span>
                                <span>Entries: </span>
                                <select class="entriesSelect">
                                    <option value="0">Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="2">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                                <button id="enterDrawing" class="btn_enterDrawingPop">Enter</button>
                            </span>
                        </div>
                    </div>
                </section>
                <section class="productDescription" v-html="drawingDescription"></section>
            </section>
        </div>
        <span class="error" v-if="fetchDataError">Error retrieving the Drawing</span>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                previewedDrawing: '',
                drawingId: '',
                fetchDataError: false,
                drawingDescription: ''
            }
        },
        created: function() {
            this.getIdFromURL();
        },
        mounted: function() {
            this.getToken();
            
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();

                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/PlainDraw/PlainDraw/GetDrawingById?drawingId="+this.drawingId, {
                    headers: {
                        'Authorization': 'Bearer ' +apiToken,
                    }
                })
                .then(response => response.json())
                .then(json => {
                    var results = json.data;
                    this.previewedDrawing = results;
                    console.log("The Drawing");
                    console.log(this.previewedDrawing);
                    // this.fetchDataError = false;

                    if (results === null) {
                        this.fetchDataError = true;
                    } else {
                        this.fetchDataError = false;
                        this.drawingDescription = this.previewedDrawing.drawingDetails;
                    }
                })
            },
            getIdFromURL() {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const paramValue = urlParams.get('drawingId');
                this.drawingId = paramValue;

                if (this.drawingId !== '' || this.drawingId !== null) {

                }
            }
        },
    }
</script>
<style lang="scss" scoped>

</style>