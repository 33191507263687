<template>
    <article id="homeplate_main" class="bitbat-container">
        <div style="height:250px;display:block;position:relative;background-color:white;">
            <input type="file" name="image" accept="image/*" capture="environment">
        </div>
        <div id="test-panel" v-if="testing || masterControl">
            <button class="button" @click="getState(0)">Panel 0</button>
            <button class="button" @click="getState(1)">Panel 1</button>
            <button class="button" @click="getState(2)">Panel 2</button>
            <button class="button" @click="getState(3)">Panel 3</button>
            <button class="button" @click="getState(4)">Panel 4</button>
            <button class="button" @click="getState(7)">Panel 7</button>
            <button class="button" @click="getState(8)">Panel 8</button>
            <button class="button" @click="getState(11)">Panel 11</button>
            <button class="button" @click="getState(12)">Panel 12</button>
        </div>
        <div class="eightbit-top-nav">
            <a href="https://www.ohiolottery.com" id="eightbit-ohlottery-logo">
                <div alt="ohio lottery logo" id="bitbat-header-ohlogo" />
            </a>
            <a href="https://www.ohiolottery.com" id="eightbit-game-logo">
                <div class="logoHorizontal" alt="8Bit at Bat Logo" id="bitbat-header-8bitlogo" />
            </a>
        </div>
        <!-- ANCHOR Controls -->
        <!-- <%@ Control Language="C#" AutoEventWireup="true" CodeFile="GuardiansHomeplateInstantWins.ascx.cs" Inherits="CMSWebParts_OhioLottery_PLRC_GuardiansHomeplateInstantWins" %>
        <asp:HiddenField ID="hdnMLBAllowedToPlay" ClientIDMode="Static" />
        <asp:HiddenField ID="hdnMLBWinner" ClientIDMode="Static" />
        <asp:HiddenField ID="hdnMLBFirstName" ClientIDMode="Static" />
        <asp:HiddenField ID="hdnMLBLastName" ClientIDMode="Static" />
        <asp:HiddenField ID="hdnMLBFullName" ClientIDMode="Static" />
        <asp:HiddenField ID="hdnUserId" ClientIDMode="Static" /> -->

        <div class="">
            <p class="homeplate-disclaimer text-white">Must be 18 years or older. Not an official Lottery Game.</p> 
        </div>
        
        <!-- ANCHOR Inning Top -->
        <!-- NOTE Game -->
        <span class="homeplate-logic current4" v-if="currentState === 4">
            <article ID="pnlGameTop" class="pnlGame bitbat-2021-size bitbat-panel">
                <div class="bitbat-background bitbat-grass-top"></div>
                <div class="homeplate-game game-choice game-top" v-if="gameTop">
                    <div class="bitbat-top-selection bitbat-sky homeplate-top">
                        <div class="cloud-wrapper cloud-1">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>

                        <div class="cloud-wrapper cloud-2">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>

                        <p class="error-text bitbat-header bit-text">
                            Strike 'em out!
                        </p>

                        <p class="bitbat-text-setting">
                            It's a full count, <span class="player-name"></span> is on the<br/>
                            mound. Will you strike out the batter<br/>
                            and win $50 Lottery Cash? Choose your pitch.
                        </p>

                        <button class="button btn-secondary game-choicebutton bit-text" id="sb" @click="setScrewball">SCREWBALL</button>
                        <button class="button btn-secondary game-choicebutton bit-text" id="fb" @click="setFastball">FASTBALL</button>
                        <button class="button btn-secondary game-choicebutton bit-text" id="kb" @click="setKnuckleball">KNUCKLEBALL</button>
                    </div>

                    <div alt="baseball" class="bitbat-top-selection homeplate-player cle" id="image-balltoss" v-if="cle" />
                    <div alt="baseball" class="bitbat-top-selection homeplate-player reds" id="image-balltoss" v-if="reds" />

                    <div class="bitbat-top-selection bitbat-dirt"></div>

                    <div class="bitbat-top-selection bitbat-mound">
                    
                    </div>

                    <div class="bitbat-top-selection bitbat-mound-plate"></div>

                    <div class="bitbat-top-selection bitbat-grass"></div>

                    <div class="bitbat-top-selection homeplate-grass"></div>
                </div>

                <div class="homeplate-game game-animation animation-top" v-if="animationTop === true">
                    <div class="homeplate-black">
                        <div id="game-animation-choice">
                            <div class="bitbat-selected">You selected:</div><div id="game-animation-chosen">{{ topChoice }}</div>
                        </div>
                    </div>

                    <!-- HIT -->
                    <span v-if="cle" class="cle">
                        <div class="game-animation-reveal screen-full" id='anSBH' v-if="screwball === true && winner === false && loser === true"/>
                        <div class="game-animation-reveal screen-full" id='anFBH' v-if="fastball === true && winner === false && loser === true"/>
                        <div class="game-animation-reveal screen-full" id='anKBH' v-if="knuckleball === true && winner === false && loser === true"/>
                    </span>

                    <span v-if="reds" class="reds">
                        <div class="game-animation-reveal screen-full" id='anSBH' v-if="screwball === true && winner === false && loser === true"/>
                        <div class="game-animation-reveal screen-full" id='anFBH' v-if="fastball === true && winner === false && loser === true"/>
                        <div class="game-animation-reveal screen-full" id='anKBH' v-if="knuckleball === true && winner === false && loser === true"/>
                    </span>
                    

                    <!-- MISS -->
                    <span v-if="cle" class="cle">
                        <div class="game-animation-reveal screen-full" id='anSBM' v-if="screwball === true && winner === true && loser === false"/>
                        <div class="game-animation-reveal screen-full" id='anFBM' v-if="fastball === true && winner === true && loser === false"/>
                        <div class="game-animation-reveal screen-full" id='anKBM' v-if="knuckleball === true && winner === true && loser === false"/>
                    </span>

                    <span v-if="reds" class="reds">
                        <div class="game-animation-reveal screen-full" id='anSBM' v-if="screwball === true && winner === true && loser === false"/>
                        <div class="game-animation-reveal screen-full" id='anFBM' v-if="fastball === true && winner === true && loser === false"/>
                        <div class="game-animation-reveal screen-full" id='anKBM' v-if="knuckleball === true && winner === true && loser === false"/>
                    </span>
                </div>

                <!-- NOTE Win -->
                <article ID="pnlPrizeWonTop" ClientIDMode="Static" class="pnlPrizeWon bitbat-2021-size bitbat-panel" v-if="prizeWonTop">
                    <div class="homeplate-top">
                        <div class="cloud-wrapper cloud-1">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>

                        <div class="cloud-wrapper cloud-2">
                            <div alt="cloud" class=" bkgnd-cloud"/>
                        </div>

                        <div class="lights" alt="lights"/>

                        <div class="cloud-wrapper cloud-1">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>

                        <div class="cloud-wrapper cloud-2">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>
                    </div>

                    <div class="homeplate-black">
                        <h1>
                            Nice pitch!<br/>
                            You won $50 Lottery Cash!
                        </h1>

                        <p id="bitbat-endplate-1-top" class="bitbat-text-setting">
                            Your prize will be in your MyLotto<br/>
                            Rewards&reg; account soon.<br/><br/>
                            You also earned an entry into our<br/>
                            Grand Slam end-of-year drawing.
                        </p>
                        <p id="bitbat-endplate-2-top" class="bitbat-text-setting">
                            Come back in the bottom of the<br/>
                            8th for your chance at another<br/>
                            $50 in lottery cash.
                        </p>

                        <button class="button btn-secondary" id="bitbat-plate-forward-top" @click="setState(7)">NEXT</button>
                        <button class="button btn-secondary bitbat-plate-backward-top">NEXT</button>
                    </div>

                    <div class="homeplate-grey">&nbsp;</div>

                    <div class="homeplate-pillars">
                        <div class="pillars" alt="pillars"/>
                    </div>

                    <div class="homeplate-crowd">&nbsp;</div>

                    <div class="homeplate-yellow">&nbsp;</div>

                    <div class="homeplate-wall"></div>

                    <div class="homeplate-dirt bitbat-dirt"></div>

                    <div class="homeplate-grass-side bitbat-grass-side"></div>

                    <div class="homeplate-grass bitbat-grass-top"></div>
                </article>

                <article ID="pnlDrawingEntryTop" ClientIDMode="Static" class="pnlDrawingEntry bitbat-2021-size bitbat-panel" v-if="drawEntryTop">

                    <div class="homeplate-top">
                        <div alt="lights" class="bitbat-lights"/>

                        <div class="cloud-wrapper cloud-1">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>

                        <div class="cloud-wrapper cloud-2">
                            <div alt="cloud" class=" bkgnd-cloud"/>
                        </div>

                        <div class="cloud-wrapper cloud-1">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>

                        <div class="cloud-wrapper cloud-2">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>
                    </div>

                    <div class="homeplate-black">
                        <h1>
                            Oh no,<br/>
                            it's a hit!
                        </h1>

                        <p class="bitbat-text-setting">
                            You didn't win this time, but you did<br/>
                            earn an entry into our Grand Slam<br/>
                            end-of-season drawing!<br/><br/>
                            Play again in the bottom of the<br/>
                            8th for a chance at another $50<br/>
                            in lottery cash.
                        </p>

                        <button class="button btn-secondary bitbat-plate-backward-end" @click="setState(7)">NEXT</button>
                    </div>

                    <div class="homeplate-grey">&nbsp;</div>

                    <div class="homeplate-pillars">
                        <div class="pillars" alt="pillars"/>
                    </div>

                    <div class="homeplate-crowd">&nbsp;</div>

                    <div class="homeplate-yellow">&nbsp;</div>

                    <div class="homeplate-wall"></div>

                    <div class="homeplate-dirt bitbat-dirt"></div>

                    <div class="homeplate-grass-side bitbat-grass-side"></div>

                    <div class="homeplate-grass bitbat-grass-top"></div>
                </article>
            </article>
        </span>
        <!-- ANCHOR Inning Bottom -->
        <!-- NOTE Game -->
        <span class="homeplate-logic" v-if="currentState === 8">
            <article ID="pnlGameBottom" class="pnlGame bitbat-2021-size bitbat-panel"  Visible="true">
            <div class="bitbat-background bitbat-grass-top"></div>
                <div class="homeplate-game game-choice game-bottom" v-if="gameBottom">
                    <div class="bitbat-bottom-selection bitbat-sky homeplate-top">
                        <div class="cloud-wrapper cloud-1">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>
                    
                        <div class="cloud-wrapper cloud-2">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>

                        <div class="cloud-wrapper cloud-2">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>

                        <p class="error-text bit-text bitbat-header">
                            Batter up!
                        </p>

                        <p class="bitbat-text-setting">
                            It's a full count, bases loaded.<br/>
                            <span class="player-name"></span>, steps up to the plate.<br/>
                            What will you hit? Guess correctly <br/>
                            and win $50 in lottery cash.
                        </p>

                        <button class="button btn-secondary game-choicebutton bit-text" id="s" @click="getWinner('SINGLE')">SINGLE</button>
                        <button class="button btn-secondary game-choicebutton bit-text" id="d" @click="getWinner('DOUBLE')">DOUBLE</button>
                        <button class="button btn-secondary game-choicebutton bit-text" id="h" @click="getWinner('HOMERUN')">HOME RUN</button>
                    </div>

                    <span v-if="cle" class="cle">
                        <div alt="baseball" class="bitbat-bottom-selection bitbat-swing" />
                    </span>
                    
                    <span v-if="reds" class="reds">
                        <div alt="baseball" class="bitbat-bottom-selection bitbat-swing" />
                    </span>

                    <div class="bitbat-bottom-selection bitbat-grass bitbat-grass-top"></div>
                    <div class="bitbat-bottom-selection bitbat-baseline"></div>
                    <div class="bitbat-bottom-selection bitbat-grass bitbat-grass-bottom bitbat-grass-top"></div>
                    <div class="bitbat-bottom-selection bitbat-grass bitbat-grass-bottom bitbat-grass-side"></div>
                    <div class="bitbat-bottom-selection bitbat-dirt"></div>

                    <div class="bitbat-bottom-selection homeplate-dirt"></div>
                </div>

                <div class="homeplate-game game-animation animation-bottom" v-if="animationBottom">
                    <div class="homeplate-black">
                        <div id="game-animation-choice">
                            <div class="bitbat-selected">You selected: </div> <div id="game-animation-chosen-bottom">{{bottomChoice}}</div>
                        </div>
                    </div>

                    <span v-if="cle" class="cle">
                        <div class="game-animation-reveal screen-full" id='anD' v-if="choice === 0" />
                        <div class="game-animation-reveal screen-full" id='anS' v-if="choice === 1" />
                        <div class="game-animation-reveal screen-full" id='anH' v-if="choice === 2" />
                    </span>

                    <span v-if="reds" class="reds">
                        <div class="game-animation-reveal screen-full" id='anD' v-if="choice === 0" />
                        <div class="game-animation-reveal screen-full" id='anS' v-if="choice === 1" />
                        <div class="game-animation-reveal screen-full" id='anH' v-if="choice === 2" />
                    </span>
                </div>

            <article ID="pnlPrizeWonBottom" ClientIDMode="Static" class="pnlPrizeWon pnlPrizeWonBottom bitbat-2021-size bitbat-panel" v-if="prizeWonBottom">
                <div class="homeplate-top">
                    <div class="cloud-wrapper cloud-1">
                        <div alt="cloud" class="bkgnd-cloud"/>
                    </div>

                    <div class="cloud-wrapper cloud-2">
                        <div alt="cloud" class=" bkgnd-cloud"/>
                    </div>

                    <div class="lights" alt="lights"/>

                    <div class="cloud-wrapper cloud-1">
                        <div alt="cloud" class="bkgnd-cloud"/>
                    </div>

                    <div class="cloud-wrapper cloud-2">
                        <div alt="cloud" class="bkgnd-cloud"/>
                    </div>
                </div>

                <div class="homeplate-black bitbat-claim-screen">

                    <h1>Congrats, slugger!<br/>You won $50 Lottery Cash!</h1>
                    <p id="bitbat-endplate-1-bottom" class="bitbat-text-setting">
                        Your prize will be in your MyLotto<br/>
                        Rewards&reg; account soon<br/><br/>
                        You also earned an entry into our<br/>
                        Grand Slam end-of-season drawing.
                    </p>
                    <p id="bitbat-endplate-2-bottom" class="bitbat-text-setting">
                        Remember, you can play<br/>
                        <em>8-Bit at bat!</em> in the 8th inning of every<br/>
                        {{ city }}  {{ team }} home game.
                    </p>

                    <button class="button btn-secondary" id="bitbat-plate-forward-bottom" @click="setState(11)">NEXT</button>
                    <button class="bitbat-learn-more">
                        <a href="/mylotto-rewards/club-promotions/8-bit-at-bat!" class="button">
                            LEARN MORE
                        </a>
                    </button>
                </div>

                <div class="homeplate-grey">&nbsp;</div>

                <div class="homeplate-pillars">
                    <div class="pillars" alt="pillars"/>
                </div>

                <div class="homeplate-crowd">&nbsp;</div>

                <div class="homeplate-yellow"></div>

                <div class="homeplate-wall"></div>

                <div class="homeplate-dirt bitbat-dirt"></div>

                <div class="homeplate-grass-side bitbat-grass-side"></div>

                <div class="homeplate-grass bitbat-grass-top"></div>
            </article>

            <article ID="pnlDrawingEntryBottom" ClientIDMode="Static" class="pnlDrawingEntry pnlDrawingEntryBottom bitbat-2021-size bitbat-panel" v-if="drawEntryBottom">
                <div class="homeplate-top">
                    <div class="cloud-wrapper cloud-1">
                        <div alt="cloud" class="bkgnd-cloud"/>
                    </div>

                    <div class="cloud-wrapper cloud-2">
                        <div alt="cloud" class="bkgnd-cloud"/>
                    </div>
                </div>

                    <div class="homeplate-black bitbat-claim-screen">
                        <h1>Aw, so close!</h1>

                        <p class="bitbat-text-setting">
                            You didn't win this time, but you did<br/>
                            earn an entry into our Grand Slam<br/>
                            end-of-season drawing!<br/>
                            <br/>
                            Remember, you can play <em>8-Bit at bat!</em><br/>
                            in the 8th Inning of every<br/>
                             {{ city }}  {{ team }} home game.
                        </p>

                        <button class="bitbat-learn-more">
                            <a href="/mylotto-rewards/club-promotions/8-bit-at-bat!" class="button">
                                LEARN MORE
                            </a>
                        </button>
                    </div>

                    <div class="homeplate-grey">&nbsp;</div>

                    <div class="homeplate-pillars">
                        <div class="pillars" alt="pillars"/>
                    </div>

                    <div class="homeplate-crowd">&nbsp;</div>

                    <div class="homeplate-yellow">&nbsp;</div>

                    <div class="homeplate-wall"></div>

                    <div class="homeplate-dirt bitbat-dirt"></div>

                    <div class="homeplate-grass-side bitbat-grass-side"></div>

                    <div class="homeplate-grass bitbat-grass-top"></div>
                </article>
            </article>
        </span>

        <!-- ANCHOR Shared Screens -->
        <!-- NOTE Not Enabled Not Logged In -->
        <span class="homeplate-logic" v-if="currentState === 1">
            <article ID="pnlNotEnabledNonLoggedIn" class="pnlNotEnabledNonLoggedIn bitbat-2021-size bitbat-panel"  Visible="true">
                <div class="bitbat-background bitbat-grey"></div>
                <div class="homeplate-top">
                    <div class="cloud-wrapper cloud-1">
                        <div alt="cloud" class="bkgnd-cloud"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d1"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d2"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d3"/>
                    </div>

                    <div class="cloud-wrapper cloud-2">
                        <div alt="cloud" class="bkgnd-cloud"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d1"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d2"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d3"/>
                    </div>

                    <p class="bit-text">
                        Game over<br/>
                        (for now)
                    </p>

                    <p class="bitbat-text-setting">
                        <em>8-Bit at bat!</em> is available in the 8th<br/>
                        inning of every  {{ city }}<br/>
                         {{ team }} home game. Come back<br/>
                        next game day for your chance to<br/>
                        win $50 Lottery Cash instantly.
                    </p>
                    <p class="bitbat-text-setting">
                        If you don't have a MyLotto Rewards&reg;<br/>
                        account, create one now so you're<br/>
                        ready to come game day.
                    </p>

                    <div class="module_SignInRegister bitbat">
                        <a href="/myLotto/My-Account/Register" class=" buttonbtn_Register bitbat-btn eightBit_blue" target="_blank">Join now</a>
                        <a href="/mylotto-rewards/club-promotions/8-bit-at-bat!" class="button bitbat-btn eightBit_grey" target="_blank">LEARN MORE</a>
                    </div>
                </div>

                <div class="homeplate-yellow">&nbsp;</div>

                <div class="homeplate-wall"></div>

                <div class="homeplate-dirt"></div>

                <div class="homeplate-grey"></div>
            </article>
        </span>

        <!-- NOTE Not Enabled Logged In -->
        <span class="homeplate-logic" v-if="currentState === 11">
            <article ID="pnlNotEnabledLoggedIn" class="pnlNotEnabledLoggedIn bitbat-2021-size bitbat-panel"  Visible="true">
                <div class="bitbat-background bitbat-grey"></div>
                <div class="homeplate-top">
                    <div class="cloud-wrapper cloud-1">
                        <div alt="cloud" class="bkgnd-cloud"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d1"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d2"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d3"/>
                    </div>

                    <div class="cloud-wrapper cloud-2">
                        <div alt="cloud" class="bkgnd-cloud"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d1"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d2"/>
                        <div alt="raindrop" class="bkgnd-cloud-rdrop d3"/>
                    </div>

                    <p class="bit-text">
                        Game over<br/>
                        (for now)
                    </p>

                    <p class="bitbat-text-setting">
                        <em>8-Bit at bat!</em> is available in the 8th<br/>
                        inning of every  {{ city }}<br/>
                         {{ team }} home game. Come back<br/>
                        next game day for your chance to<br/>
                        win $50 Lottery Cash instantly.
                    </p>

                    <div class="module_SignInRegister bitbat">
                        <a href="/mylotto-rewards/club-promotions/8-bit-at-bat!" class="button bitbat-btn eightBit_grey" target="_blank">LEARN MORE</a>
                    </div>
                </div>

                <div class="homeplate-yellow">&nbsp;</div>

                <div class="homeplate-wall"></div>

                <div class="homeplate-grey"></div>
            </article>
        </span>

        <!-- NOTE Already Played -->
        <span class="homeplate-logic" v-if="currentState === 12">
            <article ID="pnlAlreadyPlayed" class="pnlAlreadyPlayed bitbat-2021-size bitbat-panel"  Visible="true">
                <div class="bitbat-background bitbat-sky"></div>
                <div class="homeplate-top">
                    <div class="cloud-wrapper cloud-1">
                        <div alt="cloud" class="bkgnd-cloud"/>
                    </div>

                    <div class="cloud-wrapper cloud-2">
                        <div alt="cloud" class=" bkgnd-cloud"/>
                    </div>
                </div>

                <div class="plaque">
                    <div class="bitbat-plaque-top"></div>
                    <div class="bitbat-plaque-mid">
                        <div class="bitbat-checkmark-check"></div>
                        <div class="bitbat-checkmark">
                            <div class="bitbat-check-l1"></div>
                            <div class="bitbat-check-l2"></div>
                            <div class="bitbat-check-l3"></div>
                            <div class="bitbat-check-l4"></div>
                            <div class="bitbat-check-l5"></div>
                            <div class="bitbat-check-l6"></div>
                            <div class="bitbat-check-l5"></div>
                            <div class="bitbat-check-l4"></div>
                            <div class="bitbat-check-l3"></div>
                            <div class="bitbat-check-l2"></div>
                            <div class="bitbat-check-l1"></div>
                        </div>

                        <h1 class="bit-text">
                            Looks like<br/>
                            you already<br/>
                            played today.
                        </h1>

                        <p class="bitbat-text-setting">
                            Come back in the 8th<br/>
                            inning of the next<br/>
                             {{ city }}  {{ team }} home<br/>
                            game for your chance<br/>
                            to win $50 Lottery Cash instantly.
                        </p>
                    </div>
                    <div class="bitbat-plaque-bottom"></div>
                </div>
            </article>
        </span>

        <!-- NOTE Error -->
        <span class="homeplate-logic" v-if="currentState === -1">
            <article ID="pnlError" class="pnlError bitbat-panel" Visible="true">
                <div  id="testImage"  ref="test"/>
                <div class="bitbat-background bitbat-grass-top"></div>
                <div class="homeplate-top">
                    <div class="bitbat-error-animation">
                        <div class="bitbat-error-wrapper">
                            <div class="bitbat-error-inner">
                                <div  class="baseball"/>
                            </div>
                        </div>
                    </div>
                    <h1 class="bit-text">
                        Oops!<br/>
                        There was an error
                    </h1>
                    <p class="bitbat-text-setting">
                        Sorry, something went wrong.<br/>
                        Try reloading or scanning again for<br/>
                        your chance to win.
                    </p>
                </div>
            </article>
        </span>

        <!-- NOTE Enabled Not logged in -->
        <span class="homeplate-logic" v-if="currentState === 0">
            <article ID="pnlEnabledNonLoggedIn" class="pnlEnabledNonLoggedIn bitbat-panel" Visible="true">
                <div class="bitbat-background bitbat-black"></div>
                <!-- ANIMATION INTRO -->
                <div class="animation-intro" v-if="animationIntro">
                    <div class="animation-intro-shakecontainer">
                        <div class="bitlogo bitLogoAnimation">
                            <div class="logoStacked" />
                        </div>
                        <div class="ball">
                            <div class="screen-full" id="smash" />
                        </div>
                        <div class="animation-intro-header">
                            <h4>The Ohio Lottery</h4>
                            <h4>presents</h4>
                        </div>
                    </div>
                </div>

                <!-- LOGIN -->
                <div class="homeplate-game game-choice" v-if="login">
                    <div class="homeplate-top homeplate-2021-height-override">
                        <div class="cloud-wrapper cloud-1">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>

                        <div class="cloud-wrapper cloud-2">
                            <div alt="cloud" class="bkgnd-cloud"/>
                        </div>

                        <div class="homeplate-plaque plaque">
                            <div class="bitbat-plaque-top plaque-top"></div>
                            <div class="bitbat-plaque-mid plaque-mid">
                                <span class="bit-text bitlogo-welcome">Welcome to</span>
                                <div class="bitlogo">
                                    <div class="logoHorizontal" alt="8Bit at Bat Logo"/>
                                </div>

                                <p class="bitbat-text-setting">
                                    You found a hidden Ohio Lottery 8-Bit game, where you have a chance to win $50 Lottery Cash in the 8th inning of  {{ city }}  {{ team }} home games.
                                </p>
                                <p class="bitbat-text-setting">
                                    Sign into your MyLotto Rewards&reg; account to play. Don't have an account? Sign up for free.
                                </p>
                                <div class="module_SignInRegister bitbat">
                                    <button class="button btn_SignIn btn_popupLogIn">Sign In</button>
                                    <a href="/myLotto/my-account/register" class="button btn_Register" target="_blank">Join now</a>
                                </div>
                            </div>

                            <div class="bitbat-plaque-bottom plaque-bottom"></div>
                        </div>
                        
                        
                    </div>
                    <div class="bitbat-opening-balltoss">
                        <div>
                            <div class="baseball"/>
                        </div>
                    </div>
                </div>
            </article>
        </span>

        <!-- NOTE Between -->
        <span class="homeplate-logic" v-if="currentState === 7">
            <article ID="pnlBetween" class="pnlBetween bitbat-panel" Visible="true">
                <div class="bitbat-background"></div>
                <div class="transition cle" v-if="cle">
                    <div class="transition-intro">
                        <div  class="transition-intro-animation screen-full"/>
                    </div>
                    <div class="transition-loop">
                        <div  class="transition-loop-animation screen-full"/>
                    </div>
                </div>

                <div class="transition reds" v-if="reds">
                    <div class="transition-intro">
                        <div  class="transition-intro-animation screen-full"/>
                    </div>
                    <div class="transition-loop">
                        <div  class="transition-loop-animation screen-full"/>
                    </div>
                </div>
            </article>
        </span>

        <!-- NOTE Pregame -->
        <span class="homeplate-logic" v-if="currentState === 3">
            <article ID="pnlPreGame" class="pnlPreGame preGameLoggedIn bitbat-panel" Visible="true">
                <div class="bitbat-background bitbat-dirt"></div>
                <div class="preGame-text">
                    <div class="homeplate-plaque plaque preGame">
                        <div class="bitbat-plaque-top plaque-top"></div>
                        <div class="bitbat-plaque-mid plaque-mid">
                            <span class="bit-text bitlogo-welcome">Welcome to</span>
                            <div class="bitlogoPregame">
                                <div class="logoHorizontal" alt="8Bit at Bat Logo"/>
                            </div>

                            <p class="bitbat-text-setting">
                                Come back in the top of the 8th inning to play for your chance to <strong>win $50 Lottery Cash instantly</strong>.
                            </p>
                            <div class="module_SignInRegister bitbat">
                                <a href="/mylotto-rewards/club-promotions/8-bit-at-bat!" class="button bitbat-btn eightBit_grey" target="_blank">LEARN MORE</a>
                            </div>
                        </div>

                        <div class="bitbat-plaque-bottom plaque-bottom"></div>
                    </div>
                </div>

                <span class="cle" v-if="cle">
                    <div  class="on-deck"/>
                </span>

                <span class="reds" v-if="reds">
                    <div  class="on-deck"/>
                </span>

                <div class="homeplate-top"></div>
                <div class="homeplate-yellow">&nbsp;</div>
                <div class="homeplate-wall"></div>
                <div class="homeplate-green-ondeck-batting"></div>
                <div class="homeplate-green2-ondeck-batting"></div>
                <div class="homeplate-dirt-ondeck-batting-disclaimer bitbat-dirt"></div>
            </article>
        </span>

        <!-- NOTE Pregame Logged Out-->
        <span class="homeplate-logic" v-if="currentState === 2">
            <div class="animation-intro" v-if="animationIntro">
                <div class="animation-intro-shakecontainer">
                    <div class="bitlogo bitLogoAnimation">
                        <div class="logoStacked" />
                    </div>
                    <div class="ball">
                        <div class="screen-full" id="smash" />
                    </div>
                    <div class="animation-intro-header">
                        <h4>The Ohio Lottery</h4>
                        <h4>presents</h4>
                    </div>
                </div>
            </div>
            <article ID="pnlPreGameLoggedOut" class="pnlPreGame bitbat-panel" Visible="true" v-if="login">
                <div class="bitbat-background bitbat-dirt"></div>
                <div class="preGame-text">
                    <div class="bit-information">
                        <div class="homeplate-plaque plaque preGame">
                            <div class="bitbat-plaque-top plaque-top"></div>
                            <div class="bitbat-plaque-mid plaque-mid">
                                <span class="bit-text bitlogo-welcome">Welcome to</span>
                                <div class="bitlogoPregame">
                                    <div class="logoHorizontal" alt="8Bit at Bat Logo"/>
                                </div>

                                <p class="bitbat-text-setting">
                                    Come back in the top of the 8th inning to play for your chance to <strong>win $50 Lottery Cash instantly</strong>.
                                </p>
                                <div class="module_SignInRegister bitbat">
                                    <button class="button btn_SignIn btn_popupLogIn bitbat-btn eightBit_purple" id="globalSignIn">Sign In</button>
                                    <a href="/myLotto/my-account/register" class="button btn_Register bitbat-btn eightBit_purple" target="_blank">Join now</a>
                                    <a href="/mylotto-rewards/club-promotions/8-bit-at-bat!" class="button bitbat-btn eightBit_grey" target="_blank">LEARN MORE</a>
                                </div>
                            </div>

                            <div class="bitbat-plaque-bottom plaque-bottom"></div>
                        </div>
                    </div>
                </div>

                <span class="cle" v-if="cle">
                    <div  class="on-deck"/>
                </span>

                <span class="reds" v-if="reds">
                    <div  class="on-deck"/>
                </span>

                <div class="homeplate-top"></div>
                <div class="homeplate-yellow">&nbsp;</div>
                <div class="homeplate-wall"></div>
                <div class="homeplate-green-ondeck-batting"></div>
                <div class="homeplate-green2-ondeck-batting"></div>
                <div class="homeplate-dirt-ondeck-batting-disclaimer bitbat-dirt"></div>
            </article>
        </span>
        <div class="testingButtons" style="display:flex;justify-content:space-around;" v-if="masterControl">
            <button class="button" @click="toggleTesting">TEST</button>
            <button class="button" @click="testWin">WIN</button>
            <button class="button" @click="testLose">LOSE</button>
        </div>
    </article>
</template>

<script>
    // import $ from 'jquery';

    export default {
        components: {
           
        },
        data: function () {
            return {
                token: '',
                logged: false,
                login: false,
                userAuthed: '',
                notEnabledNotLogged: false,
                notEnabledLogged: false,
                alreadyPlayed: false,
                enabledNotLogged: false,
                enabledLogged: false,
                gameTop: false,
                intermision: false,
                gameBottom: false,
                error: false,
                area: '',
                city: '',
                team: '',
                apiTeam: 0,
                choices: ['SINGLE', 'DOUBLE', 'HOME RUN'],
                topchoices: ['SCREWBALL', 'FASTBALL', 'KNUCKLEBALL'],
                topChoice: '',
                single: false,
                double: false,
                hr: false,
                screwball: false,
                fastball: false,
                knuckleball: false,
                bottomChoice: '',
                animationTop: false,
                prizeWonTop: false,
                prizeWonBottom: false,
                drawEntryTop: false,
                drawEntryBottom: false,
                animationIntro: false,
                animationTop: false,
                animationBottom: false,
                cle: false,
                reds: false,
                win: false,
                lose: false,
                currentState: "-1",
                choice: 0,
                testing: false, 
                masterControl: false 
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    this.userAuthed = this.isUserAuthed();
                    
                    this.getTeam();
                    this.testGame(); // TESTING ONLY
                    // this.getState(); // NOT TESTING ONLY
                    this.appendStyles();
                });
            },
            loadComponent() {
                $(document).ready(function () {
                    $('.close-button').on('click', function () {
                        $("#globalLogInModal").hide();
                        $("#globalLoginBlocker").hide();
                    })

                    $(".btn_popupLogIn").on('click', function () {
                        $("#globalLogInModal").show();
                        $("#globalLoginBlocker").show();
                    });
                })
            },
            toggleTesting() {
                this.testing = !this.testing;
            },
            testWin() {
                this.testing = true;
                this.winner = true;
                this.loser = false;
            },
            testLose() {
                this.testing = true;
                this.winner = false;
                this.loser = true;
            },
            appendStyles() {
                let head = document.getElementsByTagName("head"),
                    topMenu = document.getElementById("top-menu"),
                    footer = document.getElementsByTagName("footer"),
                    footerMenu = document.getElementsByClassName("footer-menu"),
                    sheet = document.createElement("link"),
                    container = document.getElementById("container"),
                    main = document.getElementById("main");

                main.style.top = "-22px";

                sheet.type = "text/css";
                sheet.rel = "stylesheet";
                sheet.href = "https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap";

                setTimeout(() => {
                    footer[0].style.background = "transparent";
                    footer[0].style.marginTop = "0px";
                    footer[0].style.height = "1px";
                    footer[0].style.overflow = "hidden";

                    topMenu.style.display = "none !important";
                    topMenu.remove();
                    footerMenu[0].style.display = "none !important";

                    container.style.overflow = "initial";
                    container.style.position = "relative";
                    container.style.top = "-22px";

                    head[0].appendChild(sheet);
                }, 500)
                
            },
            testGame() {
                // console.log("Testing Game");
                let url = process.env.VUE_APP_APIURL + '/1.0/Games/EightBitAtBat/ToggleGameInstance',
                    apiToken = this.token;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    // console.log('=== TESTING ===');
                    // console.log(json);
                })
                .then(() => {
                    this.getState();
                })
            },
            getTeam() {
                // console.log('getting team')
                let pathName = window.location.pathname;

                if (pathName.indexOf('reds') >= 0) {
                    this.cle = false;
                    this.reds = true;
                    this.area = 'EnterEightBitAtBatCincinnati';
                    this.team = 'Reds';
                    this.city = 'Cincinnati';
                    this.apiTeam = 1;
                } else {
                    this.cle = true;
                    this.reds = false;
                    this.area = 'EnterEightBitAtBatCleveland';
                    this.team = 'Guardians';
                    this.city = 'Cleveland';
                    this.apiTeam = 0; 
                }

                // console.log(pathName.indexOf('reds'));

                // this.setImages():
            },
            setImages() {
                let test = document.getElementById('testImage');

                test.style.backgroundImage = 'url("../media/site-design/8bit/2021/General/error.png")';
                // let clouds = document.getElementsByClassName('bkgnd-cloud'),
                //     ballToss = document.getElementById('image-balltoss'),
                //     sbh = 

                // if (this.cle === true && this.reds === false) {
                //     // Set all images from Guardians using this.cleImages
                // } else {
                //     // Set all images for reds using this.redImages
                // }
            },
            setState(panel) {
                this.currentState = panel;
            },
            getState(panel) {
                if (panel === null || panel === undefined) {
                    // console.log(panel)
                    let url = process.env.VUE_APP_APIURL + '/1.0/Games/EightBitAtBat/LoadGame?team=' + this.apiTeam + '&userId=' + this.user.user.userId,
                        apiToken = this.token;

                    fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken
                        }
                    })
                    .then((response) => response.json())
                    .then((json) => {
                        this.currentState = json.data;
                        if (this.currentState === 4) {
                            this.gameTop = true;
                            this.gameBottom = false;
                            // console.log('Game Top ' + this.gameTop);
                        } else if (this.currentState === 8) {
                            this.gameTop = false;
                            this.gameBottom = true;
                            // console.log('Game Bottom ' + this.gameBottom);
                        } else {
                            this.gameTop = false;
                            this.gameBottom = false;
                            // console.log('No Game')
                        }
                        // console.log('8Bit Testing Stoppoint')
                        // console.log(json);
                        // console.log(this.currentState);
                    })
                } else {
                    this.testing = true;
                    this.currentState = panel;
                    if (this.currentState === 4) {
                        this.gameTop = true;
                        this.gameBottom = false;
                        // console.log('Game Top ' + this.gameTop);
                    } else if (this.currentState === 8) {
                        this.gameTop = false;
                        this.gameBottom = true;
                        // console.log('Game Bottom ' + this.gameBottom);
                    } else {
                        this.gameTop = false;
                        this.gameBottom = false;
                        // console.log('No Game')
                    }
                    // console.log('=== 8Bit Testing ===')
                    // console.log(this.currentState);
                }

                if (this.enabledNotLoggedIn) {
                    setTimeout(function() {
                        background.classList.remove('bitbat-black');
                        background.classList.add('bitbat-sky');
                    }, 2250);
                }

                let _this = this;

                _this.animationIntro = true;

                setTimeout(() => {
                    _this.animationIntro = false;
                    if (_this.currentState === 0 || _this.currentState === 1 || _this.currentState === 2) {
                        _this.login = true;
                        this.loadComponent();
                    }
                }, 2250);
            },
            getWinner(id) {
                /** GET WINNER RETURN */
                // console.log(this.testing)
                if (this.testing === false) {
                    let userId = this.user.user.userId,
                        url = process.env.VUE_APP_APIURL + '/1.0/Games/EightBitAtBat/EnterEightBitAtBat?userId=' + userId + '&team=' + this.apiTeam,
                        playerChoice = id;

                    fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + this.token
                        }
                    })
                    .then((response) => response.json())
                    .then((json) => {
                        // console.log('=== Get Winner ===')
                        this.gameTop ? this.playResultTop(json, id) : this.playResultBottom(json, id);
                        return json;
                    })
                } else {
                    this.gameTop ? this.playResultTop({"data": this.winner}, id) : this.playResultBottom({"data": this.winner}, id);
                    return this.winner;
                }
            }, 
            setScrewball() {
                let outcome = this.getWinner('SCREWBALL');

                // outcome ? this.screwball = true : this.fastball = true;
                this.screwball = true;
                this.fastball = false;
                this.knuckleball = false;
            },
            setFastball() {
                let outcome = this.getWinner('FASTBALL');

                // outcome ? this.fastball = true : this.knuckleball = true;
                this.screwball = false;
                this.fastball = true;
                this.knuckleball = false;
            },
            setKnuckleball() {
                let outcome = this.getWinner('KNUCKLEBALL');

                // outcome ? this.knuckleball = true : this.screwball = true;
                this.screwball = false;
                this.fastball = false;
                this.knuckleball = true;
            },
            playResultTop(json, id) {
                let _this = this;
                // Winner comes from API, Choice comes from button in game top section
                // console.log('=== Play Result Top ===');
                // console.log(json);
                // console.log(id);

                let winner = json.data,
                    choice = id;

                let animationTiming = 6800;

                if (winner === true) {
                    this.winner = true;
                    this.loser = false;

                    // console.log('Winner!')
                    // console.log(typeof(winner))

                    animationTiming = 11800;
                } else {
                    this.winner = false;
                    this.loser = true;

                    // console.log('Loser!')
                    // console.log(typeof(winner))
                }

                this.topChoice = id;

                // Add method for every button "Screwball", "Fastball", "Knuckleball" etc to change their state from true/false

                this.gameTop = false;
                // console.log('game top ' + this.gameTop)
                this.animationTop = true;
                // console.log('Animation Top ' + this.animationTop)

                // console.log(animationTiming)

                setTimeout(function() {
                    _this.animationTop = false;
                    _this.winner ? _this.prizeWonTop = true : _this.drawEntryTop = true;
                }, animationTiming);

                return false;
            },
            playResultBottom(json, id) {
                let _this = this;
                // console.log('=== Play Result Bottom ===');
                // console.log(json);
                // console.log(id);

                let winner = json.data,
                    choice = id,
                    animation = id;

                let animationTiming = 0;

                if (id === 'SINGLE' && winner === true) {
                    animationTiming = 5200;
                } else {
                    animationTiming = 8000;
                }

                if (winner === true) {
                    this.winner = true;
                    this.loser = false;

                    if (choice === 'SINGLE') {
                        this.choice = 1;
                    } else if (choice === 'DOUBLE') {
                        this.choice = 0;
                    } else {
                        this.choice = 2;
                    }
                } else {
                    this.winner = false;
                    this.loser = true;

                    // Set animation here for non-winner
                    if (choice === 'SINGLE') {
                        this.choice = Math.random() < 0.5 ? 0 : 2;
                    } else if (choice === 'DOUBLE') {
                        this.choice = Math.random() < 0.5 ? 1 : 2;
                    } else {
                        this.choice = Math.random() < 0.5 ? 0 : 1;
                    }
                }

                this.bottomChoice = id;

                this.gameBottom = false;
                this.animationBottom = true;

                setTimeout(function() {
                    _this.animationBottom = false;
                    // console.log(animationTiming)
                    _this.winner ? _this.prizeWonBottom = true : _this.drawEntryBottom = true;
                }, animationTiming);

                return false;
            }
		}
    };
</script>

<style lang="scss" scoped>
    #header,
    .top-bar {
        display: none !important;
    }
    #test-panel {
        position: absolute;
        z-index: 10000;
        display: flex;
        flex-wrap: wrap;
        max-width: 390px;
    }
    .testingButtons {
        position: relative;
        top: 20px;
    }
</style>