<template>
    <div>
        <section v-if="products.length" class="catalogList">
            <span>
                <span v-for="product in products" :key="product.skuNumber">
                    <div class="productItem cf">

                        <a :href="product.nodeAliasPath" class="productImg" :style="'backgroundImage: url(' + product.image + ';'" v-if="!kycMode">
                            <!-- <img :src="product.image" alt="product.description"> -->
                        </a>

                        <a :href="product.nodeAliasPath" class="productImg" :style="'backgroundImage: url(' + product.image + ';'" v-if="kycMode && isKYCVerified">
                            <!-- <img :src="product.image" alt="product.description"> -->
                        </a>

                        <a class="productImg" :style="'backgroundImage: url(' + product.image + ';'" @click="handleClick" v-if="kycMode && !isKYCVerified">
                            <!-- <img :src="product.image" alt="product.description"> -->
                        </a>

                        <article class="productInfo">

                            <a :href="product.nodeAliasPath" class="productTitle" v-if="!kycMode">
                                {{product.name}}
                            </a>

                            <a :href="product.nodeAliasPath" class="productTitle" v-if="kycMode && isKYCVerified">
                                {{product.name}}
                            </a>

                            <a class="productTitle" @click="handleClick" v-if="kycMode && !isKYCVerified">
                                {{product.name}}
                            </a>



                            <span class="productPrice">
                                {{product.price}} Points
                            </span>
                        </article>
                    </div>
                </span>
                <br/>
                <AdditionalRewardsVue></AdditionalRewardsVue>
            </span>
        </section>
        <section v-if="!products.length" class="noProductsAvailable">
             We re-stock up to 3,000 new Lottery Cash and Coupons at random times every day—they do sell out quickly, so check in often to get them while they're hot!
        </section>
        <span class="note red">* Point costs are subject to change</span>
        <div id="modal-kyc" class="reveal modal-kyc reveal-modal" data-reveal data-close-on-click="false" data-close-on-esc="false">
            <button class="close-button" type="button" data-close="modal-kyc">
                <span aria-hidden="true">&times;</span>
            </button>
                <h2>Please verify your account</h2>
                <p>We have updated security for MyLotto Rewards. <strong>Please note that you will not be able to submit tickets or redeem existing points until you have completed the identity verification process.</strong></p>
                <div class="modal-kyc_actions">
                    <a @click="goVerify" class="button btn-primary">Verify My Id</a>
                    <!-- <button type="button" class="button btn-primary" data-close="modal-kyc">I'll do it later</button> -->
                </div>
            </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import Foundation from "@/foundation-sites/js/foundation.js";
    import AdditionalRewardsVue from '../AdditionalRewards.vue';

    export default {
        components: [
            AdditionalRewardsVue
        ],
        data: function() {
            return {
                token: '',
                products: [],
                games: [
                    {
                        name: 1,
                        id: 2
                    }
                ],
                kycMode: process.env.VUE_APP_KYCMODE,
                isKYCVerified: false,
                user: {}
            }
        },
        mounted: function() {
            this.getToken()
        },
        methods: {
            getToken() {
                // console.log('getting token')
                this.getAPItoken()
                .then((token) => {
                    // console.log('got token')
                    this.token = token;
                    this.getProducts(token);
                })
                .then(() => {
                    this.getKYCVerified();
                    // console.log("KYC MODE: " + this.kycMode);
                });
            },
            getProducts(token) {
                // console.log("Getting Products")
                let apiToken = token,
                    url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/GetProductList?type=7&top=100';
                    // req = '{"type": 4, "offset": 0, "top": 10, "categoryId": 0}';

                return fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                    // body: JSON.stringify(req)
                })
                .then(response => response.json())
                .then(json => {
                    this.setStock(json.data);
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            setStock(json) {
                let data = json,
                    products = [];

                for (let i = 0; i < data.length; i++) {
                    data[i].stock > 0 ? products.push(data[i]) : false;
                }

                this.products = products;
            },
            handleClick() {
               //should only run when kycMode is true and isKYCVerified is false
               // console.log("kycMode is true and isKYCVerified is false");
               this.openKYCModal();
            },
            openKYCModal() {
                let popup = new Foundation.Reveal($('#modal-kyc'));
                popup.open();
            },
            getKYCVerified() {
                //get the userId
                this.user = this.$cookies.get('apiToken');
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + this.user.user.userId;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    // console.log(json);
                    // console.log(json.data);
                    //always true
                    this.isKYCVerified = json.data.userIsVerified;

                    // console.log("User is verified?" + json.data.userIsVerified);

                    if (!this.kycMode) {
                        this.isKYCVerified = true;
                    }

                    if (json.data.errorMessage !== '') {
                       console.log(json.data.errorMessage);
                    }

                    // this.isKYCVerified = true;
                    // console.log(`KYC : ${this.isKYCVerified}`);
                })
                .catch((error) => {
                    // return (this.errorMessage = error)
                    console.log(error);
                })
                 
            },
            goVerify() {
                //set the return link into a cookie
                //go to verify page
                let goURL = window.location.origin + "/mylotto-rewards/my-account/verify-my-identity";
                this.$cookies.set("kycReturnUrl", JSON.stringify(window.location));
                window.location.assign(goURL);

            }
        }
    }
</script>