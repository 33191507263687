<template>
    <div class="video-player-wrapper winning-number-video">
        <h3>{{ selectedVideoTitle }}</h3>
        
        <div class="video_player">
            
            <span class="placeholder-youtube" @click="playVideo" v-if="!videoId"></span>

            <template v-if="videoId">
                <youtube :video-id="videoId"
                        :host="host"
                        player-width="100%" player-height="511"
                >
                </youtube>
            </template>
        </div>

        <div class="video_archive">
            <h3 class="archive_heading">Watch Archive Videos</h3>
            <div class="archive_date_wrap">
                <select name="video-date" @change="onChangeSelect" id="selectDate">
                    <option v-for="videoDate in videos" v-bind:key="videoDate.id" :value="videoDate.snippet.resourceId.videoId">
                        {{ videoDate.snippet.title | formatDateName }}
                    </option>
                </select>
            </div>
            <a href="https://www.youtube.com/user/OHdailydraws/videos" target="_blank" class="youtubeArchive">View More Archived Drawings</a>
        </div>

        

        
    </div>
</template>


<script>
   // import moment from 'moment'
    export default {
        components: {
        },
        data: function () {
            return {
                videos: [],
                videoId: '',
                selectedVideoTitle: '',
                host: 'https://www.youtube.com'
            }
        },
        mounted: function () {
            this.fetchVideoList();
        },
        methods: {
            fetchVideoList() {
                return fetch("https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyBPQS5zqrWLNMIm9XhtIzQJY-EQQr5Wu_I&part=snippet&playlistId=UUhjQju6fU6uLSPh3BqYTJ2Q&maxResults=20")
                .then(response => response.json())
                .then(videoList => {
                    // console.log(videoList)
                    this.videos = videoList.items;
                    //this.videoId = videoList.items[0].snippet.resourceId.videoId
                    //this.selectedVideoTitle = videoList.items[0].snippet.title
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            onChangeSelect(event) {
                this.videoId = event.target.value;
                var getThisEle;

               Array.from( event.target ).forEach( function( ele ){
                    if (ele.value == event.target.value) {
                        getThisEle =  ele.innerText;
                    }
               });

              this.selectedVideoTitle =  getThisEle


            },
            playVideo(){
                
                // console.log(this.videos)

                this.videoId = this.videos[0].snippet.resourceId.videoId
                this.selectedVideoTitle = this.videos[0].snippet.title

                
            }
        },
        filters:{
            formatDateName: function (value){
                let regex = /\d+/;
                let sToDate = value.match(regex);
                let month = sToDate[0].substring(0,2);
                let day = sToDate[0].substring(2,4);
                let year = sToDate[0].substring(4,8);
                let date  = month+'/'+day+'/'+year;

                let eveningOrMid;

                if(value.indexOf('MID') !== -1 ){
                    eveningOrMid = 'Midday'
                }else{
                    eveningOrMid = 'Evening'
                }
 
                let formatedString = "Results " + date + " "+eveningOrMid
           
                return formatedString;
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>