<template>
    <div class="luckyone-archives-wrapper">
        <!-- <div class="grid-x date-or-nunber-wrapper">
            <p>Search for winning numbers by drawing # or drawing date. Each drawing’s results will be available on the web
                site within 2 minutes after the drawing.</p>

            <div class="cell small-12 form">
                <div class="keno-search-by-number">
                    <SearchByNumber v-model="number"></SearchByNumber>
                    <input type="submit" class="button" name="SearchByDrawNumber" placeholder="Search By Number"
                        value="Search By Number" @click="clkByNumber($event)">
                </div>

                <div class="or-divisor">
                    <div class="word"><span>or</span></div>
                </div>

                <div class="keno-search-by-date">
                    <SearchByDate></SearchByDate>
                    <datepicker :disabledDates="disabledDates" placeholder="Select Date" name="inputLuckyOneDate"
                        :format="customFormatter" v-model="inputValueDate">
                    </datepicker>
                    <input type="submit" class="button" name="SearchByDrawDate" placeholder="Search By Date" value="Search By Date"
                        @click="clkByDate($event)">
                </div>
            </div>
        </div> -->

        <div class="grid-x date-or-nunber-wrapper">
            <p>Search for winning numbers by drawing # or drawing date. Each drawing’s results will be available on the web
                site within 2 minutes after the drawing.</p>
            <div class="cell small-12 form">
                <div class="keno-search-by-number">
                    <SearchByNumber v-model="number"></SearchByNumber>
                    <input type="submit" class="button" name="SearchByDrawNumber" placeholder="Draw Number"
                        value="Search By Number" @click="clkByNumber($event)">
                </div>

                <div class="or-divisor">
                    <div class="word"><span>or</span></div>
                </div>

                <div class="keno-search-by-date">
                    <SearchByDate></SearchByDate>
                    <datepicker :disabledDates="disabledDates" placeholder="Select Date" name="inputLuckyOneDate"
                        :format="customFormatter" v-model="inputValueDate">
                    </datepicker>
                    <input type="submit" class="button" name="SearchByDrawDate" placeholder="Search By Date"
                        value="Search By Date" @click="clkByDate($event)">
                </div>
            </div>
        </div>

        <div class="error-message" v-if="showError">
            <div class="callout alert">
                <p>{{ errMsg }}</p>
            </div>
        </div>

        <div class="grid-x results" v-if="isLoading">
            <div class="cell small-12">
                <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" />
            </div>
        </div>

        <div class="grid-x results" v-if="showResults">
            <div class="cell small-12 text-right">
                <a title="Printer Friendly" class="printfriendly" href="#" @click="print()">Print</a>
            </div>
            <div class="cell small-12 text-left LuckyOne_results">
                Results For: {{ inputDate }}
            </div>
            <table id="luckyone-table-archives" border="0">
                <thead>
                    <tr>
                        <th>Draw Number</th>
                        <th>Winning Numbers</th>
                        <th>High/Low</th>
                        <th>Even/Odd</th>
                    </tr>
                </thead>
                <tbody v-if="numberResult">
                    <tr>
                        <td>{{ numberExId }}</td>
                        <td>
                            <span class="draw-numbers">
                                {{ results.value }}
                            </span>
                        </td>
                        <td>
                            {{ formatHigLow(results.value) }}
                        </td>
                        <td>
                            {{ formatEvenOdd(results.value) }}
                        </td>
                    </tr>
                </tbody>

                <tbody v-if="!numberResult">
                    <tr v-for="result in pageOfItems" :key="result.id">
                        <td>{{ result.externalDrawId }}</td>
                        <td>
                            <span v-for="number in result.numbers" :key="number.id">
                                {{ number.value }}
                            </span>
                        </td>
                        <td>
                            <span v-for="numberHighLow in result.numbers" :key="numberHighLow.id">
                                {{ formatHigLow(numberHighLow.value) }}
                            </span>

                        </td>
                        <td>
                            <span v-for="numberEvenOdd in result.numbers" :key="numberEvenOdd.id">
                                {{ formatEvenOdd(numberEvenOdd.value) }}
                            </span>

                        </td>
                    </tr>
                </tbody>

            </table>

            <jw-pagination v-if="!numberResult" :items="results" :pageSize="25" @changePage="onChangePage"></jw-pagination>
        </div>


    </div>
</template>

<script>
import JwPagination from 'jw-vue-pagination';
import SearchByNumber from './SearchByNumber.vue';
import { HollowDotsSpinner } from 'epic-spinners';
import moment from 'moment';
import Datepicker from "vue-moment-datepicker";

export default {
    components: {
        JwPagination,
        SearchByNumber,
        HollowDotsSpinner,
        Datepicker

    },
    data: function () {
        return {
            number: '',
            results: [],
            pageOfItems: [],
            numberMod: '',
            numberExId: '',
            highLow: '',
            oddEven: '',
            errMsg: '',
            inputDate: '',
            inputValueDate: '',
            disabledDates: {
                from: new Date()
            },
            disabledNumber: true,
            disabledDate: true,
            numberResult: false,
            showResults: false,
            showError: false,
            isLoading: false
        }
    },
    mounted: function () {
    },
    methods: {
        clkByNumber(event) {
            this.showResults = false;
            this.showError = false;
            let eventName = event.target.getAttribute('name'),
                inputValue = this.number

            document.getElementsByName('inputLuckyOneDate')[0].value = ""

            this.isLoading = true;

            this.inputDate = '';
            this.inputValueDate = '';

            if (inputValue.length < 4 || document.getElementsByName('inputSearchByNumber')[0].value == "") {
                this.showError = true;
                this.isLoading = false;
                this.errMsg = "At least 4 characters";

            } else {
                this.getToken(eventName, inputValue)
            }

        },
        clkByDate(event) {
            this.showResults = false;
            this.showError = false;
            let eventName = event.target.getAttribute('name'),
                inputValue = document.querySelector("input[name=inputLuckyOneDate]").value

            document.getElementsByName('inputSearchByNumber')[0].value = "";

            this.inputDate = inputValue;
            this.isLoading = true;
            this.getToken(eventName, inputValue)
        },
        getToken(name, input) {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token, name, input);
                });
        },
        fetchData(token, name, input) {
            let apiToken = token,
                methodName = name,
                inputVal = input,
                methodFormatUrl = '';

            if (methodName == 'SearchByDrawNumber') {
                methodFormatUrl = 'drawId'
            } else if (methodName == 'SearchByDrawDate') {
                methodFormatUrl = 'date'
            }

            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/DrawGames/TheLuckyOne/' + methodName + '?' + methodFormatUrl + '=' + inputVal, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    // console.log(json)
                    let results = json.data;
                    this.isLoading = false;

                    if (json.data === null || json.data.length === 0) {
                        this.showResults = false;
                        this.showError = true;
                        this.results = [];
                        this.errMsg = "We're sorry, we couldn't find any results for that search.";
                        return
                    } else if (json.data.winningNumbers) {
                        if (json.data.winningNumbers.length === 0) {
                            // console.log('hola');
                            this.showResults = false;
                            this.showError = true;
                            this.results = [];
                            this.errMsg = "We're sorry, we couldn't find any results for that search.";
                            return
                        } else {
                            // console.log('chau', results.winningNumbers[0]);
                            this.showResults = true;
                            this.showError = false;
                            this.results = results.winningNumbers[0];
                            this.numberResult = true;
                            this.numberMod = results.winningNumbers[0].modifier;
                            this.numberExId = results.winningNumbers[0].externalDrawId;
                        }

                    }
                    else {
                        this.showError = false;

                        if (methodFormatUrl == 'drawId') {
                            this.results = results.winningNumbers;
                            this.numberResult = true;
                            this.numberMod = results.winningNumbers[0].modifier;
                            this.numberExId = results.winningNumbers[0].externalDrawId;
                        } else {
                            this.numberResult = false;
                            this.results = results;
                        }
                        this.showResults = true;
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        formatHigLow(number) {
            if (parseInt(number) >= 19) {
                return 'HIGH'
            } else {
                return 'LOW'
            }
        },
        formatEvenOdd(number) {
            if (parseInt(number) % 2 == 0) {
                return 'EVEN'
            }
            else {
                return 'ODD'
            }
        },
        onChangePage(pageOfItems) {
            // update page of items
            this.pageOfItems = pageOfItems;
        },
        print() {
            var kenoTable = document.getElementById("luckyone-table-archives");
            var newWin = window.open("");
            newWin.document.write(kenoTable.outerHTML);
            newWin.print();
            newWin.close();
        },
        customFormatter(date) {
            return moment(date).format('MM/DD/yyyy');
        }

    },
    watch: {
        // Watch Input / search by number
        number: function (value) {
            // if value is empty, button will be disabled
            if (value.length > 0) {
                this.disabledNumber = false;
            } else {
                this.disabledNumber = true;
            }
        },
        // Watch Input / search by date
        inputValueDate: function (date) {
            // if date is empty, button will be disabled
            if (date) {
                this.disabledDate = false;
            } else {
                this.disabledDate = true;
            }

        }
    }


};
</script>

<style lang="scss"></style>