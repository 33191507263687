<template>
    <section class="cf module_drawingList">
        <span v-if="debug === true">
            <hr>
            <p>
                <span>ID: </span>{{ userId }} ||
                <span>Return Responses: </span>{{ resultsReturned }}
            </p>
        </span>
        <h2>{{ title }}</h2>
        <button v-if="view === false && history" class="drawHisViewAll link" @click="$emit('history', 1)">View History</button>
        <button v-if="view === true && history" class="drawHisViewAll link" @click="$emit('history', 1)">View Recent</button>
        <div class="cf drawingList drawingListResponsive ActiveDrawingEntryList">
            <table class="ActiveDrawingEntryList cf">
                <tbody>
                    <tr>
                        <th class="listHead">Drawing Name</th>
                        <th class="listHead">Closing Date</th>
                        <th class="listHead" colspan="2">My Entries</th>
                    </tr>
                </tbody>
            </table>

            <!-- Collapsed View -->
            <ul v-if="view === false">
                <li v-for="entry in data.slice(0, resultsReturned)" :key="entry.drawingName" class="stRow drawEntryHistoryMore cf">
                    <div class="drawHisLI col1">
                        {{ entry.drawingName }}
                    </div>
                    <div class="drawHisLI col2">
                        <span class="drawingDate">
                            <span class="drawingLabel">Closing Date: </span>{{ entry.drawingClosingDate }}
                        </span>
                    </div>
                    <div class="drawHisLI col3" colspan="2">
                        <span class="entryCount">
                            <span class="drawingLabel">Entries: </span>{{ entry.entryCount }}
                        </span>
                    </div>
                    <!-- <div class="drawHisLI col4"> -->
                        <EntryList :id="userId" :tokenKey="tokenKey" :url="'/1.0/PlainDraw/PlainDraw/Get-User-Participation-Entries-Detail?userId=' + userId + '&drawingId=' + entry.plainDrawingID"></EntryList>
                    <!-- </div> -->
                    <ul class="drawHisLi col5">

                    </ul>
                </li>
            </ul>

            <!-- History View -->
            <ul v-if="view === true">
                <li v-for="entry in data" :key="entry.drawingName" class="stRow drawEntryHistoryMore cf">
                    <div class="drawHisLI col1">
                        {{ entry.drawingName }}
                    </div>
                    <div class="drawHisLI col2">
                        <span class="drawingDate">
                            <span class="drawingLabel">Closing Date: </span>{{ entry.drawingClosingDate }}
                        </span>
                    </div>
                    <div class="drawHisLI col3" colspan="2">
                        <span class="entryCount">
                            <span class="drawingLabel">Entries: </span>{{ entry.entryCount }}
                        </span>
                    </div>
                    <!-- <div class="drawHisLI col4"> -->
                        <EntryList :id="userId" :tokenKey="tokenKey" :url="'/1.0/PlainDraw/PlainDraw/Get-User-Participation-Entries-Detail?userId=' + userId + '&drawingId=' + entry.plainDrawingID"></EntryList>
                    <!-- </div> -->
                    <ul class="drawHisLi col5">

                    </ul>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    import EntryList from './DrawListEntriesByDate.vue';

    export default {
        props: [
            'debug',
            'tokenKey',
            'title',
            'userId',
            'resultsReturned',
            'data',
            'view'
        ],
        components:{
            EntryList
        },
        data: function () {
            return {
                qty: 1,
                history: false,
            }
        },
        mounted: function () {
            // if (this.debug === true) {
                this.data.length > this.resultsReturned ? this.history = true : false;
                this.getDrawingEntriesByDate();
            // }
        },
        methods: {
            getDrawingEntriesByDate() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/Get-User-Participation-Entries-Detail?userId=' + this.userId + '&drawingId=' + this.data[0].plainDrawingID;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.tokenKey
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    // console.log('Drawing Entries broken out')
                    // console.log(json)
                    // this.drawings = this.convertDates(json.data);
                })
            }
        },
        computed: {
        },
        watch: {
        }
    };
</script>

<style lang="scss" scoped>
    h2 {
        float: left;
    }
    .drawHisViewAll {
        display: inline;
        float: right;
        // margin-top: -30px;
        margin-bottom: 10px;
        font-weight: 300;
        letter-spacing: 0;
            text-transform: none;

        @media screen and (min-width: 641px) {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
</style>