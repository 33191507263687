<template>
    <div class="check_your_numbers_panel">
        <h3>180 Day Calculator</h3>

        <div class="panel_content">
            <div class=" calculator_180_days">
                <label>Select the Drawing Date of your Ticket:</label>

                <datepicker 
                    :disabledDates="disabledDates" 
                    placeholder="Select Date" 
                    name="inputArchiveDate"
                    :format="customFormatter"
                    v-model="datePickerValue">
                </datepicker>

                <button class="button" @click="go">Go</button>


                <div class="results">
                    <div>
                        <span>Draw Date of Ticket</span>

                        <span>{{ dateOfTicket }}</span>
                    </div>
                    <div>
                        <span>Last Day to Redeem Your Ticket</span>
                        <span>{{ redeemTicket }}</span>
                    </div>
                    <div>
                        <span>Number of Days Remaining to Redeem Your Ticket</span>
                        <span>{{ remainingDays }}</span>
                    </div>
                </div>
                
                <p>
                    The calculator is designed to assist and inform Ohio Lottery Commission 
                    customers regarding the last day to redeem winning Pick 3, Pick 4, PICK 5, 
                    Rolling Cash 5, Classic Lotto, Keno, Powerball and Mega Millions tickets 
                    based on the draw date. The Ohio Lottery Commission makes no warranties or 
                    representations as to the accuracy of the information supplied above and the 
                    Ohio Lottery Commission is not liable for payment of ticket based upon an error 
                    in the 180 Day Calculator. Actual last day to redeem dates can only be verified 
                    through the Ohio Lottery gaming system, and a ticket that is not presented within the
                    applicable claiming period and that is not properly validated will not be honored or paid.
                </p>
            </div>
        </div>
    </div>
</template>


<script>
    import Datepicker from "vue-moment-datepicker";
    import moment from 'moment';

    export default {
            components: {
                Datepicker
            },
            data: function () {
                return {
                    disabledDates: {
                        from: new Date()
                    },
                    dateOfTicket:'',
                    datePickerValue: '',
                    redeemTicket: '',
                    remainingDays: ''
                }
            },
            mounted: function () {
            },
            methods: {
                customFormatter(date) {
                    return moment(date).format('MM/DD/yyyy');
                },
                go() {
                    this.dateOfTicket = this.customFormatter(this.datePickerValue);

                    let ticketDate = this.dateOfTicket 

                    let redeemDate =  moment(moment(ticketDate).add(180, 'd').format('MM/DD/yyyy')).format('MM/DD/yyyy');
                    this.redeemTicket = redeemDate;

                    var today = moment(new Date()).format("MM/DD/YYYY");
          
                    var date1 = new Date(ticketDate);
                    var date2 = new Date(today);
                    var diffInTime = date2.getTime() - date1.getTime()
                    var diffInDays = diffInTime / (1000 * 3600 * 24);

                    let remainingTicketDays = Math.round (  180 - diffInDays );

                    console.log("Remaining Ticket Days");
                    console.log(remainingTicketDays);


                    if(remainingTicketDays < 0 ){
                        this.remainingDays = "Your Ticket expired"
                        // this.redeemTicket =  "Your Ticket expired";
                        //calculate the date that the tickt expired instead of the canned message
                        this.redeemTicket = this.findDateXDaysAway(ticketDate, this.remainingDays);
                    }else{
                        this.remainingDays = remainingTicketDays;
                        this.redeemTicket = redeemDate;
                    }

                },
                findDateXDaysAway(startDate, daysToAdd) {
                    const dt = new Date(startDate);
                    //abs value of daysToAdd
                    daysToAdd = Math.abs(daysToAdd);
                    dt.setDate(date.getDate() + daysToAdd);
                    return dt;
                }
            }
            
    };

</script>

<style>
</style>
